import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/components/profile/service/profile.service';
import { User } from 'src/app/entities/user.model';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { OfficeService } from '../../shared/service/office.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';
import { FormsModule } from '@angular/forms';
import { NgClass, NgFor, NgIf } from '@angular/common';
@Component({
    selector: 'office-taxrates',
    templateUrl: './office-taxrates.component.html',
    styleUrls: ['./office-taxrates.component.scss'],
    imports: [
        NgFor,
        NgClass,
        FormsModule,
        NgxMaskDirective,
        NgIf,
        TranslateModule,
        NgxMaskDirective,
    ]
})
export class OfficeTaxratesComponent implements OnInit {
  constructor(
    private officeService: OfficeService,
    private alertService: AlertService,
    private profileService: ProfileService,
    private sharedService: SharedService
  ) {}

  currentUser: User;
  taxrates: Array<any>;
  newTaxRate: any;
  isSavingTaxrate: boolean = false;

  ngOnInit() {
    this.newTaxRate = {};
    this.getTaxRates();
    this.sharedService.currentUser.subscribe(
      (user) => (this.currentUser = user)
    );
  }

  getTaxRates() {
    this.officeService.getTaxrates().subscribe((response) => {
      this.taxrates = response.data;
    });
  }

  onSetDefault($checked, taxrate) {
    if (!$checked) {
      return;
    }
    this.currentUser.profile.default_taxrate = taxrate.id;
    this.profileService.updateItem(
      { default_taxrate: taxrate.id },
      this.currentUser.profile.id
    );
  }

  isDefaultRate(taxrate) {
    return this.currentUser.profile.default_taxrate == taxrate.id;
  }

  saveTaxrate() {
    if (!this.newTaxRate.name || !this.newTaxRate.percentage) {
      this.alertService.error('Please fill out all fields.');
      return;
    }
    if (this.isSavingTaxrate) {
      return;
    }
    this.isSavingTaxrate = true;

    this.newTaxRate.percentage = parseFloat(this.newTaxRate.percentage).toFixed(
      2
    );

    this.officeService.saveTaxrate(this.newTaxRate).subscribe((response) => {
      this.isSavingTaxrate = false;
      this.taxrates = response.data;
      this.newTaxRate = {};
    });
  }
}
