import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../entities/user.model';
import { Appointment } from '../../../entities/appointment.model';
import { TranslateModule } from '@ngx-translate/core';
import {
  CurrencyPipe,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  UpperCasePipe,
} from '@angular/common';

@Component({
    selector: 'app-appointment-status-message',
    templateUrl: './appointment-status-message.component.html',
    styleUrls: ['./appointment-status-message.component.css'],
    imports: [
        NgSwitch,
        NgSwitchCase,
        NgIf,
        UpperCasePipe,
        CurrencyPipe,
        TranslateModule,
    ]
})
export class AppointmentStatusMessageComponent implements OnInit {
  @Input() currentUser: User;
  @Input() appointment: Appointment;
  @Input() profileId: number;

  isTherapist: boolean;
  constructor() {}

  ngOnInit(): void {
    //  GS uses strings, so lets force the comparison to be a string
    this.isTherapist =
      this.profileId.toString() === this.currentUser.profile.id.toString();
  }
}
