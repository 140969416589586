import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutosizeModule } from 'ngx-autosize';
import { NgxMaskDirective } from 'ngx-mask';
import { DatePipe, NgIf } from '@angular/common';
import { LocalisedDatePipe } from '../booking-form-container/localised-date.pipe';
import { Dayjs } from 'dayjs';

@Component({
  selector: 'app-booking-form-blocker-summary',
  templateUrl: './booking-form-blocker-summary.component.html',
  styleUrls: ['./booking-form-blocker-summary.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgxMaskDirective,
    AutosizeModule,
    DatePipe,
    TranslateModule,
    NgxMaskDirective,
    LocalisedDatePipe,
  ],
})
export class BookingFormBlockerSummaryComponent implements OnInit {
  @Input() requestBookingForm: FormGroup;
  @Input() onCancel: () => void;
  @Input() onSubmit: () => void;
  @Input() onChangeDate: () => void;
  @Input() timezoneText: string;

  blockerTimestamp: any;
  constructor() {}

  ngOnInit(): void {
    this.blockerTimestamp = (
      this.requestBookingForm.controls.appointmentDate.value as Dayjs
    )
      .toDate()
      .getTime();
  }
}
