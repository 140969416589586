import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/entities/user.model';
import { InvoiceExtended } from 'src/app/entities/invoice.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TranslateModule } from '@ngx-translate/core';
import {
  CurrencyPipe,
  DatePipe,
  NgIf,
  NgSwitch,
  NgSwitchCase,
} from '@angular/common';
import { LocalisedDatePipe } from '../../booking/booking-form-container/localised-date.pipe';

@Component({
  selector: 'app-invoice-attachment',
  templateUrl: './invoice-attachment.component.html',
  styleUrls: ['./invoice-attachment.component.scss'],
  imports: [
    NgSwitch,
    NgSwitchCase,
    NgIf,
    CurrencyPipe,
    DatePipe,
    TranslateModule,
    LocalisedDatePipe,
  ],
})
export class InvoiceAttachmentComponent implements OnInit {
  constructor(private sharedService: SharedService) {}

  @Input() invoice: InvoiceExtended;
  @Input() clientId: number;
  @Input() currentUser: User;

  ngOnInit() {}

  payAndConfirm() {
    this.sharedService.setPayableInvoice(this.invoice.id);
  }

  viewInvoice() {
    this.payAndConfirm();
  }
}
