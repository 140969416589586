import { Injectable } from '@angular/core';
import { InvoiceTotalPipe } from '../pipes/invoice-total.pipe';
import { formatAddress } from '../../../shared/helpers/stripeAddress';
import { InvoiceItem } from '../../../entities/invoice-item.model';
import { formatDate } from '@angular/common';
import { User } from '../../../entities/user.model';
import { InvoiceExtended } from '../../../entities/invoice.model';
import { TranslateService } from '@ngx-translate/core';
import { MonitoringService } from '../../../shared/services/monitoring/monitoring.service';
import { calculateTotalVAT } from '../../../shared/helpers/tax.helper';
import { formatCurrencyForUser } from '../../../shared/helpers/currency_helper';
import { FontService } from '../../../shared/services/font/FontService';
import { Observable, from, forkJoin } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
// Import types only
import type { jsPDF } from 'jspdf';
import type { UserOptions } from 'jspdf-autotable';

// Define the autoTable function type
type AutoTableFunction = (doc: jsPDF, options: UserOptions) => void;

@Injectable({
  providedIn: 'root',
})
export class DownloadInvoiceService {
  constructor(
    private fontService: FontService,
    private translateService: TranslateService
  ) {}

  private readonly fontName = 'arial';

  // lazy load pdf libs since they are so big
  private async loadPdfLibraries(): Promise<{
    PDFDocument: typeof import('jspdf')['default'];
    tablePlugin: AutoTableFunction;
  }> {
    try {
      const jsPdfModule = await import('jspdf');
      const autoTableModule = await import('jspdf-autotable');

      // Create PDFDocument instance to ensure autoTable is attached
      const PDFDocument = jsPdfModule.default;

      // Handle the autoTable plugin differently
      // The plugin modifies the jsPDF prototype, so we need to ensure it's properly initialized
      const tablePlugin = (doc: jsPDF, options: UserOptions) => {
        if (typeof (doc as any).autoTable !== 'function') {
          // Initialize autoTable with an empty options object to satisfy type requirements
          autoTableModule.default(doc, {});
        }
        return (doc as any).autoTable(options);
      };

      return {
        PDFDocument,
        tablePlugin,
      };
    } catch (error) {
      console.error('Error loading PDF libraries:', error);
      throw new Error('Failed to load PDF libraries');
    }
  }

  downloadInvoice(
    invoice: InvoiceExtended,
    currentUser: User
  ): Observable<void> {
    return from(this.loadPdfLibraries()).pipe(
      switchMap(({ PDFDocument, tablePlugin }) => {
        return new Observable<void>((observer) => {
          // Load both fonts in parallel
          forkJoin({
            regular: this.fontService.loadFont('assets/fonts/arial.ttf').pipe(
              catchError((error) => {
                console.error('Failed to load regular font:', error);
                // Return a fallback font or empty string
                return from(['']);
              })
            ),
            bold: this.fontService.loadFont('assets/fonts/arial-bold.ttf').pipe(
              catchError((error) => {
                console.error('Failed to load bold font:', error);
                // Return a fallback font or empty string
                return from(['']);
              })
            ),
          }).subscribe(
            ({ regular, bold }) => {
              try {
                const pdf = new PDFDocument({ format: 'letter' });

                // Only add fonts if they were successfully loaded
                if (regular) {
                  pdf.addFileToVFS('arial.ttf', regular);
                  pdf.addFont('arial.ttf', this.fontName, 'normal');
                }
                if (bold) {
                  pdf.addFileToVFS('arial-bold.ttf', bold);
                  pdf.addFont('arial-bold.ttf', this.fontName, 'bold');
                }

                this.buildAndSaveInvoice(
                  invoice,
                  currentUser,
                  pdf,
                  tablePlugin
                );
                observer.next();
                observer.complete();
              } catch (error) {
                MonitoringService.captureException(error);
                observer.error(error);
              }
            },
            (error) => {
              MonitoringService.captureException(error);
              observer.error(error);
            }
          );
        });
      }),
      catchError((error) => {
        MonitoringService.captureException(error);
        throw error;
      })
    );
  }

  buildAndSaveInvoice(
    invoice: InvoiceExtended,
    currentUser: User,
    doc: jsPDF,
    tablePlugin: AutoTableFunction
  ) {
    this.buildInvoice(doc, invoice, currentUser, tablePlugin);

    const fileDate = new Date(invoice.created_at);
    const fileDateString = fileDate.toISOString().split('T')[0];
    const fileName = `${fileDateString}_${invoice.stripe.number}.pdf`;
    doc.save(fileName);
  }

  buildInvoice(
    doc: jsPDF,
    invoice: InvoiceExtended,
    currentUser: User,
    tablePlugin: AutoTableFunction
  ) {
    const pageWidth: number = doc.internal.pageSize.width;
    const halfPageWidth = pageWidth / 2;
    const vMargin: number = 18;
    const hMargin: number = 10;

    const companyName = invoice.business_profile.name;
    const taxTotal = invoice.invoice_items.reduce(
      (a: any, b: InvoiceItem) => a + calculateTotalVAT(b.price, b.taxrate),
      0
    );

    const totalAmount = new InvoiceTotalPipe().transform(invoice.invoice_items);
    const applicationFee = invoice.fee_rate
      ? totalAmount * invoice.fee_rate
      : invoice.application_fee / 100;
    // Stripe fee is only relevant in old invoices, where it is not included in the application fee (fee_rate is null)
    const stripeFee = invoice.fee_rate ? 0 : invoice.stripe_fee / 100;
    const currency = (value: number) => {
      return formatCurrencyForUser(value, currentUser, invoice.stripe.currency);
    };

    doc.setFillColor('#74d0bf');
    doc.rect(0, 0, pageWidth, 1.5, 'F');

    let rowPos = vMargin;

    // Add invoice header
    doc.setFont(this.fontName, 'normal');
    doc.setFontSize(18);
    doc.text(
      this.translateService.instant('office.invoice.download.title'),
      hMargin,
      rowPos
    );
    doc.setTextColor('#999999');
    doc.text(companyName, pageWidth - hMargin, rowPos, {
      align: 'right',
    });

    rowPos += 10;
    doc.setTextColor('#000000');
    doc.setFontSize(10);
    doc.setFont(this.fontName, 'bold');

    const headerTableBody = [
      [
        this.translateService.instant('office.invoice.download.created_on'),
        formatDate(invoice.created_at, 'mediumDate', currentUser.locale),
      ],
    ];

    if (invoice.stripe?.status_transitions.finalized_at) {
      headerTableBody.push([
        this.translateService.instant('office.invoice.download.issued_on'),
        formatDate(
          invoice.stripe.status_transitions.finalized_at * 1000,
          'mediumDate',
          currentUser.locale
        ),
      ]);
    }

    if (invoice.stripe?.status_transitions.paid_at) {
      headerTableBody.push([
        this.translateService.instant('office.invoice.download.paid_on'),
        formatDate(
          invoice.stripe?.status_transitions.paid_at * 1000,
          'mediumDate',
          currentUser.locale
        ),
      ]);
    }

    if (invoice.stripe?.custom_fields) {
      invoice.stripe.custom_fields.forEach((field) => {
        headerTableBody.push([field.name, field.value]);
      });
    }

    tablePlugin(doc, {
      margin: { left: hMargin, right: hMargin },
      startY: rowPos,
      head: [
        [
          this.translateService.instant(
            'office.invoice.download.invoice_number'
          ),
          invoice.stripe?.number,
        ],
      ],
      styles: {
        cellPadding: {
          top: 0, // top padding
          right: 2, // right padding
          bottom: 1, // bottom padding (this controls the space between rows)
          left: 0, // left padding
        },
      },
      body: headerTableBody,
      theme: 'plain',
      headStyles: { fillColor: false, textColor: '#000000', fontStyle: 'bold' },
      tableWidth: 'wrap',
    });

    rowPos = (doc as any).lastAutoTable.finalY + 10;

    doc.setFont(this.fontName, 'bold');
    doc.text(companyName, hMargin, rowPos);

    doc.text(this.getClientLabel(invoice), halfPageWidth, rowPos);

    doc.setFont(this.fontName, 'normal');

    doc.setFontSize(9);
    rowPos += 5;

    // business details on the left
    const therapistAddress = formatAddress(
      invoice.business_profile.support_address
    );
    let therapistAddressPos = rowPos;
    while (therapistAddress.length > 0) {
      doc.text(therapistAddress.shift(), hMargin, therapistAddressPos);
      therapistAddressPos += 5;
    }

    // add client name, email and address on the right side (whatever is available)
    // We could just use the customer address from the stripe invoice, but I want to be consistent with the other invoices
    const clientAddress = formatAddress(invoice.stripe.customer_address);
    let clientAddressPos = rowPos;
    doc.setFont(this.fontName, 'normal');
    if (invoice.stripe.customer_name) {
      doc.text(invoice.stripe.customer_name, halfPageWidth, clientAddressPos);
      clientAddressPos += 5;
    }

    if (invoice.stripe.customer_email) {
      doc.text(invoice.stripe.customer_email, halfPageWidth, clientAddressPos);
      clientAddressPos += 5;
    }

    while (clientAddress.length > 0) {
      doc.text(clientAddress.shift(), halfPageWidth, clientAddressPos);
      clientAddressPos += 5;
    }

    rowPos = Math.max(therapistAddressPos, clientAddressPos);

    rowPos += 10;

    doc.setFont(this.fontName, 'bold');
    doc.setFontSize(12);
    doc.text(
      this.getStatusMessage(invoice, currency(totalAmount)),
      hMargin,
      rowPos
    );

    if (this.shouldShowPaymentLink(invoice)) {
      rowPos += 7;

      doc.setFontSize(10);
      doc.setFont(this.fontName, 'normal');
      doc.setTextColor('#64b1a3');

      const cta = this.translateService.instant(
        'office.invoice.download.pay_online'
      );
      doc.textWithLink(cta, hMargin, rowPos, {
        url: invoice.stripe.hosted_invoice_url,
      });
      const textWidth = doc.getTextWidth(cta);
      doc.setDrawColor('#64b1a3');
      doc.setLineWidth(0.1);
      doc.line(hMargin, rowPos + 1, hMargin + textWidth, rowPos + 1);
    }

    doc.setFontSize(10);
    doc.setFont(this.fontName, 'normal');
    doc.setTextColor('#000000');

    const totalPagesExp = '{total_pages_count_string}';

    rowPos += 10;

    const footerLineItem = (
      labelKey: any,
      value: string,
      fontStyle: string = 'normal'
    ) => {
      return [
        { content: '' },
        {
          content: this.translateService.instant(labelKey),
          colSpan: 3,
          styles: { halign: 'left', fontStyle },
        },
        {
          content: value,
          colSpan: 1,
          styles: { halign: 'right', fontStyle },
        },
      ];
    };

    let footerLineItems = [];
    const invoiceFooter = invoice.stripe?.footer
      ? [
          {
            content: invoice.stripe?.footer,
            colSpan: 5,
            styles: { cellPadding: { top: 10 }, fontStyle: 'normal' },
          },
        ]
      : null;
    switch (invoice.status) {
      case 'paid':
      case 'paid_externally':
      case 'refunded':
        footerLineItems = [
          footerLineItem(
            'office.invoice.download.tax_total',
            currency(taxTotal)
          ),
          footerLineItem(
            'office.invoice.download.total',
            currency(totalAmount)
          ),
          footerLineItem(
            'office.invoice.download.application_fee',
            currency(-applicationFee)
          ),
          stripeFee > 0
            ? footerLineItem(
                'office.invoice.download.stripe_fee',
                currency(-stripeFee)
              )
            : null,
          footerLineItem(
            'office.invoice.download.net',
            currency(totalAmount - applicationFee - stripeFee)
          ),
          invoiceFooter,
        ].filter((item) => item);
        break;
      case 'canceled':
        footerLineItems = [
          footerLineItem(
            'office.invoice.download.tax_total',
            currency(taxTotal)
          ),
          footerLineItem(
            'office.invoice.download.total',
            currency(totalAmount)
          ),
          invoiceFooter,
        ].filter((item) => item);
        break;
      case 'open':
      case 'processing':
        footerLineItems = [
          footerLineItem(
            'office.invoice.download.tax_total',
            currency(taxTotal)
          ),
          footerLineItem(
            'office.invoice.download.total',
            currency(totalAmount)
          ),
          footerLineItem(
            'office.invoice.download.amount_due',
            currency(totalAmount),
            'bold'
          ),
          invoiceFooter,
        ].filter((item) => item);
        break;
      default:
        MonitoringService.captureMessage(
          'Unknown invoice status: ' + invoice.stripe.status
        );
    }

    tablePlugin(doc, {
      margin: { left: hMargin, right: hMargin },
      startY: rowPos,
      body: invoice.invoice_items.map((item) => {
        return {
          description: item.name,
          quantity: item.amount,
          price: currency(item.price),
          taxRate: item.taxrate + '%',
          total: currency(item.amount * item.price),
        };
      }),
      showHead: 'firstPage',
      columns: [
        {
          header: {
            title: this.translateService.instant(
              'office.invoice.download.description'
            ),
            styles: { halign: 'left', fontStyle: 'normal' },
          },
          dataKey: 'description',
        },
        {
          header: {
            title: this.translateService.instant(
              'office.invoice.download.quantity'
            ),
            styles: { halign: 'left', fontStyle: 'normal' },
          },
          dataKey: 'quantity',
          footer: {
            styles: { halign: 'left', fontStyle: 'normal' },
          },
        },
        {
          header: {
            title: this.translateService.instant(
              'office.invoice.download.unit_price'
            ),
            styles: { halign: 'left', fontStyle: 'normal' },
          },
          dataKey: 'price',
        },
        {
          header: {
            title: this.translateService.instant(
              'office.invoice.download.tax_rate'
            ),
            styles: { halign: 'left', fontStyle: 'normal' },
          },
          dataKey: 'taxRate',
        },
        {
          header: {
            title: this.translateService.instant(
              'office.invoice.download.total'
            ),
            styles: { halign: 'right', fontStyle: 'normal' },
          },
          dataKey: 'total',
        },
      ],
      theme: 'plain',
      headStyles: { fillColor: false, textColor: '#000000', fontStyle: 'bold' },
      columnStyles: {
        0: { cellWidth: halfPageWidth - hMargin },
        1: { cellWidth: 20 },
        4: { halign: 'right', lineColor: '#000000' },
      },
      styles: {
        fontSize: 9,
        cellPadding: { top: 2, bottom: 2 },
      },
      didDrawCell: (data) => {
        // Draw separator lines in header and footer
        const lineThickness = 0.2; // Line height (0.5mm)

        if (data.section === 'head') {
          const lineY = data.cell.y + data.cell.height - lineThickness;
          doc.setDrawColor(0, 0, 0); // Line color (black)
          doc.setLineWidth(lineThickness); // Line width (0.5mm)
          doc.line(data.cell.x, lineY, data.cell.x + data.cell.width, lineY);
        }

        if (
          data.cell &&
          data.cell.section === 'foot' &&
          data.column.index >= data.table.columns.length - 4
        ) {
          const lineY = data.cell.y;
          doc.setDrawColor('#ECECEC');
          doc.setLineWidth(0.2);
          doc.line(data.cell.x, lineY, data.cell.x + data.cell.width, lineY);
        }
      },
      showFoot: 'lastPage',
      foot: footerLineItems,
      didDrawPage: (data) => {
        // Page number
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.setFontSize(9);
        if ((doc as any).internal.getNumberOfPages() > 1) {
          doc.text(
            'Page ' +
              (doc as any).internal.getNumberOfPages() +
              ' of ' +
              totalPagesExp,
            data.settings.margin.left,
            pageHeight - 10
          );
        }
      },
    });

    doc.putTotalPages(totalPagesExp);
  }

  private getClientLabel(invoice: InvoiceExtended) {
    return this.translateService.instant(
      invoice.status === 'paid'
        ? 'office.invoice.download.paid_by'
        : 'office.invoice.download.issued_to'
    );
  }

  private getStatusMessage(invoice: InvoiceExtended, totalAmountFormatted) {
    switch (invoice.status) {
      case 'paid':
        return this.translateService.instant(
          'office.invoice.download.has_been_paid'
        );
      case 'paid_externally':
        return this.translateService.instant(
          'office.invoice.download.has_been_paid_externally'
        );
      case 'refunded':
        return this.translateService.instant(
          'office.invoice.download.has_been_refunded'
        );
      case 'open':
        return this.translateService.instant(
          'office.invoice.download.due_to_be_paid',
          { amount: totalAmountFormatted }
        );
      case 'processing':
        return this.translateService.instant(
          'office.invoice.download.is_being_processed'
        );
      case 'canceled':
        return this.translateService.instant(
          'office.invoice.download.has_been_cancelled'
        );
      default:
        MonitoringService.captureMessage(
          'Unknown invoice status: ' + invoice.status
        );
        return '';
    }
  }

  private shouldShowPaymentLink(invoice: InvoiceExtended) {
    return invoice.status === 'open';
  }
}
