import { Component, Input, OnInit } from '@angular/core';
import { CalendarService } from '../../../components/calendar/service/calendar.service';
import { User } from 'src/app/entities/user.model';
import { Appointment } from 'src/app/entities/appointment.model';
import { SharedService } from '../../services/shared.service';
import { AppointmentMarkEapCompletedDialog } from '../../../components/booking/appointment-mark-eap-completed-dialog/appointment-mark-eap-completed-dialog';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { LocalisedDatePipe } from '../../../components/booking/booking-form-container/localised-date.pipe';

@Component({
  selector: 'app-appointments-requiring-completion',
  templateUrl: './appointments-requiring-completion.html',
  styleUrls: ['./appointments-requiring-completion.scss'],
  imports: [
    NgIf,
    NgFor,
    ProfilePictureComponent,
    DatePipe,
    TranslateModule,
    LocalisedDatePipe,
  ],
})
export class AppointmentsRequiringCompletion implements OnInit {
  constructor(
    private calendarService: CalendarService,
    private sharedService: SharedService,
    public dialog: MatDialog
  ) {}

  appointmentsRequiringCompletion: Array<Appointment>;

  @Input()
  currentUser: User;

  showTimezoneHint: boolean = false;
  currentTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  ngOnInit() {
    this.showTimezoneHint =
      this.currentUser.profile.timezone != this.currentTimezone;

    this.calendarService
      .getAppointmentsRequiringCompletion()
      .subscribe((data) => {
        this.appointmentsRequiringCompletion = data;
      });
  }

  markEapCompletedConfirm(appointment: Appointment) {
    const dialogRef = this.dialog.open(AppointmentMarkEapCompletedDialog, {
      data: {
        sourcePage: 'dashboard',
        appointmentId: appointment.id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.appointmentsRequiringCompletion =
          this.appointmentsRequiringCompletion.filter(
            (app) => app.id !== appointment.id
          );
      }
    });
  }
}
