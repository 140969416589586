<div [@simpleFadeAnimation]="'in'" class="container-fluid container-fluid-limited">
  <div class="columns is-marginless">
    <div class="column is-3 is-hidden-touch is-marginless">
      <app-sidenav></app-sidenav>
    </div>
    <div class="column message-col">
      <div class="container-fluid container-fluid-limited">
        <div id="ic-messages-container" class="card has-border">
          <nav class="conv-header">
            <div class="is-flex is-flex-direction-row is-align-items-center justify-space-between flex-gap-10">
              <!-- Title Section -->
              <h4 class="title is-5 has-margin-bottom-0">{{ "messaging.list.inbox" | translate }}</h4>
              <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-flex-end">
                <!-- Input and Menus -->
                <div class="control" style="flex: 1 1 auto; min-width: 100px; padding-right: 10px">
                    <input
                      type="text"
                      class="input is-rounded"
                      [placeholder]="'messaging.search' | translate"
                      [(ngModel)]="searchTerm"
                      (ngModelChange)="onSearchTermChange($event)"
                    />
                </div>

                <!-- Sorting Dropdown Menu Button -->
                <button mat-icon-button [matMenuTriggerFor]="sortingMenu" aria-label="Sort options">
                  <mat-icon>sort</mat-icon>
                </button>
                <mat-menu #sortingMenu="matMenu">
                  <button mat-menu-item (click)="changeSorting('last_updated')">
                    <span>{{ "messaging.list.sort-activity" | translate }}</span>
                    <mat-icon *ngIf="sorting == 'last_updated'" style="margin-left: auto;">check</mat-icon>
                  </button>
                  <button mat-menu-item (click)="changeSorting('created_at')">
                    <span>{{ "messaging.list.sort-first-contact" | translate }}</span>
                    <mat-icon *ngIf="sorting == 'created_at'" style="margin-left: auto;">check</mat-icon>
                  </button>
                  <button mat-menu-item (click)="changeSorting('has_unread')">
                    <span>{{ "messaging.list.show-unread" | translate }}</span>
                    <mat-icon *ngIf="sorting == 'has_unread'" style="margin-left: auto;">check</mat-icon>
                  </button>
                </mat-menu>

                <!-- More Options Menu Button -->
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More message list options">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item *ngIf="!showHidden" (click)="changeShowHidden(true)">
                    <div class="is-flex align-center">
            <span
              class="feature-tooltip"
              matTooltipClass="mat-tooltip"
              [matTooltipPosition]="'above'"
              [matTooltip]="'messaging.list.show-hidden-tooltip' | translate"
            >
              <mat-icon class="mat-tooltip-trigger-icon material-symbols-outlined"
                        style="font-size: 20px !important; height: unset !important; width: unset !important;">help_outline</mat-icon>
            </span>
                      <span>{{ "messaging.list.show-hidden" | translate }}</span>
                    </div>
                  </button>
                  <button mat-menu-item *ngIf="showHidden" (click)="changeShowHidden(false)">
                    <div class="is-flex align-center">
            <span
              class="feature-tooltip"
              matTooltipClass="mat-tooltip"
              [matTooltipPosition]="'above'"
              [matTooltip]="'messaging.list.show-non-hidden-tooltip' | translate"
            >
              <mat-icon class="mat-tooltip-trigger-icon material-symbols-outlined"
                        style="font-size: 20px !important; height: unset !important; width: unset !important;">help_outline</mat-icon>
            </span>
                      <span>{{ "messaging.list.show-non-hidden" | translate }}</span>
                    </div>
                  </button>
                </mat-menu>
              </div>
            </div>
          </nav>
          <div class="columns main-columns is-gapless">
            <div class="column">
              <!-- Show loading state -->
              <div *ngIf="isLoading">
                <div class="card" [ngClass]="{ 'no-shadow': false }">
                  <app-conversation-preview
                    *ngFor="let i of [1, 2, 3]"
                    [loading]="true"
                  ></app-conversation-preview>
                </div>
              </div>

              <!-- Show error state -->
              <ng-container *ngIf="hasError">
                <div class="notification is-danger">
                  {{ "messaging.could_not_load_latest_messages" | translate }}
                </div>
              </ng-container>

              <!-- Show empty state if channels are loaded but empty -->
              <div *ngIf="!isLoading && !hasError && (channels$ | async)?.length=== 0" [ngClass]="{ 'card is-flex justify-center': true }">
                <div class="card-content has-text-centered">
                  <p *ngIf="!searchTerm && showHidden">
                    {{
                      "messaging.list.hidden-conversations-appear-here"
                        | translate
                    }}
                  </p>
                  <p *ngIf="!searchTerm && !showHidden">
                    {{ "messaging.list.conversations-appear-here" | translate }}
                  </p>
                  <p *ngIf="searchTerm && showHidden">
                    {{ "messaging.list.no-hidden-conversations" | translate }}
                  </p>
                  <p *ngIf="searchTerm && !showHidden">
                    {{ "messaging.list.no-conversations" | translate }}
                  </p>
                </div>
              </div>

              <!-- Show channels when they are loaded -->
              <div *ngIf="!isLoading && !hasError && (channels$ | async)?.length > 0" class="str-chat__channel-list-messenger">
                <div class="str-chat__channel-list-messenger__main">
                  <ng-content select="[channel-list-top]"></ng-content>
                  <ng-container
                    *ngFor="let channel of channels$ | async; trackBy: trackByChannelId"
                  >
                    <app-stream-conversation-preview
                      data-testclass="channel-preview"
                      [channel]="channel"
                      [currentUserIsTherapist]="currentUser?.type === 'Therapist'"
                      [truncateSize]="60"
                      (conversationClick)="goToConversation(channel)"
                    ></app-stream-conversation-preview>
                  </ng-container>

                  <!-- Load more button -->
                  <div
                    *ngIf="hasMoreChannels$ | async"
                    class="has-text-centered"
                  >
                    <button
                      (click)="loadMoreChannels()"
                      [disabled]="isLoadingMoreChannels"
                      class="button is-primary is-rounded moreLink"
                    >
                      <span *ngIf="!isLoadingMoreChannels; else loadingIndicator">{{ "messaging.list.load-more" | translate }}</span>
                      <ng-template #loadingIndicator
                      >
                        <stream-loading-indicator-placeholder></stream-loading-indicator-placeholder
                        >
                      </ng-template>
                    </button>
                  </div>
                  <ng-content select="[channel-list-bottom]"></ng-content>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
