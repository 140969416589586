import { Animation } from 'src/app/shared/animations/fade-animation';
import { Component, OnInit } from '@angular/core';
import { AlertService } from './service/alert.service';
import { Alert, AlertType } from 'src/app/entities/alert.model';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    animations: [Animation.fadeAnimation],
    imports: [NgFor, NgIf, TranslateModule]
})
export class AlertComponent implements OnInit {
  constructor(private alertService: AlertService) {}

  alerts: Alert[] = [];

  ngOnInit() {
    this.alertService.alertObservable.subscribe((alert: Alert) => {
      if (!alert) {
        // Clear alerts when an empty alert is received
        return (this.alerts = []);
      }
      this.alerts.push(alert);
      if (alert.autoHide) {
        setTimeout(() => {
          this.removeAlert(alert);
        }, 5000);
      }
    });
  }

  removeAlert(alert: Alert) {
    this.alerts = this.alerts.filter((x) => x !== alert);
  }

  onEmitted(alert) {
    this.removeAlert(alert);
  }

  cssClass(alert: Alert) {
    if (!alert) {
      return;
    }
    switch (alert.type) {
      case AlertType.SUCCESS:
        return 'is-success';
      case AlertType.ERROR:
        return 'is-danger';
      case AlertType.INFO:
        return 'is-info';
      case AlertType.WARNING:
        return 'is-warning';
    }
  }
}
