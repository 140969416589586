import { Pipe, PipeTransform } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';

@Pipe({ standalone: true, name: 'localisedDate' })
export class LocalisedDatePipe implements PipeTransform {
  transform(value: Date | Dayjs | string, dateFormat: string): string {
    if (!value) {
      return '';
    }

    // If the value is already a Dayjs object, use it directly
    if (dayjs.isDayjs(value)) {
      return value.tz().format(dateFormat);
    }

    // For strings, check if it's in UTC format (ends with Z or has offset)
    if (typeof value === 'string') {
      if (value.endsWith('Z') || /[+-]\d{2}:?\d{2}$/.test(value)) {
        return dayjs.utc(value).tz().format(dateFormat);
      }
    }

    // For Date objects or non-UTC strings, assume local time
    return dayjs.tz(value).format(dateFormat);
  }
}
