import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/entities/user.model';
import { WeeklyAvailabilitySlot } from 'src/app/entities/availability-slot.model';
import { SettingsService } from '../service/settings.service';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { DatePipe, DecimalPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { AddSpecificWorkingHoursDialogComponent } from '../../calendar/add-specific-working-hours/add-specific-working-hours-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DateSpecificAvailability } from '../../../entities/date-specific-availability';
import { CalendarSettingsService } from '../service/calendar-settings.service';
import { SlotHelper } from './slot.helper';
import { AddRegularWorkingHoursDialogComponent } from '../../calendar/add-regular-working-hours/add-regular-working-hours-dialog.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton } from '@angular/material/button';
import dayjs from 'dayjs';

@Component({
  selector: 'app-calendar-working-hours',
  templateUrl: './calendar-working-hours.component.html',
  styleUrls: ['./calendar-working-hours.component.scss'],
  imports: [
    NgFor,
    NgIf,
    NgClass,
    FormsModule,
    DecimalPipe,
    TranslateModule,
    MatIcon,
    DatePipe,
    MatTooltip,
    MatIconButton,
  ],
})
export class CalendarWorkingHoursComponent implements OnInit {
  constructor(
    private alertService: AlertService,
    private settingsService: SettingsService,
    private calendarSettingsService: CalendarSettingsService,
    public dialog: MatDialog
  ) {}

  @Input()
  public currentUser: User;
  public firstLoadRegularHours = false;
  public firstLoadSpecificHours = false;

  public availabilitySlotsByDay: {
    [key: number]: Array<WeeklyAvailabilitySlot>;
  } = {};
  public weekdays: Array<string> = [];
  public dateSpecificAvailabilities: Array<GroupedDateSpecificAvailability> =
    [];

  public showWorkingHoursModal = false;

  ngOnInit(): void {
    this.updateSlots();
    this.setLocalizedWeekdays();
    this.loadDateSpecificAvailabilities();
  }

  setLocalizedWeekdays(): void {
    const weekdaysStartingWithSunday = dayjs().localeData().weekdaysShort();
    // move sunday to the end of the array
    this.weekdays = weekdaysStartingWithSunday
      .slice(1)
      .concat(weekdaysStartingWithSunday[0]);
  }

  openAddSpecificWorkingHoursDialog() {
    const dialogRef = this.dialog.open(
      AddSpecificWorkingHoursDialogComponent,
      {}
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadDateSpecificAvailabilities(); // Refresh the list after adding a new availability
      }
    });
  }

  updateSlots(): void {
    this.settingsService.getAvailabilitySlots().subscribe((slots) => {
      this.availabilitySlotsByDay = {};
      slots.forEach((slot) => {
        // Convert from UTC to user's timezone in one step
        slot.start = dayjs
          .tz(slot.start, 'UTC')
          .tz(this.currentUser.profile.timezone)
          .toDate();

        slot.end = dayjs
          .tz(slot.end, 'UTC')
          .tz(this.currentUser.profile.timezone)
          .toDate();

        if (!this.availabilitySlotsByDay[slot.weekday]) {
          this.availabilitySlotsByDay[slot.weekday] = [];
        }
        this.availabilitySlotsByDay[slot.weekday].push(slot);
      });

      // Sort slots for each day
      Object.keys(this.availabilitySlotsByDay).forEach((day) => {
        this.availabilitySlotsByDay[day].sort((a, b) => {
          const aStart = dayjs()
            .set('hour', a.start_hour)
            .set('minute', a.start_minute);
          const bStart = dayjs()
            .set('hour', b.start_hour)
            .set('minute', b.start_minute);
          return aStart.diff(bStart);
        });
      });
      this.firstLoadRegularHours = true;
    });
  }

  loadDateSpecificAvailabilities(): void {
    this.calendarSettingsService
      .getUpcomingDateSpecificAvailabilities()
      .subscribe(
        (availabilities) => {
          availabilities.sort((a, b) => {
            const dateA = new Date(a.date).getTime();
            const dateB = new Date(b.date).getTime();
            return dateA - dateB;
          });
          this.dateSpecificAvailabilities =
            this.groupConsecutiveAvailabilities(availabilities);
          this.firstLoadSpecificHours = true;
        },
        (error) => {
          this.alertService.error(
            'settings.calendar.date-specific-hours.load-error'
          );
        }
      );
  }

  groupConsecutiveAvailabilities(
    availabilities: Array<DateSpecificAvailability>
  ): GroupedDateSpecificAvailability[] {
    if (availabilities.length === 0) {
      return [];
    }

    const groupedAvailabilities = [];
    let currentGroup = {
      startDate: availabilities[0].date,
      endDate: availabilities[0].date,
      slots: availabilities[0].availability_slots,
    };

    for (let i = 1; i < availabilities.length; i++) {
      const currentAvailability = availabilities[i];
      const previousAvailability = availabilities[i - 1];

      const currentDate = dayjs(currentAvailability.date);
      const previousDate = dayjs(previousAvailability.date);

      const isConsecutive = currentDate.diff(previousDate, 'days') === 1;
      const hasSameSlots =
        JSON.stringify(currentAvailability.availability_slots) ===
        JSON.stringify(previousAvailability.availability_slots);

      if (isConsecutive && hasSameSlots) {
        currentGroup.endDate = currentAvailability.date;
      } else {
        groupedAvailabilities.push({ ...currentGroup });
        currentGroup = {
          startDate: currentAvailability.date,
          endDate: currentAvailability.date,
          slots: currentAvailability.availability_slots,
        };
      }
    }
    groupedAvailabilities.push({ ...currentGroup });

    return groupedAvailabilities;
  }

  formatDateRange(group: GroupedDateSpecificAvailability): string {
    const startDate = dayjs(group.startDate);
    const endDate = dayjs(group.endDate);

    if (startDate.isSame(endDate, 'day')) {
      return startDate.format('MMM D, YYYY');
    } else if (startDate.isSame(endDate, 'month')) {
      return `${startDate.format('MMM D')} - ${endDate.format('D, YYYY')}`;
    } else if (startDate.isSame(endDate, 'year')) {
      return `${startDate.format('MMM D')} - ${endDate.format('MMM D, YYYY')}`;
    } else {
      return `${startDate.format('MMM D, YYYY')} - ${endDate.format(
        'MMM D, YYYY'
      )}`;
    }
  }

  deleteDateSpecificAvailability(
    availability: GroupedDateSpecificAvailability
  ): void {
    const dates = [];
    let currentDate = dayjs(availability.startDate);
    while (currentDate.isSameOrBefore(availability.endDate)) {
      dates.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'days');
    }
    this.calendarSettingsService
      .deleteDateSpecificAvailabilities(dates)
      .subscribe(
        () => {
          this.alertService.success(
            'settings.calendar.date-specific-hours.delete-success'
          );
          this.loadDateSpecificAvailabilities();
        },
        () => {
          this.alertService.error(
            'settings.calendar.date-specific-hours.delete-error'
          );
        }
      );
  }

  createSlotForWeekday(weekday: number): void {
    const currentSlots = this.availabilitySlotsByDay[weekday] || [];
    const dialogRef = this.dialog.open(AddRegularWorkingHoursDialogComponent, {
      width: '400px',
      data: { currentSlots, weekday },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.saveSlot(result);
      }
    });
  }

  deleteSlot(slotID: number): void {
    let dayKey: number | null = null;
    let slotIndex: number | null = null;

    for (const key of Object.keys(this.availabilitySlotsByDay)) {
      const index = this.availabilitySlotsByDay[Number(key)].findIndex(
        (slot) => slot.id === slotID
      );
      if (index !== -1) {
        dayKey = Number(key);
        slotIndex = index;
        break; // Exit the loop once the slot is found
      }
    }

    if (dayKey !== null && slotIndex !== null) {
      const slot = this.availabilitySlotsByDay[dayKey][slotIndex];

      // Optimistically remove the slot
      this.availabilitySlotsByDay[dayKey].splice(slotIndex, 1);

      this.settingsService.deleteAvailabilitySlot(slotID).subscribe(
        () => {
          this.alertService.success(
            'settings.calendar.working-hours.delete-success'
          );
        },
        () => {
          // Revert the change if the request fails
          this.availabilitySlotsByDay[dayKey].splice(slotIndex, 0, slot);
          this.alertService.error(
            'settings.calendar.working-hours.delete-error'
          );
        }
      );
    } else {
      this.alertService.error('Slot not found');
    }
  }

  saveSlot(slot: WeeklyAvailabilitySlot): void {
    if (
      SlotHelper.isSlotOverlapping(
        slot,
        this.availabilitySlotsByDay[slot.weekday] || []
      )
    ) {
      this.alertService.error('add-error-overlap');
      return;
    }

    // Optimistically add the new slot
    const newSlot = { ...slot, id: Date.now() }; // Temporarily assign an id
    if (!this.availabilitySlotsByDay[newSlot.weekday]) {
      this.availabilitySlotsByDay[newSlot.weekday] = [];
    }
    this.availabilitySlotsByDay[newSlot.weekday].push(newSlot);

    // Sort slots for the day after adding the new slot
    Object.keys(this.availabilitySlotsByDay).forEach((day) => {
      this.availabilitySlotsByDay[day].sort((a, b) => {
        const aStart = dayjs()
          .set('hour', a.start_hour)
          .set('minute', a.start_minute);
        const bStart = dayjs()
          .set('hour', b.start_hour)
          .set('minute', b.start_minute);
        return aStart.diff(bStart);
      });
    });

    this.showWorkingHoursModal = false;

    this.settingsService.createAvailabilitySlot(slot).subscribe(
      () => {
        this.alertService.success(
          'settings.calendar.working-hours.add-success'
        );
        this.updateSlots(); // Get the latest slots from the server
      },
      () => {
        // Revert the change if the request fails
        const index = this.availabilitySlotsByDay[newSlot.weekday].findIndex(
          (slot) => slot.id === newSlot.id
        );
        this.availabilitySlotsByDay[newSlot.weekday].splice(index, 1);
        this.alertService.error('settings.calendar.working-hours.add-error');
      }
    );
  }
}

type GroupedDateSpecificAvailability = {
  startDate: string;
  endDate: string;
  slots: WeeklyAvailabilitySlot[];
};
