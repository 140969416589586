import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { Channel } from 'angular2-actioncable';
import { OnlineSession } from 'src/app/entities/onlinesession.model';
import { User } from 'src/app/entities/user.model';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';
import { VideoService } from 'src/app/shared/video/video.service';
import { WebsocketService } from '../messaging/service/websocket.service';
import { createNewWaitingRoomLink } from '../../shared/helpers/navigation_helper';
import { TranslateModule } from '@ngx-translate/core';
import { ProfilePictureComponent } from '../../shared/components/profile-picture/profile-picture.component';
import { Router, RouterLink } from '@angular/router';
import { NgFor, NgIf } from '@angular/common';
import { SidenavComponent } from '../../frame/sidenav/sidenav.component';
import dayjs from 'dayjs';

@Component({
  selector: 'app-waiting-room',
  templateUrl: './waiting-room.component.html',
  styleUrls: ['./waiting-room.component.css'],
  imports: [
    SidenavComponent,
    NgIf,
    RouterLink,
    NgFor,
    ProfilePictureComponent,
    TranslateModule,
  ],
})
export class WaitingRoomComponent implements OnInit {
  public currentUser: User;
  public userChannel: Channel;
  public waitingSessions: OnlineSession[] = [];
  public hasAccess = false;
  public showJoinBetaCallout = false;
  public showUpgradeCallout = false;

  waitingRoomLink: string;

  constructor(
    private webSocketService: WebsocketService,
    private http: GenericHttpService,
    private clipboard: Clipboard,
    private videoService: VideoService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit() {
    this.http.get('/current_user').subscribe((user) => {
      this.currentUser = user.user ? user.user : user;
      if (this.currentUser?.type !== 'Therapist') {
        this.alertService.error(
          'You are not allowed to access this page, please check the URL and try again',
          'Sorry',
          true
        );
        this.router.navigate(['/home']);
      }
      this.hasAccess = this.currentUser?.abilities?.can_start_video_call;
      this.showUpgradeCallout =
        !this.currentUser?.abilities?.can_start_video_call;

      this.listenToWaitingClients();
      this.waitingRoomLink = createNewWaitingRoomLink(
        this.currentUser.profile.slug
      );
    });
  }

  openCommunityBetaPost() {
    window.open(
      'https://community.complicated.life/c/announcements/early-access-to-our-new-improved-video-calling-solution',
      '_blank'
    );
  }

  copyToClipboard() {
    this.clipboard.copy(this.waitingRoomLink);
    this.alertService.success('Link copied to clipboard');
  }

  joinSession(session: OnlineSession) {
    this.videoService.navigateToGetstreamVideoCall(
      this.videoService.generateAdHocWaitingRoomCallSlug(
        this.currentUser.profile.id,
        session
      )
    );
  }

  private fetchClients() {
    this.http.get('/onlinesessions').subscribe((sessions: OnlineSession[]) => {
      this.waitingSessions = (sessions || [])
        .filter(isSessionOutOfDate)
        .sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });
    });
  }

  private listenToWaitingClients() {
    this.fetchClients();
    this.userChannel = this.webSocketService.getUserChannel(this.currentUser);
    this.userChannel.received().subscribe((data) => {
      if (
        data.signal === 'new_video_wait' ||
        data.signal === 'remove_video_wait'
      ) {
        this.fetchClients();
      }
    });
  }

  protected readonly dayjs = dayjs;
}

function isSessionOutOfDate(session: OnlineSession): boolean {
  const hours_ago_3 = Date.now() - 1000 * 60 * 60 * 3;
  return new Date(session.created_at).getTime() > hours_ago_3;
}
