import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-stream-conversation-loading',
    templateUrl: './stream-conversation-loading.component.html',
    imports: [NgIf]
})
export class StreamConversationLoadingComponent {
  @Input() isActiveChannel: boolean;
  @Input() isError: boolean;
}
