<nav class="level balance" *ngIf="balances">
  <div class="balance-container">
    <!-- Unpaid Column -->
    <div class="balance-item">
      <div class="balance-content">
        <span class="balance-label">{{ "office.balance.unpaid_invoices" | translate }}</span>
        <div class="balance-value">
          <ng-container *ngFor="let currency of currencies">
            <p class="balance" *ngIf="hasUnpaidBalance(currency)">
              {{ balances[currency].unpaid / 100 | currency : (currency | uppercase) }}
              <span class="count">({{ balances[currency].unpaid_count }})</span>
            </p>
          </ng-container>
          <p class="balance" *ngIf="!hasAnyUnpaidBalances()">-</p>
        </div>
      </div>
    </div>

    <div class="arrow-container">
      <mat-icon class="arrow-icon">chevron_right</mat-icon>
    </div>

    <!-- Processing Column -->
    <div class="balance-item">
      <div class="balance-content">
        <span class="balance-label">{{ "office.balance.processing_invoices" | translate }}</span>
        <div class="balance-value">
          <ng-container *ngFor="let currency of currencies">
            <p class="balance" *ngIf="hasPendingBalance(currency)">
              {{ balances[currency].pending / 100 | currency : (currency | uppercase) }}
            </p>
          </ng-container>
          <p class="balance" *ngIf="!hasAnyPendingBalances()">-</p>
        </div>
      </div>
    </div>

    <div class="arrow-container">
      <mat-icon class="arrow-icon">chevron_right</mat-icon>
    </div>

    <!-- Account Balance Column -->
    <div class="balance-item">
      <div class="balance-content">
        <span class="balance-label">{{ "office.balance.account_balance" | translate }}</span>
        <div class="balance-value">
          <ng-container *ngFor="let currency of currencies">
            <p class="balance is-account" *ngIf="hasAvailableBalance(currency)">
              {{ balances[currency].available / 100 | currency : (currency | uppercase) }}
            </p>
          </ng-container>
          <p class="balance is-account" *ngIf="!hasAnyAvailableBalances()">-</p>
        </div>
      </div>
    </div>
  </div>
</nav>
