import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { StatisticsService } from './service/statistics.service';
import { ChartConfiguration, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

import { User } from '../../entities/user.model';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { LoadingAnimationComponent } from '../../shared/components/loading-animation/loading-animation.component';
import { NgClass, NgFor, NgIf, PercentPipe } from '@angular/common';
import { DateRangePickerComponent } from '../../shared/components/date-range-picker/date-range-picker.component';
import dayjs from 'dayjs';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
  imports: [
    DateRangePickerComponent,
    NgIf,
    NgFor,
    NgClass,
    BaseChartDirective,
    LoadingAnimationComponent,
    RouterLink,
    PercentPipe,
    TranslateModule,
  ],
})
export class StatisticsComponent implements OnInit {
  constructor(private statsService: StatisticsService) {}

  public totals: object = {};
  public trend: object = {};

  public isFake: boolean;

  public showSortBy: boolean;
  public currentlyRendered: any;
  public chartLabels: any;

  public chartData: ChartConfiguration['data'] = null;
  public previousData: Object;

  @Input()
  user: User;

  public lineChartOptions: ChartOptions & { annotation: any } = {
    annotation: {},
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    responsive: true,
    animation: false,
  };
  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;

  ngOnInit() {}

  getStats(dayrange: any) {
    if (!this.user.abilities.can_view_statistics) {
      this.statsService.getFakeStats().subscribe((stats) => {
        this.setupGraph(stats);
        this.isFake = true;
      });
      return;
    }

    this.statsService.getInsights(dayrange).subscribe((stats) => {
      this.setupGraph(stats);
    });
  }

  setupGraph(stats) {
    this.chartLabels = stats.days.map((day) => dayjs(day).format('MMM Do'));

    const greenColors = {
      backgroundColor: 'rgba(106,205,187,0.2)', // Light green background
      borderColor: 'rgba(106,205,187,1)', // Green border
      pointBackgroundColor: 'rgba(106,205,187,1)', // Green points
      pointBorderColor: '#fff', // White point borders
      pointHoverBackgroundColor: '#fff', // White hover background
      pointHoverBorderColor: 'rgba(106,205,187,0.8)', // Light green hover border
    };

    const yellowColors = {
      backgroundColor: 'rgba(255,221,87,0.2)',
      borderColor: 'rgba(255,221,87,1)',
      pointBackgroundColor: 'rgba(255,221,87,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(255,221,87,1)',
    };

    this.chartData = {
      datasets: stats.data.map((stat, index) => ({
        label: stat.label,
        data: stat.data,
        hidden: true,
        tension: 0.3,
        fill: true,
        ...[greenColors, yellowColors][index % 2],
      })),
      labels: this.chartLabels,
    };
    this.previousData = stats.previous;
    stats.data.forEach((stat) => {
      this.totals[stat.label] = stat.data.reduce(
        (sum, current) => sum + current,
        0
      );

      this.trend[stat.label] =
        (this.totals[stat.label] - this.previousData[stat.label]) /
        this.previousData[stat.label];
      this.trend[stat.label] = this.trend[stat.label] || 0;
      stat.hidden = true;
    });
    this.chartData.datasets[0].hidden = false;
  }

  onDatePickerChange(event: any) {
    this.getStats(event);
  }

  toggleSortBy() {
    this.showSortBy = !this.showSortBy;
  }

  toggleStat(data: any) {
    this.chartData.datasets.forEach((stat) => {
      stat.hidden = true;
    });
    data.hidden = false;
    this.chart.chart.update();
  }
}
