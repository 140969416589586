<div [@simpleFadeAnimation]="'in'" *ngIf="plan">
  <div class="card">
    <div class="plan-container image-background-community">
      <h4 class="title">
        {{ header }}
      </h4>
      <div class="feature-subtitle">{{ plan.subtitle }}</div>
      <div class="feature-and-price-layout">
        <div>
          <div class="feature-header">{{ plan.feature_header }}</div>
          <ul class="features">
            <li *ngFor="let feature of plan.plan_features">
      <span
        class="feature-tooltip"
        [ngClass]="{ highlighted: feature.highlighted }"
        matTooltipClass="mat-tooltip"
        [matTooltipPosition]="'above'"
        matTooltip="{{ feature.tooltip }}"
      >
        <mat-icon class="mat-tooltip-trigger-icon material-symbols-outlined"
        >info_outline</mat-icon
        >
      </span>
              <span
                class="description"
                [ngClass]="{ highlighted: false }"
              >{{ feature.description }}</span
              >
            </li>
          </ul>
        </div>
        <div class=".is-divider-vertical"></div>
        <div class="prices">
          <div class="priceLayoutYearly">
            <span class="has-text-weight-semibold is-highlighted is-size-4">{{ "office.settings.subscription.with_a_1_year_commitment" | translate }}</span>
            <div class="price">
        <span class="has-text-weight-semibold">{{
            4.90 | currency : "EUR"
          }}</span
        >/mo
            </div>
            <button
              class="button is-rounded is-fullwidth is-primary is-large"
              (click)="onPlanSelected(this.paymentPlanYearly)"
            >
              {{ctaText}}
            </button>
            <span class="has-text-weight-semibold is-highlighted">{{yearlySavingsText}}</span>
          </div>
          <div class="priceLayout" style="flex: 1">
            <span
              class="has-text-weight-semibold is-highlighted is-size-4">{{ "office.settings.subscription.higher_monthly_price_but_no_commitment" | translate }}</span>
            <div class="price">
        <span class="has-text-weight-semibold">{{
            6.90 | currency : "EUR"
          }}</span
        >/mo
            </div>
            <button
              class="button is-rounded is-fullwidth is-primary is-large"
              (click)="onPlanSelected(this.paymentPlanMonthly)"
            >
              {{ctaText}}
            </button>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>
