<div *ngIf="currentUser" class="container-fluid container-fluid-limited">
  <div class="columns is-desktop is-marginless">
    <div class="column is-3 is-mobile is-flex-touch sidebar">
      <app-sidenav [isSettings]="true"></app-sidenav>
    </div>
    <div class="column is-9 settings">
      <ng-container *ngIf="currentSection == 'settings'">
        <div [@simpleFadeAnimation]="'in'">
          <h3 class="subtitle is-4 is-hidden-touch">
            {{ "settings.account" | translate }}
          </h3>
          <div class="card has-border">
            <div class="card-content">
              <div class="columns is-mobile">
                <div class="column is-2">
                  <span class="label">{{
                    "common.first-name" | translate
                  }}</span>
                </div>
                <div *ngIf="!editFirstName" class="column">
                  <span>{{ currentUser.profile.firstname }}</span>
                  <span (click)="startEditingFirstName()">
                    <i class="material-icons pointer">edit</i>
                  </span>
                </div>
                <div *ngIf="editFirstName" class="column">
                  <div class="field has-addons">
                    <div class="control is-expanded">
                      <input
                        type="text"
                        class="input"
                        [class.is-loading]="updatingUser"
                        name="editFirstName"
                        [(ngModel)]="firstName"
                        (keydown)="updateUser($event)"
                        placeholder="{{ 'common.first-name' | translate }}"
                      />
                    </div>
                    <div class="control">
                      <button
                        class="button is-small is-borderless"
                        (click)="cancelEditingFirstName()"
                        [disabled]="updatingUser"
                      >
                        <i class="material-icons">close</i>
                      </button>
                    </div>
                  </div>
                  <p class="help">
                    {{ "settings.press_enter_to_save" | translate }}
                  </p>
                </div>
              </div>

              <div class="columns is-mobile">
                <div class="column is-2">
                  <span class="label">{{
                    "common.last-name" | translate
                  }}</span>
                </div>
                <div *ngIf="!editLastName" class="column">
                  <span>{{ currentUser.profile.lastname }}</span>
                  <span (click)="startEditingLastName()">
                    <i class="material-icons pointer">edit</i>
                  </span>
                </div>
                <div *ngIf="editLastName" class="column">
                  <div class="field has-addons">
                    <div class="control is-expanded">
                      <input
                        type="text"
                        class="input"
                        [class.is-loading]="updatingUser"
                        name="editLastName"
                        [(ngModel)]="lastName"
                        (keydown)="updateUser($event)"
                        placeholder="{{ 'common.last-name' | translate }}"
                      />
                    </div>
                    <div class="control">
                      <button
                        class="button is-small is-borderless"
                        (click)="cancelEditingLastName()"
                        [disabled]="updatingUser"
                      >
                        <i class="material-icons">close</i>
                      </button>
                    </div>
                  </div>
                  <p class="help">
                    {{ "settings.press_enter_to_save" | translate }}
                  </p>
                </div>
              </div>
              <div class="columns is-mobile">
                <div class="column is-2">
                  <span class="label">{{ "settings.email" | translate }}</span>
                </div>
                <div *ngIf="!editingEmail" class="column">
                  <span class="is-flex">
                    <span class="is-3">{{ currentUser.email }}</span>
                    <span (click)="startEditingEmail()">
                      <i class="material-icons pointer">edit</i>
                    </span>
                  </span>
                </div>
                <div *ngIf="editingEmail" class="column">
                  <div class="field has-addons">
                    <div class="control is-expanded">
                      <input
                        type="email"
                        class="input"
                        [class.is-loading]="updatingUser"
                        name="newEmail"
                        [(ngModel)]="newEmail"
                        (keydown)="updateUser($event)"
                        placeholder="{{
                          'settings.enter_new_email' | translate
                        }}"
                      />
                    </div>
                    <div class="control">
                      <button
                        class="button is-small is-borderless"
                        (click)="cancelEditingEmail()"
                        [disabled]="updatingUser"
                      >
                        <i class="material-icons">close</i>
                      </button>
                    </div>
                  </div>
                  <p class="help">
                    {{ "settings.press_enter_to_save" | translate }}
                  </p>
                </div>
              </div>
              <div class="columns">
                <div class="column is-2">
                  <span class="label"
                    >{{ "settings.password" | translate }}
                    <div
                      class="qmarkCircle tooltip is-tooltip-multiline"
                      [attr.data-tooltip]="
                        'settings.change-password-tooltip' | translate
                      "
                    ></div>
                  </span>
                </div>
                <div *ngIf="!editingEmail" class="column">
                  <button
                    (click)="startPasswordResetProcess()"
                    [disabled]="resetingPassword"
                    class="button is-outlined is-rounded is-small"
                  >
                    {{ "settings.request-pw-change" | translate }}
                  </button>
                </div>
              </div>
              <div class="columns">
                <div class="column is-2">
                  <span class="label"
                    >{{ "settings.timezone" | translate }}
                    <div
                      class="qmarkCircle tooltip is-tooltip-multiline"
                      [attr.data-tooltip]="
                        'settings.timezone-tooltip' | translate
                      "
                    ></div>
                  </span>
                </div>
                <div class="column is-3">
                  <ng-select
                    class="custom"
                    [(ngModel)]="currentUser.profile.timezone"
                    [groupBy]="groupTZ"
                    [clearable]="false"
                    id="timezones"
                    *ngIf="timezones"
                    (change)="updateSettings()"
                  >
                    <ng-option *ngFor="let tz of timezones" [value]="tz"
                      >{{ tz }}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="columns">
                <div class="column is-2">
                  <span class="label">{{
                    "settings.language" | translate
                  }}</span>
                </div>
                <div class="column">
                  <div class="select is-rounded is-small">
                    <select [(ngModel)]="currentUser.locale">
                      <option value="en">
                        {{ "settings.en" | translate }}
                      </option>
                      <option value="de">
                        {{ "settings.de" | translate }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="currentUser.type == 'Client'">
                <div class="columns">
                  <div class="column is-2">
                    <span class="label">{{
                      "settings.profile-pic" | translate
                    }}</span>
                  </div>
                  <div class="column">
                    <button
                      (click)="deletePhotos()"
                      class="button is-outlined is-rounded is-small"
                      [ngClass]="{ 'is-loading': isDeletingPhoto }"
                    >
                      {{ "settings.del-profile-pic" | translate }}
                    </button>
                  </div>
                </div>
                <div class="columns is-mobile">
                  <div class="column is-2">
                    <span class="label">
                      {{ "settings.eap-voucher-code" | translate }}
                      <div
                        class="qmarkCircle tooltip is-tooltip-multiline"
                        [attr.data-tooltip]="
                          'settings.eap-voucher-code-tooltip' | translate
                        "
                      ></div>
                    </span>
                  </div>
                  <div *ngIf="!editEAPAccessCode" class="column">
                    <span>{{ currentUser.eap_access_code }}</span>
                    <span (click)="startEditingEAPCode()">
                      <i class="material-icons pointer">edit</i>
                    </span>
                  </div>
                  <div *ngIf="editEAPAccessCode" class="column">
                    <div class="field has-addons">
                      <div class="control is-expanded">
                        <input
                          type="text"
                          class="input"
                          [class.is-loading]="updatingUser"
                          name="editEAPAccessCode"
                          [(ngModel)]="eapAccessCode"
                          (keydown)="updateUser($event)"
                        />
                      </div>
                      <div class="control">
                        <button
                          class="button is-small is-borderless"
                          (click)="cancelEditingEAPCode()"
                          [disabled]="updatingUser"
                        >
                          <i class="material-icons">close</i>
                        </button>
                      </div>
                    </div>
                    <p class="help">
                      {{ "settings.press_enter_to_save" | translate }}
                    </p>
                  </div>
                </div>
              </ng-container>
              <div class="columns">
                <div class="column is-2">
                  <span class="label"
                    >{{ "settings.marketing_opt_in" | translate }}
                    <div
                      class="qmarkCircle tooltip is-tooltip-multiline"
                      [attr.data-tooltip]="
                        'settings.marketing_opt_in-tooltip' | translate
                      "
                    ></div>
                  </span>
                </div>
                <div class="column is-3">
                  <label class="switch">
                    <input
                      name="enableMarketingEmails"
                      (change)="onEnableMarketingEmails($event.target.checked)"
                      [(ngModel)]="currentUser.marketing_email_opt_in"
                      type="checkbox"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div class="columns">
                <div class="column is-2">
                  <span class="label"
                    >{{ "settings.newsletter_opt_in" | translate }}
                    <div
                      class="qmarkCircle tooltip is-tooltip-multiline"
                      [attr.data-tooltip]="
                        'settings.newsletter_opt_in-tooltip' | translate
                      "
                    ></div>
                  </span>
                </div>
                <div class="column is-3">
                  <label class="switch">
                    <input
                      name="enableNewsletter"
                      (change)="onEnableNewsletterEmails($event.target.checked)"
                      [(ngModel)]="currentUser.newsletter_email_opt_in"
                      type="checkbox"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>

              <div class="columns">
                <div class="column is-2"><span class="label"></span></div>
                <div class="column">
                  <button
                    class="button is-primary is-rounded"
                    [ngClass]="{ 'is-loading': updatingUser }"
                    (click)="updateSettings()"
                  >
                    {{ "settings.save-settings" | translate }}
                  </button>
                </div>
              </div>
              <hr />
              <div class="columns">
                <div class="column is-2">
                  <span class="label">{{
                    "settings.delete-account" | translate
                  }}</span>
                </div>
                <div class="column">
                  <button
                    (click)="showDeleteModal = true"
                    class="button is-danger is-outlined is-small is-rounded"
                    [disabled]="
                      currentUser.type == 'Therapist' && hasActiveSubscription
                    "
                  >
                    {{ "settings.delete-account" | translate }}
                  </button>
                  <p
                    *ngIf="
                      currentUser.type == 'Therapist' && hasActiveSubscription
                    "
                    class="delete-text"
                  >
                    {{ "settings.delete-account-desc" | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </ng-container>
      <ng-container *ngIf="currentSection == 'calendar'">
        <div [@simpleFadeAnimation]="'in'">
          <h3 class="subtitle is-4 is-hidden-touch">
            {{ "settings.cal-settings" | translate }}
          </h3>
          <div class="card has-border slots">
            <div class="card-content">
              <h3 class="subtitle is-5">
                {{ "settings.enable-cal" | translate }}
              </h3>
              <div class="columns">
                <div class="column is-1">
                  <label class="switch">
                    <input
                      name="enableCalendar"
                      (change)="onEnableCalendarChange($event.target.checked)"
                      [(ngModel)]="currentUser.profile.calendar_enabled"
                      type="checkbox"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
                <div class="column">
                  <p>
                    {{ "settings.enable-cal-desc" | translate }}
                  </p>
                </div>
              </div>
              <div
                [@simpleFadeAnimation]="'in'"
                *ngIf="currentUser.profile.calendar_enabled"
              >
                <hr />
                <app-calendar-working-hours
                  [currentUser]="currentUser"
                ></app-calendar-working-hours>
                <hr />
                <h3 class="subtitle is-5">
                  {{ "settings.session-duration" | translate }}
                </h3>
                <div class="content">
                  <p>{{ "settings.session-duration-desc" | translate }}</p>
                  <p>
                    {{ "settings.bookable-every" | translate
                    }}<strong>{{
                      currentUser.profile.appointment_duration +
                        currentUser.profile.appointment_break_duration
                    }}</strong>
                    {{ "settings.minutes" | translate }}
                  </p>
                </div>
                <div class="columns">
                  <div class="column">
                    <div class="field">
                      <label class="label">{{
                        "settings.app-duration" | translate
                      }}</label>
                      <div class="select is-fullwidth">
                        <select
                          [(ngModel)]="currentUser.profile.appointment_duration"
                          (ngModelChange)="updateDuration()"
                        >
                          <option [ngValue]="5">5 min</option>
                          <option [ngValue]="10">10 min</option>
                          <option [ngValue]="15">15 min</option>
                          <option [ngValue]="20">20 min</option>
                          <option [ngValue]="25">25 min</option>
                          <option [ngValue]="30">30 min</option>
                          <option [ngValue]="35">35 min</option>
                          <option [ngValue]="40">40 min</option>
                          <option [ngValue]="45">45 min</option>
                          <option [ngValue]="50">50 min</option>
                          <option [ngValue]="55">55 min</option>
                          <option [ngValue]="60">60 min</option>
                          <option [ngValue]="65">65 min</option>
                          <option [ngValue]="70">70 min</option>
                          <option [ngValue]="75">75 min</option>
                          <option [ngValue]="80">80 min</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="field">
                      <label class="label">{{
                        "settings.break-time" | translate
                      }}</label>
                      <div class="select is-fullwidth">
                        <select
                          [(ngModel)]="
                            currentUser.profile.appointment_break_duration
                          "
                          (ngModelChange)="updateDuration()"
                        >
                          <option [ngValue]="0">0 min</option>
                          <option [ngValue]="5">5 min</option>
                          <option [ngValue]="10">10 min</option>
                          <option [ngValue]="15">15 min</option>
                          <option [ngValue]="20">20 min</option>
                          <option [ngValue]="25">25 min</option>
                          <option [ngValue]="30">30 min</option>
                          <option [ngValue]="35">35 min</option>
                          <option [ngValue]="40">40 min</option>
                          <option [ngValue]="45">45 min</option>
                          <option [ngValue]="50">50 min</option>
                          <option [ngValue]="55">55 min</option>
                          <option [ngValue]="60">60 min</option>
                          <option [ngValue]="65">65 min</option>
                          <option [ngValue]="70">70 min</option>
                          <option [ngValue]="75">75 min</option>
                          <option [ngValue]="80">80 min</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h3 class="subtitle is-5">
                  {{ "settings.app-notification-title" | translate }}
                </h3>
                <div class="content">
                  <p>{{ "settings.app-notification-desc" | translate }}</p>
                </div>
                <div class="columns">
                  <div class="column">
                    <div class="field">
                      <label class="label">{{
                        "settings.app-notification-time" | translate
                      }}</label>
                      <div class="select is-fullwidth">
                        <select
                          [(ngModel)]="
                            currentUser.profile
                              .min_appointment_notification_time
                          "
                          (ngModelChange)="
                            updateMinAppointmentNotificationTime()
                          "
                        >
                          <option [ngValue]="30">
                            {{ "common.durations.30_minutes" | translate }}
                          </option>
                          <option [ngValue]="60">
                            {{ "common.durations.1_hour" | translate }}
                          </option>
                          <option [ngValue]="90">
                            {{ "common.durations.90_minutes" | translate }}
                          </option>
                          <option [ngValue]="120">
                            {{ "common.durations.2_hours" | translate }}
                          </option>
                          <option [ngValue]="180">
                            {{ "common.durations.3_hours" | translate }}
                          </option>
                          <option [ngValue]="240">
                            {{ "common.durations.4_hours" | translate }}
                          </option>
                          <option [ngValue]="300">
                            {{ "common.durations.5_hours" | translate }}
                          </option>
                          <option [ngValue]="360">
                            {{ "common.durations.6_hours" | translate }}
                          </option>
                          <option [ngValue]="720">
                            {{ "common.durations.12_hours" | translate }}
                          </option>
                          <option [ngValue]="1440">
                            {{ "common.durations.24_hours" | translate }}
                          </option>
                          <option [ngValue]="2880">
                            {{ "common.durations.48_hours" | translate }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <app-calendar-integrations
                  [currentUser]="currentUser"
                ></app-calendar-integrations>
                <hr />
                <h3 id="export" class="subtitle is-5">
                  {{ "settings.export-cal" | translate }}
                </h3>
                <div class="columns">
                  <div class="column is-1">
                    <label class="switch">
                      <input
                        name="enableCalendar"
                        (change)="onEnableICSChange($event.target.checked)"
                        [(ngModel)]="currentUser.profile.calendar_feed_enabled"
                        type="checkbox"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div class="column">
                    <p>
                      {{ "settings.export-as-ics" | translate }}
                    </p>
                    <p *ngIf="currentUser.profile.calendar_feed_enabled">
                      <input
                        class="input"
                        readonly
                        [value]="
                          env.baseUrl +
                          '/calendar/' +
                          currentUser.profile.calendar_feed
                        "
                      />
                      <span class="help is-danger">{{
                        "settings.keep-link-safe" | translate
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentSection == 'notifications'">
        <div [@simpleFadeAnimation]="'in'">
          <h3 class="subtitle is-4 is-hidden-touch">
            {{ "settings.notifications" | translate }}
          </h3>
          <div class="card has-border">
            <div class="card-content">
              <p>
                {{ "settings.we-will-notify-you" | translate }}
                <br />
                {{ "settings.choose-how-we-notify" | translate }}
              </p>

              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>{{ "settings.messages" | translate }}</th>
                    <td>
                      <label class="switch">
                        <input
                          *ngIf="messageSettings"
                          name="msgEmail"
                          (change)="onNotificationSettingsChange()"
                          [(ngModel)]="messageSettings.email"
                          type="checkbox"
                        />
                        <span class="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  <tr *ngIf="currentUser.type == 'Therapist'">
                    <th>{{ "settings.appointments" | translate }}</th>
                    <td>
                      <label class="switch">
                        <input
                          *ngIf="appointmentSettings"
                          name="appEmail"
                          (change)="onNotificationSettingsChange()"
                          [(ngModel)]="appointmentSettings.email"
                          type="checkbox"
                        />
                        <span class="slider round"></span>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-container>

      <app-programs *ngIf="currentSection == 'programs'"></app-programs>

      <app-subscription
        *ngIf="currentSection == 'subscription'"
        [currentUser]="currentUser"
      ></app-subscription>

      <app-subscription-callback
        *ngIf="currentSection == 'subscription-callback'"
      ></app-subscription-callback>

      <app-plan-picker
        *ngIf="currentSection == 'plan-picker'"
        [currentUser]="currentUser"
      ></app-plan-picker>

      <app-single-plan-picker
        *ngIf="currentSection == 'community-plan'"
        [planKind]="'community'"
        [header]="
          'office.settings.subscription.community_plan_header' | translate
        "
        [ctaText]="
          'office.settings.subscription.stay_part_of_the_community' | translate
        "
        [yearlySavingsText]="
          'office.settings.subscription.pay_for_a_year_and_save'
            | translate : { discount: '29%' }
        "
        [confirmationTitleText]="
          'office.settings.subscription.plan_downgrade_to_community_confirmation.title'
            | translate
        "
        [confirmationMessageText]="
          'office.settings.subscription.plan_downgrade_to_community_confirmation.message'
            | translate
        "
      ></app-single-plan-picker>

      <ng-container *ngIf="currentSection == 'widgets'">
        <div [@simpleFadeAnimation]="'in'">
          <h3 class="subtitle is-4 is-hidden-touch">
            {{ "settings.widgets" | translate }}
          </h3>
          <div class="card has-border">
            <div class="card-content">
              <social-widgets></social-widgets>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="modal" [ngClass]="{ 'is-active': showDeleteModal }">
        <div class="modal-background"></div>
        <div class="modal-content">
          <a class="delete" (click)="showDeleteModal = false"></a>
          <h3 class="modal-title">
            {{ "settings.deleting-account" | translate }}
          </h3>
          <p>{{ "settings.deleting-account-desc" | translate }}</p>
          <div class="field level">
            <div class="level-left">
              <button
                class="button is-danger is-rounded is-outlined"
                [ngClass]="{ 'is-loading': deletingAccount }"
                style="margin-right: 1rem"
                (click)="deleteUser()"
              >
                {{ "settings.delete-account" | translate }}
              </button>
            </div>
            <div class="level-right">
              <button
                class="button is-primary is-rounded"
                (click)="showDeleteModal = false"
              >
                {{ "settings.keep-account" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" [ngClass]="{ 'is-active': shouldResetPassword }">
  <div class="modal-background"></div>
  <div class="modal-content">
    <a class="delete" (click)="shouldResetPassword = false"></a>
    <h2 class="modal-title">{{ "settings.reset-pw" | translate }}</h2>
    <p>{{ "settings.reset-pw-desc" | translate }}</p>
    <div class="buttons is-right">
      <button
        class="button is-primary is-rounded"
        (click)="logoutToResetPassword()"
        style="margin-right: 1rem"
      >
        {{ "common.logout" | translate }}
      </button>
    </div>
  </div>
</div>
