import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Client } from '../../../entities/client.model';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-booking-form-select-client',
    templateUrl: './booking-form-select-client.component.html',
    styleUrls: ['./booking-form-select-client.component.scss'],
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor, TranslateModule]
})
export class BookingFormSelectClientComponent implements OnInit {
  @Input() requestBookingForm: FormGroup;
  @Input() onCancel: () => void;
  @Input() onNext: () => void;
  @Input() onCreateBlocker: () => void;
  @Input() clients: Client[];
  constructor() {}
  ngOnInit() {}

  next() {
    this.onNext();
  }

  createBlocker() {
    this.onCreateBlocker();
  }
}
