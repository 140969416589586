import { DateAdapter, DAYS_OF_WEEK } from 'angular-calendar';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import minMax from 'dayjs/plugin/minMax';
// Extend dayjs with required plugins
dayjs.extend(weekday);
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);
dayjs.extend(minMax);

export function dayjsAdapterFactory(): DateAdapter {
  function startOfWeek(date: Date | number): Date {
    return dayjs(date).startOf('week').toDate();
  }

  function endOfWeek(date: Date | number): Date {
    return dayjs(date).endOf('week').toDate();
  }

  function getDay(date: Date | number): number {
    return dayjs(date).day();
  }

  return {
    addDays: (date: Date | number, amount: number) =>
      dayjs(date).add(amount, 'day').toDate(),
    addHours: (date: Date | number, amount: number) =>
      dayjs(date).add(amount, 'hour').toDate(),
    addMinutes: (date: Date | number, amount: number) =>
      dayjs(date).add(amount, 'minute').toDate(),
    addSeconds: (date: Date | number, amount: number) =>
      dayjs(date).add(amount, 'second').toDate(),
    differenceInDays: (dateLeft: Date | number, dateRight: Date | number) =>
      dayjs(dateLeft).diff(dayjs(dateRight), 'day'),
    differenceInMinutes: (dateLeft: Date | number, dateRight: Date | number) =>
      dayjs(dateLeft).diff(dayjs(dateRight), 'minute'),
    differenceInSeconds: (dateLeft: Date | number, dateRight: Date | number) =>
      dayjs(dateLeft).diff(dayjs(dateRight), 'second'),
    endOfDay: (date: Date | number) => dayjs(date).endOf('day').toDate(),
    endOfMonth: (date: Date | number) => dayjs(date).endOf('month').toDate(),
    endOfWeek,
    getDay,
    getMonth: (date: Date | number) => dayjs(date).month(),
    isSameDay: (dateLeft: Date | number, dateRight: Date | number) =>
      dayjs(dateLeft).isSame(dayjs(dateRight), 'day'),
    isSameMonth: (dateLeft: Date | number, dateRight: Date | number) =>
      dayjs(dateLeft).isSame(dayjs(dateRight), 'month'),
    isSameSecond: (dateLeft: Date | number, dateRight: Date | number) =>
      dayjs(dateLeft).isSame(dayjs(dateRight), 'second'),
    max: (dates: (Date | number)[]) => {
      const daysArray = dates.map((date) => dayjs(date));
      return dayjs.max(daysArray).toDate();
    },
    setHours: (date: Date | number, hours: number) =>
      dayjs(date).set('hour', hours).toDate(),
    setMinutes: (date: Date | number, minutes: number) =>
      dayjs(date).set('minute', minutes).toDate(),
    startOfDay: (date: Date | number) => dayjs(date).startOf('day').toDate(),
    startOfMinute: (date: Date | number) =>
      dayjs(date).startOf('minute').toDate(),
    startOfMonth: (date: Date | number) =>
      dayjs(date).startOf('month').toDate(),
    startOfWeek,
    getHours: (date: Date | number) => dayjs(date).get('hour'),
    getMinutes: (date: Date | number) => dayjs(date).get('minute'),
    getTimezoneOffset: (date: Date | number) => dayjs(date).utcOffset() * -1,
    addWeeks: (date: Date | number, amount: number) =>
      dayjs(date).add(amount, 'week').toDate(),
    addMonths: (date: Date | number, amount: number) =>
      dayjs(date).add(amount, 'month').toDate(),
    subDays: (date: Date | number, amount: number) =>
      dayjs(date).subtract(amount, 'day').toDate(),
    subWeeks: (date: Date | number, amount: number) =>
      dayjs(date).subtract(amount, 'week').toDate(),
    subMonths: (date: Date | number, amount: number) =>
      dayjs(date).subtract(amount, 'month').toDate(),
    getISOWeek: (date: Date | number) => dayjs(date).week(), // Using week() instead of isoWeek()
    setDate: (date: Date | number, dayOfMonth: number) =>
      dayjs(date).date(dayOfMonth).toDate(),
    setMonth: (date: Date | number, month: number) =>
      dayjs(date).month(month).toDate(),
    setYear: (date: Date | number, year: number) =>
      dayjs(date).year(year).toDate(),
    getDate: (date: Date | number) => dayjs(date).date(),
    getYear: (date: Date | number) => dayjs(date).year(),
  };
}
