<div class="plan-container" [ngClass]="{ 'is-highlighted': isHighlighted }">
  <div *ngIf="isYearlyBilling"  class="discount-banner" [class.visible]="calculateSavings(paymentPlan) >= 40">
    {{ "office.settings.subscription.plan_picker_discount_ends_on" | translate : {
    discount: calculateSavings(paymentPlan) + '%',
    date: '2024-12-21' | localisedDate :'MMM D'
  } }}
  </div>
  <h4 class="title">
    {{ plan.name }}
  </h4>
  <div class="feature-subtitle">{{ plan.subtitle }}</div>
  <div class="details" style="display: flex; align-items: flex-end">
    <div class="plans" style="flex: 1">
      <div class="price-container">
        <div class="price">
          <ng-container *ngIf="paymentPlan.monthly_price_discounted_from">
            <div class="discount-container">
              <span class="original-price">{{paymentPlan.monthly_price_discounted_from / 100 | currency : "EUR"}}</span>
              <span
                class="savings-tag"
                [ngClass]="{'high-savings': calculateSavings(paymentPlan) > 40}"
              >
  -{{calculateSavings(paymentPlan)}}%
</span>
            </div>
          </ng-container>
          <div class="price-with-per-month">
          <span class="current-price">{{monthlyPrice(paymentPlan) | currency : "EUR"}}</span>
            <div class="per-month">
              {{ "office.settings.subscription.per_month" | translate }}
            </div>
          </div>
        </div>
        <div class="price-period" *ngIf="paymentPlan.month == 12">
          {{ "office.settings.subscription.plan_picker_billed_yearly_for" | translate : {
            price: paymentPlan.price / 100 | currency : "EUR"
        } }}
        </div>
      </div>
      <ng-container [ngSwitch]="this.paymentPlan.availability">
        <button
          mat-flat-button
          class="has-margin-top-15"
          *ngSwitchCase="PaymentPlanAvailability.AS_NEW_PLAN"
          (click)="onSelectPlan()"
        >
          {{
            "office.settings.subscription.plan_picker_cta_get_started"
              | translate
          }}
        </button>
        <button
          mat-flat-button
          class="has-margin-top-15"
          *ngSwitchCase="PaymentPlanAvailability.UPGRADE"
          (click)="onSelectPlan()"
        >
          {{
            "office.settings.subscription.plan_picker_cta_upgrade"
              | translate
                : {
                    plan_name: plan.name
                  }
          }}
        </button>
        <button
          mat-stroked-button
          class="has-margin-top-15"
          *ngSwitchCase="PaymentPlanAvailability.DOWNGRADE"
          (click)="onSelectPlan()"
        >
          {{
            "office.settings.subscription.plan_picker_cta_downgrade"
              | translate : { plan_name: plan.name }
          }}
        </button>
        <button
          mat-flat-button
          class="has-margin-top-15"
          *ngSwitchCase="PaymentPlanAvailability.BILLING_CYCLE_CHANGE"
          (click)="onSelectPlan()"
        >
          {{
            (isYearlyBilling
              ? "office.settings.subscription.plan_picker_cta_switch_to_annual"
              : "office.settings.subscription.plan_picker_cta_switch_to_monthly"
            ) | translate
          }}
        </button>
        <div
          *ngSwitchCase="PaymentPlanAvailability.UNAVAILABLE_SHORTER_CYCLE"
          matTooltipClass="mat-tooltip"
          matTooltip="{{
            'office.settings.subscription.plan_picker_cta_not_available_shorter_cycle_tooltip'
              | translate
          }}"
        >
          <button
            mat-flat-button
            class="has-margin-top-15"
            disabled
          >
            {{
              "office.settings.subscription.plan_picker_cta_not_available"
                | translate
            }}
          </button>
        </div>
        <button
          mat-flat-button
          class="has-margin-top-15"
          *ngSwitchCase="PaymentPlanAvailability.UNAVAILABLE_ALREADY_SUBSCRIBED"
          disabled
        >
          {{
            "office.settings.subscription.plan_picker_cta_unavailable_already_subscribed"
              | translate
          }}
        </button>
        <button
          mat-flat-button
          class="has-margin-top-15"
          *ngSwitchCase="PaymentPlanAvailability.UNKNOWN"
          disabled
        >
          {{
            "office.settings.subscription.plan_picker_cta_not_available"
              | translate
          }}
        </button>
      </ng-container>
    </div>
  </div>
  <hr />
  <div class="feature-header">{{ plan.feature_header }}</div>
  <ul class="features">
    <li *ngFor="let feature of plan.plan_features">
      <span
        class="feature-tooltip"
        [ngClass]="{ highlighted: feature.highlighted }"
        matTooltipClass="mat-tooltip"
        [matTooltipPosition]="'above'"
        matTooltip="{{ feature.tooltip }}"
      >
        <mat-icon class="mat-tooltip-trigger-icon material-symbols-outlined"
          >info_outline</mat-icon
        >
      </span>
      <span
        class="description"
        [ngClass]="{ highlighted: feature.highlighted }"
        >{{ feature.description }}</span
      >
    </li>
  </ul>
  <hr />
  <div class="fees-commissions">
    <h6>
      {{ "office.settings.subscription.fees_and_commission" | translate }}
    </h6>
    <div
      *ngIf="plan.initial_feerate == plan.recurring_feerate; else differentFees"
      class="align-center"
    >
      <div
        style="white-space: pre-wrap"
        [innerHTML]="
          'office.settings.subscription.booking_fee_constant'
            | translate : { fee: plan.initial_feerate | percent : '1.0-2' }
        "
      ></div>
    </div>
    <ng-template #differentFees>
      <div
        style="white-space: pre-wrap"
        [innerHTML]="
          'office.settings.subscription.booking_fee_new_repeat'
            | translate
              : {
                  new_fee: plan.initial_feerate | percent : '1.0-2',
                  repeat_fee: plan.recurring_feerate | percent : '1.0-2'
                }
        "
      ></div>
    </ng-template>
  </div>
</div>
