import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { MonitoringService } from './shared/services/monitoring/monitoring.service';

export class RetryTranslateLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private prefix: string = '/assets/i18n/',
    private suffix: string = '.json',
    private maxRetries: number = 3,
    private initialRetryDelay: number = 1000,
    private fallbackLanguage: string = 'en'
  ) {}

  getTranslation(lang: string): Observable<any> {
    if (!lang) {
      MonitoringService.captureMessage(
        'No language provided to translation loader'
      );
      lang = this.fallbackLanguage;
    }

    return this.loadTranslation(lang).pipe(
      catchError((error) => {
        // If primary language fails, try fallback
        if (lang !== this.fallbackLanguage) {
          console.warn(
            `Failed to load ${lang} translations, falling back to ${this.fallbackLanguage}`,
            error
          );
          return this.loadTranslation(this.fallbackLanguage);
        }
        throw error;
      })
    );
  }

  private loadTranslation(lang: string): Observable<any> {
    const url = `${this.prefix}${lang}${this.suffix}`;
    let requestStartTime: number;
    let retryCount = 0;
    let initialError: any = null;

    // Safely get network information
    const getNetworkInfo = () => {
      const connection = (navigator as any).connection;
      if (connection) {
        return {
          effectiveType: connection.effectiveType,
          rtt: connection.rtt,
          saveData: connection.saveData,
        };
      }
      return null;
    };

    return this.http
      .get(url, {
        responseType: 'text', // Keep as text to handle parsing ourselves
        observe: 'response',
      })
      .pipe(
        tap(() => {
          requestStartTime = Date.now();
        }),
        map((response) => {
          try {
            const responseBody = response.body;
            const contentType = response.headers.get('content-type');

            // Check for HTML content first and throw early if detected
            if (
              responseBody.includes('<!DOCTYPE') ||
              responseBody.includes('<html')
            ) {
              const error = new Error('Received HTML instead of JSON');
              error.name = 'TranslationHtmlError';

              MonitoringService.captureMessage(error.message, {
                extra: {
                  lang,
                  url,
                  responsePreview: responseBody.substring(0, 200),
                  contentType,
                },
              });

              throw error;
            }

            const result = JSON.parse(responseBody);

            // Validate the parsed result is an object with translations
            if (!result || typeof result !== 'object') {
              throw new Error('Invalid translation format - expected object');
            }

            // If we got here and retryCount > 0, a retry succeeded
            if (retryCount > 0) {
              MonitoringService.captureMessage('Translation retry succeeded', {
                extra: {
                  lang,
                  url,
                  successfulRetryCount: retryCount,
                  totalDuration: Date.now() - requestStartTime,
                  initialError: initialError?.message,
                  online: navigator.onLine,
                  networkInfo: getNetworkInfo(),
                },
              });
            }

            return result;
          } catch (e) {
            // Only log parse errors, not HTML errors which were already logged
            if (e.name !== 'TranslationHtmlError') {
              MonitoringService.captureMessage('Translation JSON parse error', {
                extra: {
                  lang,
                  url,
                  responsePreview: response.body?.substring(0, 500),
                  error: e.message,
                  errorType: e.constructor.name,
                  contentType: response.headers.get('content-type'),
                  responseLength: response.body?.length,
                },
              });
            }

            // Wrap parsing errors (but pass through HTML errors)
            if (e.name !== 'TranslationHtmlError') {
              const wrappedError = new Error(`JSON Parse Error: ${e.message}`);
              wrappedError.name = 'TranslationParseError';
              throw wrappedError;
            }
            throw e;
          }
        }),
        retry({
          count: this.maxRetries,
          delay: (error, attemptCount) => {
            // Store the first error for context
            if (!initialError) {
              initialError = error;
            }

            retryCount = attemptCount;
            const backoffDelay =
              this.initialRetryDelay * Math.pow(2, attemptCount - 1);

            // Retry on parse errors, HTML errors, network errors, and server errors
            if (
              error.name === 'TranslationParseError' ||
              error.name === 'TranslationHtmlError' ||
              (error instanceof HttpErrorResponse &&
                (error.status === 0 ||
                  (error.status >= 500 && error.status < 600)))
            ) {
              return timer(backoffDelay);
            }

            return throwError(() => error);
          },
        }),
        catchError((error) => {
          // Enhanced error logging
          if (retryCount > 0) {
            MonitoringService.captureMessage(
              'Translation load failed after exhausting retries',
              {
                extra: {
                  lang,
                  url,
                  retriesAttempted: retryCount,
                  finalError: error.message,
                  finalErrorType: error.constructor.name,
                  finalErrorName: error.name,
                  initialError: initialError?.message,
                  initialErrorType: initialError?.constructor.name,
                  status:
                    error instanceof HttpErrorResponse
                      ? error.status
                      : undefined,
                  totalDuration: Date.now() - requestStartTime,
                  online: navigator.onLine,
                  networkInfo: getNetworkInfo(),
                  pageVisibility: document.visibilityState,
                },
              }
            );
          }
          throw error;
        })
      );
  }
}
