import { CryptoService } from 'src/app/components/messaging/service/crypto.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseClass } from '../../shared/base-class';
import { MessagingService } from '../messaging/service/messaging.service';
import { Conversation } from '../../entities/conversation.model';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { Animation } from 'src/app/shared/animations/fade-animation';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { InviteClientsService } from 'src/app/shared/components/invite-clients/service/invite-clients.service';
import { environment } from 'src/environments/environment';
import { OfficeService } from 'src/app/office/shared/service/office.service';
import { VideoService } from 'src/app/shared/video/video.service';
import { Channel } from 'angular2-actioncable';
import { WebsocketService } from '../../components/messaging/service/websocket.service';
import { ProfileService } from 'src/app/components/profile/service/profile.service';
import { Profile } from './../../entities/profile.model';
import { GenericHttpService } from './../../shared/services/Generic HTTP/generic-http.service';
import { FeaturesService } from '../../shared/services/features/features.service';
import {
  createDirectoryLink,
  createEditProfileLink,
} from '../../shared/helpers/navigation_helper';
import { FirstFavoriteAddedDialog } from './first-favorite-added-dialog/first-favorite-added-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from '../../frame/footer/footer.component';
import { StatisticsComponent } from '../statistics/statistics.component';
import { CalendarOverviewComponent } from './calendar-overview/calendar-overview.component';
import { NextAppointmentComponent } from '../../shared/components/next-appointment/next-appointment.component';
import { InvoiceStatisticsBoxComponent } from './invoice-statistics-box/invoice-statistics-box.component';
import { AppointmentsRequiringCompletion } from '../../shared/components/appointments-requiring-completion/appointments-requiring-completion';
import { StatusUpdateBoxComponent } from './status-update-box/status-update-box.component';
import { AnnouncementsComponent } from '../../shared/components/announcements/announcements.component';
import { SidenavComponent } from '../../frame/sidenav/sidenav.component';
import { PhotoUploaderComponent } from '../../shared/photo-uploader/photo-uploader.component';
import { ProfilePictureComponent } from '../../shared/components/profile-picture/profile-picture.component';
import { ClientOnboardingBoxComponent } from './client-onboarding-box/client-onboarding-box.component';
import { OnboardingBoxComponent } from './onboarding-box/onboarding-box.component';
import { NgIf } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { LatestMessagesStreamComponent } from '../../shared/components/latest-messages-stream/latest-messages-stream.component';
import { LauncherComponent } from '../../shared/components/launcher/launcher.component';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [Animation.fadeAnimation],
    imports: [
        NgIf,
        OnboardingBoxComponent,
        ClientOnboardingBoxComponent,
        ProfilePictureComponent,
        PhotoUploaderComponent,
        SidenavComponent,
        RouterLink,
        AnnouncementsComponent,
        StatusUpdateBoxComponent,
        AppointmentsRequiringCompletion,
        InvoiceStatisticsBoxComponent,
        NextAppointmentComponent,
        CalendarOverviewComponent,
        StatisticsComponent,
        FooterComponent,
        TranslateModule,
        LatestMessagesStreamComponent,
        LauncherComponent,
    ]
})
export class HomeComponent extends BaseClass implements OnInit {
  channel: Channel;
  userChannel: Channel;

  profile: Profile;

  @ViewChild('waitingRoomLink', { read: ElementRef, static: false })
  waitingRoomLink: ElementRef;

  clients: any[];
  private inquirySent: boolean;

  constructor(
    private sharedService: SharedService,
    private alertService: AlertService,
    private messagingService: MessagingService,
    private router: Router,
    private notificationsService: NotificationsService,
    private cryptoService: CryptoService,
    private officeService: OfficeService,
    private inviteClientsService: InviteClientsService,
    private webSocketService: WebsocketService,
    private profileService: ProfileService,
    private videoService: VideoService,
    private http: GenericHttpService,
    private featuresService: FeaturesService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private websocketsService: WebsocketService
  ) {
    super(sharedService, alertService, router);
  }

  public events: any = [];
  public date: Date = new Date();
  public conversations: Conversation[];
  public uploadPhoto: boolean;
  public shouldShowProfileLauncher: boolean;

  public crypto_support = true;
  public env = environment;
  public myOfficeAvailable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    window.scrollTo(0, 0);

    this.crypto_support = this.cryptoService.crypto_support;

    if (!this.crypto_support) {
      this.alertService.error(
        'Unfortunately your browser does not support the encryption/decryption processes of our secure messaging system. To view your messages, please upgrade your browser.  If you are using Internet Explorer, you will need to upgrade to Microsoft Edge or an alternate brand.',
        'Browser Warning',
        true,
        null,
        false
      );
    }

    // this.isFirstLogin();
    this.sharedService.currentUser.subscribe((user) => {
      this.currentUser = user;
      if (this.shouldShowProfileLauncher === undefined) {
        // This is to prevent the launcher from disappearing later when it is finished, we check it once and keep the value
        this.shouldShowProfileLauncher =
          this.currentUser &&
          this.currentUser.type === 'Therapist' &&
          !this.currentUser.profile.has_limited_profile &&
          !this.currentUser.finished_launcher_tasks &&
          this.featuresService.isAllowingProfileLauncher(user);
      }

      if (this.currentUser) {
        this.profile = user.profile;
        this.getClients();
        this.getUnreadMessagesCount();
        this.myOfficeAvailable = this.env.stripe_available_countries.includes(
          this.currentUser.profile.country_code
        );

        if (this.currentUser.type === 'Therapist') {
          const s = document.createElement('script');
          s.type = 'text/javascript';
          s.id = 'hs-script-loader';
          s.setAttribute('async', '1');
          s.setAttribute('defer', '1');
          s.src = '//js-na1.hs-scripts.com/6767806.js';
          document.body.appendChild(s);
        }

        this.userChannel = this.webSocketService.getUserChannel(user);
        this.userChannel.received().subscribe((data) => {
          if (
            data.signal === 'new_video_wait' ||
            data.signal === 'remove_video_wait'
          ) {
            this.getClients();
          }
        });
        this.activatedRoute.queryParams.subscribe((params: any) => {
          if (params) {
            if (params['inquiry_sent'] === '1') {
              this.inquirySent = true;
            }
            if (params['favorite_added'] === '1') {
              this.dialog.open(FirstFavoriteAddedDialog, {
                data: {
                  directoryLink: createDirectoryLink(this.currentUser.locale),
                },
              });
            }
          }
        });
      }
    });

    this.profileService.profile.subscribe((profile) => {
      if (profile) {
        this.currentUser.profile = profile;
      }
    });
  }

  private getUnreadMessagesCount() {
    this.notificationsService.getUnreadMessageCount();
  }

  /**
   * Filter to only last message for 'quick' display.
   *
   * @param conversations Conversations array
   *
   */
  public filterToLastMessage(conversations: Conversation[]) {
    conversations.forEach((c: Conversation) => {
      c.messages.splice(1, c.messages.length);
    });
    conversations.splice(3, conversations.length);
    this.conversations = conversations;
  }

  showInviteClientsModal() {
    this.inviteClientsService.openModal();
  }

  createNewInvoice() {
    this.officeService.toggleCreateInvoiceModal(true);
  }

  startCallComponent() {
    this.videoService.navigateToPrivateGetstreamVideoCall(
      this.currentUser.profile.id
    );
  }

  call(id) {
    this.channel = this.webSocketService.getVideoChannel(id);
    this.channel.received().subscribe((data) => {
      if (!!this[data['action']]) {
        this[data['action']](data['data']);
      }
    });
    this.channel.connected().subscribe(() => {
      this.channel.perform('call', {});
    });
  }

  getClients() {
    this.http.get('/onlinesessions').subscribe((sessions) => {
      this.clients = sessions;
    });
  }

  isUsingGetstreamChat(): boolean {
    return (
      this.currentUser &&
      this.featuresService.isUsingGetStreamChat(this.currentUser)
    );
  }

  openProfileEditorV2() {
    window.location.href = createEditProfileLink(
      this.currentUser.locale,
      this.currentUser.profile
    );
  }
}
