import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CalendarService } from '../../calendar/service/calendar.service';
import { AlertService } from '../../../shared/components/alert/service/alert.service';
import { AmplitudeAnalyticsService } from '../../../shared/services/analytics/amplitude-analytics.service';
import { extractErrorMessage } from '../../../shared/helpers/errors.helper';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField } from '@angular/material/form-field';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatLoadingButtonDirective } from '../../../shared/directives/mat-loading-button-directive';

@Component({
    selector: 'appointment-appointment-cancel-confirm-dialog',
    templateUrl: 'appointment-mark-eap-completed-dialog.html',
    styleUrls: ['./appointment-mark-eap-completed-dialog.scss'],
    imports: [
        MatDialogTitle,
        MatDialogContent,
        NgClass,
        MatFormField,
        MatSelect,
        NgFor,
        MatOption,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        TranslateModule,
        FormsModule,
        MatInput,
        NgIf,
        MatLoadingButtonDirective,
    ]
})
export class AppointmentMarkEapCompletedDialog {
  isBusy: boolean = false;
  wasOnline: boolean = undefined;
  language: string = undefined;
  topic: string = undefined;
  customTopic: string = undefined;
  languages: Language[] = [
    { code: 'en', label: 'language_english' },
    { code: 'de', label: 'language_german' },
    { code: 'sq', label: 'language_albanian' },
    { code: 'ar', label: 'language_arabic' },
    { code: 'bs', label: 'language_bosnian' },
    { code: 'bg', label: 'language_bulgarian' },
    { code: 'zh', label: 'language_chinese' },
    { code: 'hr', label: 'language_croatian' },
    { code: 'cs', label: 'language_czech' },
    { code: 'da', label: 'language_danish' },
    { code: 'nl', label: 'language_dutch' },
    { code: 'et', label: 'language_estonian' },
    { code: 'fi', label: 'language_finnish' },
    { code: 'nl', label: 'language_flemish' },
    { code: 'fr', label: 'language_french' },
    { code: 'el', label: 'language_greek' },
    { code: 'he', label: 'language_hebrew' },
    { code: 'hi', label: 'language_hindi' },
    { code: 'hu', label: 'language_hungarian' },
    { code: 'ig', label: 'language_igbo' },
    { code: 'it', label: 'language_italian' },
    { code: 'ja', label: 'language_japanese' },
    { code: 'kn', label: 'language_kannada' },
    { code: 'ko', label: 'language_korean' },
    { code: 'lv', label: 'language_latvian' },
    { code: 'lt', label: 'language_lithuanian' },
    { code: 'mk', label: 'language_macedonian' },
    { code: 'mt', label: 'language_maltese' },
    { code: 'no', label: 'language_norwegian' },
    { code: 'fa', label: 'language_persian' },
    { code: 'pl', label: 'language_polish' },
    { code: 'pt', label: 'language_portuguese' },
    { code: 'ro', label: 'language_romanian' },
    { code: 'ru', label: 'language_russian' },
    { code: 'sr', label: 'language_serbian' },
    { code: 'sk', label: 'language_slovak' },
    { code: 'sl', label: 'language_slovenian' },
    { code: 'es', label: 'language_spanish' },
    { code: 'sv', label: 'language_swedish' },
    { code: 'tr', label: 'language_turkish' },
    { code: 'uk', label: 'language_ukrainian' },
    { code: 'ur', label: 'language_urdu' },
    { code: 'other', label: 'language_other' },
  ];

  topics: Topic[] = [
    { language_key: 'adhd', value: 'adhd' },
    { language_key: 'addictions', value: 'addictions' },
    { language_key: 'anxiety', value: 'anxiety' },
    { language_key: 'autism', value: 'autism' },
    { language_key: 'burnout', value: 'burnout' },
    { language_key: 'depression', value: 'depression' },
    { language_key: 'eating_disorders', value: 'eating_disorders' },
    { language_key: 'family_issues', value: 'family_issues' },
    { language_key: 'gender_identity', value: 'gender_identity' },
    { language_key: 'grief_and_loss', value: 'grief_and_loss' },
    { language_key: 'narcissism', value: 'narcissism' },
    { language_key: 'personal_growth', value: 'personal_growth' },
    { language_key: 'relationships', value: 'relationships' },
    { language_key: 'self_esteem', value: 'self_esteem' },
    { language_key: 'sleep_disorders', value: 'sleep_disorders' },
    { language_key: 'stress', value: 'stress' },
    { language_key: 'trauma', value: 'trauma' },
    { language_key: 'work_issues', value: 'work_issues' },
    { language_key: 'other', value: 'other' },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      sourcePage: 'messages_page' | 'calendar_page' | 'dashboard';
      appointmentId: number;
    },
    public dialogRef: MatDialogRef<AppointmentMarkEapCompletedDialog>,
    private calendarService: CalendarService,
    private alertService: AlertService,
    private analytics: AmplitudeAnalyticsService
  ) {}

  setWasOnline(wasOnline: boolean) {
    this.wasOnline = wasOnline;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCloseSession() {
    if (this.isBusy) {
      return;
    }
    this.isBusy = true;
    this.analytics.trackAppointmentInteractedWith({
      kind: 'marked_eap_session_as_completed',
      source_page: this.data.sourcePage,
    });
    this.calendarService
      .markAppointmentAsCompleted(
        this.data.appointmentId,
        this.wasOnline,
        this.language,
        this.topic === 'other' ? this.customTopic ?? 'other' : this.topic
      )
      .subscribe(
        (res) => {
          this.isBusy = false;
          if (res.status === 'completed') {
            this.alertService.success(
              'booking.alerts.appointment_eap_marked_as_completed'
            );
            this.dialogRef.close({
              appointment: this.wasOnline,
            });
          }
        },
        (error) => {
          // Handle error response
          this.alertService.error(
            extractErrorMessage(
              error,
              'booking.alerts.appointment_eap_marked_as_completed_error'
            )
          );
          this.isBusy = false;
        }
      );
  }
}

export type Language = {
  code: string;
  label: string;
};

export type Topic = {
  language_key: string;
  value: string; // this is what will be sent to the backend
};
