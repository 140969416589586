import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { NgIf } from '@angular/common';
import {
  MatTimepicker,
  MatTimepickerInput,
  MatTimepickerToggle,
} from '@angular/material/timepicker';
import dayjs from 'dayjs';

@Component({
  selector: 'app-time-slot-picker',
  template: `
    <div class="is-flex is-align-items-center flex-gap-10">
      <mat-form-field class="hour_min_field">
        <input
          matInput
          [formControl]="startTimeControl"
          [matTimepicker]="startTimePicker"
          [matTimepickerMax]="_endTime"
          readonly
        />
        <mat-timepicker-toggle [for]="startTimePicker" matSuffix />
        <mat-timepicker interval="5m" #startTimePicker></mat-timepicker>
      </mat-form-field>

      <p>
        {{
          'settings.calendar.date-specific-hours.add-hours-dialog.until'
            | translate
        }}
      </p>

      <mat-form-field class="hour_min_field">
        <input
          matInput
          [formControl]="endTimeControl"
          [matTimepicker]="endTimePicker"
          [matTimepickerMin]="_startTime"
          readonly
        />
        <mat-timepicker interval="5m" #endTimePicker></mat-timepicker>
        <mat-timepicker-toggle [for]="endTimePicker" matSuffix />
      </mat-form-field>

      <button
        *ngIf="allowRemoval"
        class="has-margin-left-5"
        matTooltip="{{
          'settings.calendar.date-specific-hours.add-hours-dialog.new-interval'
            | translate
        }}"
        matTooltipPosition="above"
        mat-icon-button
        (click)="remove.emit()"
        [disabled]="isBusy"
      >
        <mat-icon class="button-icon">clear</mat-icon>
      </button>
    </div>
  `,
  standalone: true,
  imports: [
    MatFormField,
    ReactiveFormsModule,
    MatTimepickerInput,
    MatTimepickerToggle,
    MatIcon,
    MatTimepicker,
    TranslateModule,
    MatInput,
    MatTooltip,
    NgIf,
    MatIconButton,
    MatSuffix,
  ],
})
export class TimeSlotPickerComponent implements OnInit {
  startTimeControl = new FormControl<dayjs.Dayjs | null>(null);
  endTimeControl = new FormControl<dayjs.Dayjs | null>(null);

  _startTime: string;
  _endTime: string;

  @Input()
  get startTime(): string {
    return this._startTime;
  }
  set startTime(value: string) {
    this._startTime = value;
    if (value) {
      const [hours, minutes] = value.split(':').map(Number);
      const date = dayjs().hour(hours).minute(minutes).second(0).millisecond(0);
      this.startTimeControl.setValue(date);
    }
  }

  @Input()
  get endTime(): string {
    return this._endTime;
  }
  set endTime(value: string) {
    this._endTime = value;
    if (value) {
      const [hours, minutes] = value.split(':').map(Number);
      const date = dayjs().hour(hours).minute(minutes).second(0).millisecond(0);
      this.endTimeControl.setValue(date);
    }
  }

  @Input() isBusy: boolean;
  @Input() allowRemoval: boolean = true;
  @Output() startTimeChange = new EventEmitter<string>();
  @Output() endTimeChange = new EventEmitter<string>();
  @Output() remove = new EventEmitter<void>();

  ngOnInit() {
    this.startTimeControl.valueChanges.subscribe((value) => {
      if (value) {
        const timeString = value.format('HH:mm');
        this._startTime = timeString;
        this.startTimeChange.emit(timeString);
      }
    });

    this.endTimeControl.valueChanges.subscribe((value) => {
      if (value) {
        const timeString = value.format('HH:mm');
        this._endTime = timeString;
        this.endTimeChange.emit(timeString);
      }
    });
  }
}
