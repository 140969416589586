import { Component, Inject, OnInit, ViewContainerRef } from '@angular/core';
import { Animation } from '../shared/animations/fade-animation';
import { TranslateService } from '@ngx-translate/core';
import { APP_BASE_HREF, NgClass } from '@angular/common';

import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { SettingsService } from '../components/settings/service/settings.service';
import { VideoService } from '../shared/video/video.service';
import { ICAuthService } from '../auth/service/i-c-auth.service';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { AlertComponent } from '../shared/components/alert/alert.component';
import { HeaderComponent } from '../frame/header/header.component';
import {
  ChannelService,
  ChatClientService,
  StreamI18nService,
} from 'stream-chat-angular';
import { SharedService } from '../shared/services/shared.service';
import { ChatService } from '../components/messaging/service/chat.service';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { MonitoringService } from '../shared/services/monitoring/monitoring.service';
import { DebugComponent } from '../debug/debug.component';
import { LocaleService } from '../office/shared/service/locale.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [Animation.fadeAnimation],
  imports: [
    NgClass,
    HeaderComponent,
    AlertComponent,
    RouterOutlet,
    DebugComponent,
  ],
})
export class AppComponent implements OnInit {
  constructor(
    private auth: ICAuthService,
    private videoService: VideoService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private settings: SettingsService,
    private chatClientService: ChatClientService,
    private channelService: ChannelService,
    private chatService: ChatService,
    private sharedService: SharedService,
    private streamI18nService: StreamI18nService,
    private localeService: LocaleService,
    @Inject(ViewContainerRef) private viewContainerRef: ViewContainerRef, // Added private keyword
    private svgIconRegistryService: SvgIconRegistryService,
    @Inject(APP_BASE_HREF) private baseHref: string
  ) {
    this.localeService.initializeLocale();
    this.videoService.setVideoContainer(viewContainerRef);
    this.addCommonSvgIcons();
  }

  user: boolean;
  isProfile: boolean = false;
  currentComponent: Component;

  ngOnInit() {
    this.auth.initAuth();
    this.auth.isLoggedIn.subscribe(async (userAuthenticated) => {
      if (userAuthenticated) {
        this.sharedService.currentUser.subscribe(async (user) => {
          if (user && environment.getstreamApiKey) {
            try {
              // Added try-catch block
              await this.chatClientService.init(
                environment.getstreamApiKey,
                user.external_id.toString(),
                async () => {
                  const response = await firstValueFrom(
                    this.chatService.getChatToken()
                  );
                  if (!response?.token) {
                    throw new Error('No token received');
                  }
                  return response.token;
                },
                {
                  timeout: 10000,
                  logger: console.log,
                }
              );
              await this.channelService.init(
                {
                  type: 'messaging',
                  members: { $in: [user.external_id.toString()] },
                },
                null,
                null,
                false
              );
            } catch (error) {
              MonitoringService.captureMessage('Chat initialization failed', {
                extra: error,
              });
            }
          }
        });
      } else {
        if (this.chatClientService.chatClient?.user) {
          await this.chatClientService.disconnectUser();
        }
      }
    });
  }

  onActivate(comp: Component) {
    this.currentComponent = comp;
  }

  addCommonSvgIcons() {
    this.svgIconRegistryService.addSvg(
      'badge-w',
      `${this.baseHref}assets/images/svg/badge-w.svg`
    );
    this.svgIconRegistryService.addSvg(
      'badge-g',
      `${this.baseHref}assets/images/svg/badge-g.svg`
    );
    this.svgIconRegistryService.addSvg(
      'plant-with-cup',
      `${this.baseHref}assets/images/svg/plant-with-cup.svg`
    );
    this.svgIconRegistryService.addSvg(
      'profile-picture',
      `${this.baseHref}assets/svg/profile_picture.svg`
    );
    this.svgIconRegistryService.addSvg(
      'green-check',
      `${this.baseHref}assets/images/svg/check-green.svg`
    );
    this.svgIconRegistryService.addSvg(
      'video-person',
      `${this.baseHref}assets/images/svg/video-person.svg`
    );
  }
}
