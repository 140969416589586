import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InvoiceExtended } from 'src/app/entities/invoice.model';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { INVOICE_ACTIONS } from '../shared/models/invoice_actions.enum';
import { OfficeService } from '../shared/service/office.service';
import { TranslateModule } from '@ngx-translate/core';
import { AutosizeModule } from 'ngx-autosize';
import { FormsModule } from '@angular/forms';
import { LoadingAnimationComponent } from '../../shared/components/loading-animation/loading-animation.component';
import { CurrencyPipe, DatePipe, NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'office-invoice-dialog',
  templateUrl: './invoice-dialog.component.html',
  styleUrls: ['./invoice-dialog.component.scss'],
  imports: [
    NgIf,
    NgClass,
    LoadingAnimationComponent,
    FormsModule,
    AutosizeModule,
    CurrencyPipe,
    DatePipe,
    TranslateModule,
  ],
})
export class InvoiceDialogComponent implements OnInit {
  constructor(
    private officeService: OfficeService,
    private alertService: AlertService,
    private router: Router
  ) {}

  invoice: InvoiceExtended;
  action: INVOICE_ACTIONS;
  isExecuting: boolean = false;
  hostedInvoiceLink: string = null;

  @ViewChild('linkField', { read: ElementRef, static: false })
  linkField: ElementRef;

  ngOnInit() {
    this.officeService.currentInvoice.subscribe(
      (invoice) => (this.invoice = invoice)
    );
    this.officeService.currentInvoiceAction.subscribe((action) => {
      this.action = action;
      if (this.action == INVOICE_ACTIONS.LINK) {
        this.officeService
          .getHostedLink(this.invoice.id)
          .subscribe((response) => {
            if (response.status == 'success') {
              this.hostedInvoiceLink = response.link;
            }
          });
      }
    });
  }

  confirm() {
    this.isExecuting = true;
    switch (this.action) {
      case INVOICE_ACTIONS.CANCEL:
        this.officeService.cancelInvoice(this.invoice).subscribe((repsonse) => {
          this.callback();
        });
        break;
      case INVOICE_ACTIONS.REFUND:
        this.officeService.refundInvoice(this.invoice).subscribe((response) => {
          this.callback();
        });
        break;
      case INVOICE_ACTIONS.DELETE:
        this.officeService.deleteInvoice(this.invoice).subscribe((response) => {
          this.callback();
          this.router.navigate(['/office/invoices']);
        });
        break;
      case INVOICE_ACTIONS.MARK_AS_PAID:
        this.officeService
          .markInvoiceAsPaid(this.invoice)
          .subscribe((response) => {
            this.callback();
          });
        break;
    }
  }

  callback() {
    this.alertService.success(
      'office.invoice.modal.' + this.action + '.success'
    );
    this.cancel();
  }

  cancel() {
    this.hostedInvoiceLink = null;
    this.officeService.getCurrentInvoice(this.invoice.id);
    this.officeService.setCurrentInvoice(null);
    this.officeService.setCurrentInvoiceAction(null);
    this.isExecuting = false;
  }

  copyInvoiceLink() {
    var el = this.linkField.nativeElement;
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      var editable = el.contentEditable;
      var readOnly = el.readOnly;
      el.contentEditable = 'true';
      el.readOnly = 'false';
      var range = document.createRange();
      range.selectNodeContents(el);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      el.setSelectionRange(0, 999999);
      el.contentEditable = editable;
      el.readOnly = readOnly;
    } else {
      el.select();
    }
    document.execCommand('copy');
    el.blur();

    this.alertService.success('Code copied to clipboard');
  }
}
