<span
  matTooltipClass="mat-tooltip"
  class="mat-tooltip-trigger-icon-wrapper"
  [matTooltip]="'messaging.eap_member_badge.tooltip' | translate : { company: companyName }"
  style="cursor: pointer;"
>
  <span class="tag eap-tag is-rounded has-text-weight-medium is-small">{{
      'messaging.eap_member_badge.text' | translate
    }}</span>
</span>
