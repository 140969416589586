import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../../entities/user.model';
import { Animation } from '../../../shared/animations/fade-animation';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipe, NgClass, NgIf, UpperCasePipe } from '@angular/common';
import { ProfilePictureComponent } from '../../../shared/components/profile-picture/profile-picture.component';

@Component({
    selector: 'app-favorite-therapist-card',
    templateUrl: './favorite-therapist-card.component.html',
    styleUrls: ['./favorite-therapist-card.component.scss'],
    animations: [Animation.fadeAnimation],
    imports: [
        ProfilePictureComponent,
        NgClass,
        NgIf,
        UpperCasePipe,
        CurrencyPipe,
        TranslateModule,
    ]
})
export class FavoriteTherapistCardComponent implements OnInit {
  @Input() therapist: User;
  @Input() isFavorite: boolean;
  @Output() addFavorite = new EventEmitter<User>();
  @Output() removeFavorite = new EventEmitter<User>();
  ngOnInit(): void {}

  toggleFavorite(therapist: User) {
    if (this.isFavorite) {
      this.removeFavorite.emit(therapist);
    } else {
      this.addFavorite.emit(therapist);
    }
  }
}
