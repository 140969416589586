import { Component, Input, OnInit } from '@angular/core';
import { Profile } from 'src/app/entities/profile.model';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { InvoiceNewButtonComponent } from '../../../shared/components/office/invoice-new-button/invoice-new-button.component';
import { PayoutButtonComponent } from '../../../shared/components/office/payout-button/payout-button.component';
import { NgClass, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { LocalisedDatePipe } from '../../booking/booking-form-container/localised-date.pipe';
import { OfficeBalanceComponent } from '../../../office/office-balance/office-balance.component';

@Component({
  selector: 'app-invoice-statistics-box',
  templateUrl: './invoice-statistics-box.component.html',
  styleUrls: ['./invoice-statistics-box.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    PayoutButtonComponent,
    InvoiceNewButtonComponent,
    RouterLink,
    NgSwitch,
    NgSwitchCase,
    NgClass,
    TranslateModule,
    LocalisedDatePipe,
    OfficeBalanceComponent,
  ],
})
export class InvoiceStatisticsBoxComponent implements OnInit {
  @Input() profile: Profile;

  STRIPECONNECTONBOARDINGSTATE = StripeConnectOnboardingState;
  stripeConnectOnboardingState: StripeConnectOnboardingState;

  ngOnInit() {
    this.setStripeConnectOnboardingState();
  }

  private setStripeConnectOnboardingState() {
    if (this.profile.stripe_connect_active) {
      this.stripeConnectOnboardingState = StripeConnectOnboardingState.Hidden;
    } else if (!this.profile.stripe_connect_id) {
      this.stripeConnectOnboardingState =
        StripeConnectOnboardingState.RequiresSetup;
    } else {
      if (this.profile.stripe_connect_has_errors) {
        this.stripeConnectOnboardingState = StripeConnectOnboardingState.Errors;
      } else if (this.profile.stripe_connect_has_currently_due_requirements) {
        this.stripeConnectOnboardingState =
          StripeConnectOnboardingState.RequiresMoreInfo;
      } else {
        this.stripeConnectOnboardingState =
          StripeConnectOnboardingState.WaitingForApproval;
      }
    }
  }
}

enum StripeConnectOnboardingState {
  RequiresSetup,
  Errors,
  RequiresMoreInfo,
  WaitingForApproval,
  Hidden,
}
