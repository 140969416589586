import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipe, NgFor, NgIf, UpperCasePipe } from '@angular/common';
import {
  OfficeService,
  BalanceResponse,
} from '../shared/service/office.service';
import { LoadingAnimationComponent } from '../../shared/components/loading-animation/loading-animation.component';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'office-balance',
  templateUrl: './office-balance.component.html',
  styleUrls: ['./office-balance.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    LoadingAnimationComponent,
    NgFor,
    UpperCasePipe,
    CurrencyPipe,
    TranslateModule,
    MatIcon,
  ],
})
export class OfficeBalanceComponent implements OnInit {
  balances: BalanceResponse;
  currencies: string[];
  stripeConnectAccount: any;

  constructor(private officeService: OfficeService) {}

  ngOnInit() {
    this.officeService.stripeConnectAccount.subscribe(
      (stripeConnectAccount) => {
        if (stripeConnectAccount) {
          this.stripeConnectAccount = stripeConnectAccount;
          this.loadBalances();
        }
      }
    );

    // Trigger initial load
    this.officeService.loadStripeConnectAccount();
  }

  private loadBalances() {
    this.officeService.accountBalances.subscribe((balances) => {
      if (balances) {
        this.balances = balances;
        this.currencies = Object.keys(this.balances);
      }
    });
    this.officeService.loadAccountBalance();
  }

  hasUnpaidBalance(currency: string): boolean {
    return (
      this.balances[currency]?.unpaid > 0 ||
      this.balances[currency]?.unpaid_count > 0
    );
  }

  hasPendingBalance(currency: string): boolean {
    return this.balances[currency]?.pending > 0;
  }

  hasAvailableBalance(currency: string): boolean {
    return this.balances[currency]?.available > 0;
  }

  hasAnyUnpaidBalances(): boolean {
    return this.currencies?.some((currency) => this.hasUnpaidBalance(currency));
  }

  hasAnyPendingBalances(): boolean {
    return this.currencies?.some((currency) =>
      this.hasPendingBalance(currency)
    );
  }

  hasAnyAvailableBalances(): boolean {
    return this.currencies?.some((currency) =>
      this.hasAvailableBalance(currency)
    );
  }
}
