import { Component, OnInit } from '@angular/core';
import { Plan } from '../../../entities/plan.model';
import { SettingsService } from '../service/settings.service';
import { BehaviorSubject } from 'rxjs';
import {
  animate,
  group,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Animation } from '../../../shared/animations/fade-animation';
import { MatDialog } from '@angular/material/dialog';
import { PlanStartConfirmDialog } from '../plan-start-confirm-dialog/plan-start-confirm-dialog';
import { PaymentPlan } from '../../../entities/payment-plan.model';
import { PaymentPlanAvailability } from '../../../entities/payment-plan-availability.model';
import { PlanUpgradeConfirmDialog } from '../plan-upgrade-confirm-dialog/plan-upgrade-confirm-dialog';
import { ICAuthService } from '../../../auth/service/i-c-auth.service';
import { User } from '../../../entities/user.model';
import { Router } from '@angular/router';
import { PlanDowngradeConfirmDialog } from '../plan-downgrade-confirm-dialog/plan-downgrade-confirm-dialog';
import { PlanSwitchCycleConfirmDialog } from '../plan-switch-cycle-confirm-dialog/plan-switch-cycle-confirm-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { Planv2Component } from '../planv2/planv2.component';
import { AsyncPipe, NgClass, NgFor, NgIf, PercentPipe } from '@angular/common';

@Component({
  selector: 'app-plan-picker',
  templateUrl: './plan-picker.component.html',
  styleUrls: ['./plan-picker.component.scss'],
  animations: [
    Animation.fadeAnimation,
    trigger('planAnimation', [
      transition('* <=> *', [
        query(':enter', style({ opacity: 0, transform: 'translateY(20px)' }), {
          optional: true,
        }),
        query(
          ':enter',
          stagger('200ms', [
            group([
              animate('400ms ease-in-out', style({ opacity: 1 })),
              animate('500ms ease-out', style({ transform: 'translateY(0)' })),
            ]),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
  imports: [
    NgIf,
    NgClass,
    NgFor,
    Planv2Component,
    AsyncPipe,
    PercentPipe,
    TranslateModule,
  ],
})
export class PlanPickerComponent implements OnInit {
  public plans: Array<Plan>;

  isYearlyBilling$: BehaviorSubject<boolean>;
  private currentUser: User;

  constructor(
    private settingsService: SettingsService,
    private dialog: MatDialog,
    private authService: ICAuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authService.getCurrentUser().subscribe((user) => {
      if (user) {
        this.currentUser = user;
        const isAllowedToSubscribe = this.currentUser.profile.profile_approved;

        if (isAllowedToSubscribe) {
          this.settingsService.getPlanPickerPlans().subscribe((plans) => {
            this.plans = plans;
            this.isYearlyBilling$ = new BehaviorSubject<boolean>(true);
          });
        } else {
          this.router.navigate(['/home/settings/subscription']);
        }
      }
    });
  }

  setBillingPeriodToYearly(setYearlyBilling: boolean) {
    this.isYearlyBilling$.next(setYearlyBilling);
  }

  onPlanSelected(paymentPlan: PaymentPlan, plan: Plan) {
    switch (paymentPlan.availability) {
      case PaymentPlanAvailability.AS_NEW_PLAN:
        this.dialog.open(PlanStartConfirmDialog, {
          data: {
            paymentPlanId: paymentPlan.id,
            planName: plan.name,
          },
        });
        break;
      case PaymentPlanAvailability.UPGRADE:
        this.dialog
          .open(PlanUpgradeConfirmDialog, {
            data: {
              paymentPlanId: paymentPlan.id,
              planName: plan.name,
              profileCurrency: this.currentUser.profile.currency,
              isYearlyBilling: this.isYearlyBilling$.value,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.router.navigate(['/home/settings/subscription']);
            }
          });
        break;
      case PaymentPlanAvailability.DOWNGRADE:
        this.dialog
          .open(PlanDowngradeConfirmDialog, {
            data: {
              paymentPlanId: paymentPlan.id,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.router.navigate(['/home/settings/subscription']);
            }
          });
        break;
      case PaymentPlanAvailability.BILLING_CYCLE_CHANGE:
        this.dialog
          .open(PlanSwitchCycleConfirmDialog, {
            data: {
              paymentPlanId: paymentPlan.id,
              isAnnualSwitch: paymentPlan.month === 12,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.router.navigate(['/home/settings/subscription']);
            }
          });
        break;
      default:
        console.error(
          'Availability: ' + paymentPlan.availability + ' not implemented'
        );
        break;
    }
  }
}
