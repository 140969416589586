// debug.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

export type LogSeverity = 'error' | 'warn';

export interface DebugLog {
  type: LogSeverity;
  message: string;
  timestamp: Date;
  details?: any; // For raw error object or additional context
}

@Injectable({
  providedIn: 'root',
})
export class DebugService {
  private logs = new BehaviorSubject<DebugLog[]>([]);
  logs$ = this.logs.asObservable();

  isDebugMode = false;
  hadDebugParam = false;

  constructor(private router: Router) {
    this.hadDebugParam = window.location.search.includes('debug=true');
    this.isDebugMode = this.hadDebugParam;

    if (this.isDebugMode) {
      this.overrideConsole();
      this.captureErrors();
    }

    // Preserve debug parameter during navigation if it was initially present
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.hadDebugParam) {
          const url = new URL(window.location.href);
          url.searchParams.set('debug', 'true');
          window.history.replaceState({}, '', url.toString());
        }
      });
  }

  private formatError(error: any): string {
    if (!error) {
      return 'Unknown error';
    }

    // If it's an error object
    if (error instanceof Error) {
      return `${error.name}: ${error.message}${
        error.stack ? `\nStack: ${error.stack}` : ''
      }`;
    }

    // If it's an HTTP error response
    if (error.status && error.statusText) {
      return `HTTP Error ${error.status}: ${error.statusText}
Response: ${JSON.stringify(error.error || error, null, 2)}`;
    }

    // If it's an object, try to extract meaningful information
    if (typeof error === 'object') {
      const errorProps = Object.getOwnPropertyNames(error);
      if (errorProps.length === 0) {
        return 'Empty error object';
      }

      return errorProps
        .map((prop) => `${prop}: ${JSON.stringify(error[prop], null, 2)}`)
        .join('\n');
    }

    // Fallback for primitive values
    return String(error);
  }

  private overrideConsole() {
    const originalConsole = { ...console };
    const methods: LogSeverity[] = ['error', 'warn'];

    methods.forEach((method) => {
      console[method] = (...args: any[]) => {
        // Call original console method
        originalConsole[method].apply(console, args);

        const rawError = args[0]; // Store the raw error
        const formattedMessage = args
          .map((arg) => this.formatError(arg))
          .join('\n');

        this.addLog({
          type: method,
          message: formattedMessage,
          timestamp: new Date(),
          details: rawError, // Store raw error for debugging
        });
      };
    });
  }

  private captureErrors() {
    window.onerror = (msg, url, lineNo, columnNo, error) => {
      const errorDetails = {
        message: msg,
        url,
        lineNo,
        columnNo,
        error,
      };

      this.addLog({
        type: 'error',
        message: this.formatError(error) || String(msg),
        timestamp: new Date(),
        details: errorDetails,
      });
      return false;
    };

    window.addEventListener('unhandledrejection', (event) => {
      this.addLog({
        type: 'error',
        message: this.formatError(event.reason),
        timestamp: new Date(),
        details: event.reason,
      });
    });
  }

  private addLog(log: DebugLog) {
    const currentLogs = this.logs.value;
    this.logs.next([...currentLogs, log]);

    // If it's an error, also log the raw details to console for debugging
    if (log.type === 'error' && log.details) {
      console.log('Raw error details:', log.details);
    }
  }

  clearLogs() {
    this.logs.next([]);
  }
}
