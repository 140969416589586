<section class="dashboard-teaser">
  <header class="dashboard-teaser-header">
    <h3 class="dashboard-teaser-title">{{ "messaging.title" | translate }}</h3>
  </header>
  <div class="card">
    <ng-container *ngFor="let c of (channels   | slice : 0 : 3)">
      <app-stream-conversation-preview
        [channel]="c"
        [truncateSize]="truncateSize"
        [currentUserIsTherapist]="currentUser?.type === 'Therapist'"
        (conversationClick)="goToConversation(c)"></app-stream-conversation-preview>
    </ng-container>
    <footer *ngIf="showViewAll && channels.length > 0" class="card-footer">
      <a routerLink="/home/messaging" class="card-footer-item">
          <span class="icon icon-messages"></span
          >{{ "messaging.view_all" | translate }}
      </a>
    </footer>
  </div>
</section>
