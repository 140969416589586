import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/entities/user.model';
import { ProfileService } from '../../profile/service/profile.service';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { Profile } from 'src/app/entities/profile.model';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import {
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from '@angular/common';
import dayjs from 'dayjs';

@Component({
  selector: 'app-status-update-box',
  templateUrl: './status-update-box.component.html',
  styleUrls: ['./status-update-box.component.scss'],
  imports: [
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    FormsModule,
    NgFor,
    TranslateModule,
  ],
})
export class StatusUpdateBoxComponent implements OnInit {
  constructor(
    private profileService: ProfileService,
    private alertService: AlertService,
    private router: Router
  ) {}

  _currentUser: User;
  profile: Profile;
  availabilites: Array<string> = [
    'available_for_clients',
    'not_available_for_clients',
    'profile_hidden',
  ];

  editing: boolean = false;
  statusMessage: string;

  notUpdatedRecently: boolean = false;
  hasLimitedProfile: boolean = false;
  canUpdateVisibility: boolean = false;

  @Input()
  set currentUser(currentUser: User) {
    if (currentUser && currentUser.profile) {
      this._currentUser = currentUser;
      this.canUpdateVisibility = currentUser.profile.profile_approved;
      this.hasLimitedProfile = currentUser.profile.has_limited_profile;
    }
  }

  ngOnInit() {
    this.getProfile();
  }

  getProfile() {
    this.profileService.profile.subscribe((profile) => {
      if (profile) {
        this.profile = profile;
        this.statusMessage = this.profile.statusmessage;
        var updated = this.profile.statusmessage_updated_at;
        this.notUpdatedRecently =
          !updated || new Date(updated) < dayjs().subtract(30, 'days').toDate();
      }
    });
    this.profileService.getSelfProfile();
  }

  updateStatus() {
    this._currentUser.profile.statusmessage = this.statusMessage;
    this._currentUser.profile.statusmessage_updated_at = new Date();
    if (
      this.profileService.updateItem(
        this._currentUser.profile,
        this._currentUser.id
      )
    ) {
      this.editing = this.notUpdatedRecently = false;
      this.alertService.success('home.status.updated');
    }
  }

  seePlans() {
    this.router.navigate(['/home/settings/subscription']);
  }

  shouldShowUpdateReminder() {
    return this.notUpdatedRecently && !this.hasLimitedProfile;
  }
}
