import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { User } from 'src/app/entities/user.model';
import { SharedService } from '../../services/shared.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgFor } from '@angular/common';
import dayjs from 'dayjs';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  imports: [NgClass, NgFor, TranslateModule],
})
export class DateRangePickerComponent implements OnInit {
  constructor(private sharedService: SharedService) {}

  public isDropdownOpen: boolean = false;
  public activeRange: string;

  public ranges: Array<string> = [
    'last_7_days',
    'last_14_days',
    'this_month',
    'last_month',
    'this_year',
    'last_year',
    'all_time',
  ];

  public to: Date;
  public from: Date;

  public currentUser: User;

  @Output() onDateRangeSelected = new EventEmitter<Array<string>>();

  ngOnInit() {
    this.setActiveRange(this.ranges[0]);
    this.sharedService.currentUser.subscribe((user) => {
      if (user) {
        this.currentUser = user;
      }
    });
  }

  setActiveRange(range: string) {
    this.activeRange = range;

    let fromDate: dayjs.Dayjs;
    let toDate: dayjs.Dayjs;

    switch (this.activeRange) {
      case 'last_7_days':
        fromDate = dayjs.tz().subtract(7, 'days');
        toDate = dayjs.tz();
        break;
      case 'last_14_days':
        fromDate = dayjs.tz().subtract(14, 'days');
        toDate = dayjs.tz();
        break;
      case 'this_month':
        fromDate = dayjs.tz().startOf('month');
        toDate = dayjs.tz();
        break;
      case 'last_month':
        fromDate = dayjs.tz().subtract(1, 'month').startOf('month');
        toDate = dayjs.tz().subtract(1, 'month').endOf('month');
        break;
      case 'this_year':
        fromDate = dayjs.tz().startOf('year');
        toDate = dayjs.tz();
        break;
      case 'last_year':
        fromDate = dayjs.tz().subtract(1, 'year').startOf('year');
        toDate = dayjs.tz().subtract(1, 'year').endOf('year');
        break;
      case 'all_time':
        fromDate = dayjs.tz(this.currentUser.created_at);
        toDate = dayjs.tz();
        break;
    }

    this.from = fromDate.toDate();
    this.to = toDate.toDate();

    this.onDateRangeSelected.emit([
      fromDate.format('YYYY-MM-DD'),
      toDate.format('YYYY-MM-DD'),
    ]);
    this.isDropdownOpen = false;
  }
}
