<section class="dashboard-teaser">
  <header class="dashboard-teaser-header">
    <div class="columns is-mobile align-center">
      <div class="column invoice-title">
        <h3 class="dashboard-teaser-title">
          {{ "home.office.title" | translate }}
        </h3>
        <div
          class="qmarkCircle tooltip is-tooltip-multiline"
          data-tooltip="Overview of your invoices and accounting within the platform"
        ></div>
      </div>
      <div class="column is-narrow" *ngIf="profile.stripe_connect_active">
        <shared-office-payout-button
          *ngIf="profile.stripe_connect_payout_interval === 'manual'"
        ></shared-office-payout-button>
        <shared-invoice-new-button></shared-invoice-new-button>
      </div>
    </div>
  </header>

  <div class="card cursor-pointer"
       [routerLink]="profile.stripe_connect_active && !profile.stripe_connect_has_currently_due_requirements ? ['/office/dashboard'] : ['/office/settings']">
    <div class="card-content">
      <ng-container *ngIf="profile.stripe_connect_active">
        <office-balance></office-balance>

        <ng-container *ngIf="profile.stripe_connect_restriction_deadline">
          <div class="is-divider"></div>
          <p class="has-text-centered">
            {{ "home.office.restriction_upcoming_1" | translate }}
          </p>
          <p class="has-text-centered has-text-danger is-size-3">
            {{ profile.stripe_connect_restriction_deadline | localisedDate : "L" }}
          </p>
          <p class="has-text-centered">
            {{ "home.office.restriction_upcoming_2" | translate }}
          </p>
        </ng-container>
      </ng-container>

      <div class="columns is-vcentered"
           *ngIf="!profile.stripe_connect_active && stripeConnectOnboardingState != null">
        <ng-container [ngSwitch]="stripeConnectOnboardingState">
          <div class="column" *ngSwitchCase="STRIPECONNECTONBOARDINGSTATE.RequiresSetup">
            <p>{{ "home.office.please-activate-invoicing" | translate }}</p>
          </div>
          <div class="column" *ngSwitchCase="STRIPECONNECTONBOARDINGSTATE.WaitingForApproval">
            <p>{{ "home.office.waiting_for_approval" | translate }}</p>
          </div>
          <div class="column" *ngSwitchCase="STRIPECONNECTONBOARDINGSTATE.RequiresMoreInfo">
            <p>{{ "home.office.requires_more_info" | translate }}</p>
          </div>
          <div class="column" *ngSwitchCase="STRIPECONNECTONBOARDINGSTATE.Errors">
            <p>{{ "home.office.error_requires_action" | translate }}</p>
          </div>
        </ng-container>

        <div class="column has-text-right is-narrow">
          <ng-container *ngIf="stripeConnectOnboardingState === STRIPECONNECTONBOARDINGSTATE.WaitingForApproval">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
              <g><rect fill="none" height="24" width="24" /></g>
              <g>
                <path fill="#74D0BF" d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5z"/>
              </g>
            </svg>
          </ng-container>

          <button *ngIf="stripeConnectOnboardingState !== STRIPECONNECTONBOARDINGSTATE.WaitingForApproval"
                  [ngClass]="{
                        button: true,
                        'is-rounded': true,
                        'is-primary': stripeConnectOnboardingState === STRIPECONNECTONBOARDINGSTATE.RequiresMoreInfo,
                        'is-danger': stripeConnectOnboardingState === STRIPECONNECTONBOARDINGSTATE.Errors
                      }"
                  [routerLink]="['/office/settings']">
                <span class="icon">
                  <!-- Your SVG icon here -->
                </span>
            <span>
                  {{ stripeConnectOnboardingState === STRIPECONNECTONBOARDINGSTATE.RequiresSetup
              ? ("home.office.activate-invoicing" | translate)
              : ("home.office.get-verified" | translate) }}
                </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
