import { Component, OnInit } from '@angular/core';
import { OfficeService } from 'src/app/office/shared/service/office.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'shared-office-payout-button',
    templateUrl: './payout-button.component.html',
    styleUrls: ['./payout-button.component.scss'],
    imports: [TranslateModule]
})
export class PayoutButtonComponent implements OnInit {
  constructor(private officeService: OfficeService) {}

  ngOnInit() {}

  requestPayout() {
    this.officeService.requestPayout();
  }
}
