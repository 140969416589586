import { Injectable } from '@angular/core';
import { Observable, from, forkJoin } from 'rxjs';
import { map, mergeMap, finalize, catchError } from 'rxjs/operators';
import { Invoice, InvoiceExtended } from '../../../entities/invoice.model';
import { User } from '../../../entities/user.model';
import { OfficeService } from './office.service';
import { DownloadInvoiceService } from './download_invoice.service';
import { MonitoringService } from '../../../shared/services/monitoring/monitoring.service';
import { FontService } from '../../../shared/services/font/FontService';
import type { jsPDF } from 'jspdf';
import type { UserOptions } from 'jspdf-autotable';
import type JSZip from 'jszip';
import type { saveAs as FileSaverType } from 'file-saver';

// Define types for our libraries
type AutoTableFunction = (doc: jsPDF, options: UserOptions) => void;

@Injectable({
  providedIn: 'root',
})
export class BulkDownloadService {
  private readonly fontName = 'arial';

  constructor(
    private downloadInvoiceService: DownloadInvoiceService,
    private officeService: OfficeService,
    private fontService: FontService
  ) {}

  private async loadLibraries(): Promise<{
    ZipConstructor: new () => JSZip;
    PDFDocument: typeof import('jspdf')['default'];
    saveAs: typeof FileSaverType;
    tablePlugin: AutoTableFunction;
  }> {
    try {
      const jsZipModule = await import('jszip');
      const jsPdfModule = await import('jspdf');
      const autoTableModule = await import('jspdf-autotable');
      const fileSaverModule = await import('file-saver');

      const tablePlugin = (doc: jsPDF, options: UserOptions) => {
        if (typeof (doc as any).autoTable !== 'function') {
          autoTableModule.default(doc, {});
        }
        return (doc as any).autoTable(options);
      };

      return {
        ZipConstructor: jsZipModule.default,
        PDFDocument: jsPdfModule.default,
        saveAs: fileSaverModule.default || fileSaverModule.saveAs,
        tablePlugin,
      };
    } catch (error) {
      console.error('Error loading libraries:', error);
      MonitoringService.captureException(error);
      throw new Error('Failed to load required libraries');
    }
  }

  downloadInvoicesAsZip(
    invoices: Invoice[],
    currentUser: User
  ): Observable<number> {
    return from(this.loadLibraries()).pipe(
      mergeMap(({ ZipConstructor, PDFDocument, saveAs, tablePlugin }) => {
        const zip = new ZipConstructor();
        let completed = 0;

        // Load fonts once for all PDFs
        return forkJoin({
          regular: this.fontService.loadFont('assets/fonts/arial.ttf').pipe(
            catchError((error) => {
              console.error('Failed to load regular font:', error);
              MonitoringService.captureException(error);
              return from(['']);
            })
          ),
          bold: this.fontService.loadFont('assets/fonts/arial-bold.ttf').pipe(
            catchError((error) => {
              console.error('Failed to load bold font:', error);
              MonitoringService.captureException(error);
              return from(['']);
            })
          ),
        }).pipe(
          mergeMap(({ regular, bold }) => {
            return new Observable<number>((observer) => {
              from(invoices)
                .pipe(
                  mergeMap((invoice) =>
                    this.officeService.getInvoice(invoice.id).pipe(
                      mergeMap((invoiceWithStripeExtras) => {
                        return new Promise<{
                          invoice: InvoiceExtended;
                          pdfData: Uint8Array;
                        }>((resolve, reject) => {
                          try {
                            const pdf = new PDFDocument({ format: 'letter' });

                            // Add fonts if they were successfully loaded
                            if (regular) {
                              pdf.addFileToVFS('arial.ttf', regular);
                              pdf.addFont('arial.ttf', this.fontName, 'normal');
                            }
                            if (bold) {
                              pdf.addFileToVFS('arial-bold.ttf', bold);
                              pdf.addFont(
                                'arial-bold.ttf',
                                this.fontName,
                                'bold'
                              );
                            }

                            // Initialize autoTable if needed
                            if (typeof (pdf as any).autoTable !== 'function') {
                              tablePlugin(pdf, { startY: 0 });
                            }

                            this.downloadInvoiceService.buildInvoice(
                              pdf,
                              invoiceWithStripeExtras,
                              currentUser,
                              tablePlugin
                            );

                            const pdfData = pdf.output('arraybuffer');
                            resolve({
                              invoice: invoiceWithStripeExtras,
                              pdfData: new Uint8Array(pdfData),
                            });
                          } catch (error) {
                            MonitoringService.captureException(error);
                            reject(error);
                          }
                        });
                      })
                    )
                  ),
                  map(({ invoice, pdfData }) => {
                    try {
                      const fileDate = new Date(invoice.created_at);
                      const fileDateString = fileDate
                        .toISOString()
                        .split('T')[0];
                      const fileName = `${fileDateString}_${invoice.stripe.number}.pdf`;
                      zip.file(fileName, pdfData);

                      completed++;
                      observer.next(completed);
                    } catch (error) {
                      MonitoringService.captureException(error);
                      throw error;
                    }
                  }),
                  finalize(async () => {
                    try {
                      const content = await zip.generateAsync({ type: 'blob' });
                      const date = new Date().toISOString().split('T')[0];
                      saveAs(content, `invoices_${date}.zip`);
                      observer.complete();
                    } catch (error) {
                      MonitoringService.captureException(error);
                      observer.error(error);
                    }
                  })
                )
                .subscribe({
                  error: (error) => {
                    MonitoringService.captureException(error);
                    observer.error(error);
                  },
                });
            });
          })
        );
      })
    );
  }
}
