// src/app/shared/helpers/date.helpers.ts
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export class DateHelpers {
  /**
   * Converts a local date to UTC start of day (00:00:00.000)
   * @param date The local date to convert
   * @returns ISO string for UTC start of day, or undefined if no date provided
   */
  static formatStartOfDayUTC(date: Date | null): string | undefined {
    if (!date) return undefined;

    const localDateString = dayjs(date).format('YYYY-MM-DD');
    return `${localDateString}T00:00:00.000Z`;
  }

  /**
   * Converts a local date to UTC end of day (23:59:59.999)
   * @param date The local date to convert
   * @returns ISO string for UTC end of day, or undefined if no date provided
   */
  static formatEndOfDayUTC(date: Date | null): string | undefined {
    if (!date) return undefined;

    const localDateString = dayjs(date).format('YYYY-MM-DD');
    return `${localDateString}T23:59:59.999Z`;
  }
}
