import {
  MatDialogContent,
  MatDialogTitle,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { Component, Input, Inject } from '@angular/core';
import { NgIf } from '@angular/common';

export interface ProgressDialogData {
  total: number;
}

@Component({
  selector: 'app-invoice-generating-dialog',
  template: `
    <h1 mat-dialog-title>
      {{
        total > 1
          ? ('office.invoice.generating_multiple_invoices'
            | translate : { count: total })
          : ('office.invoice.generating_invoice' | translate)
      }}
    </h1>
    <div mat-dialog-content>
      <mat-spinner [diameter]="40"></mat-spinner>
      <div *ngIf="total > 1" class="progress-text">
        {{ current }}/{{ total }}
      </div>
    </div>
  `,
  styles: [
    `
      mat-spinner {
        display: block;
        margin: 0 auto;
      }
      .progress-text {
        text-align: center;
        margin-top: 16px;
        font-size: 18px;
      }
    `,
  ],
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatProgressSpinner,
    TranslateModule,
    NgIf,
  ],
})
export class InvoiceGeneratingDialogComponent {
  current = 0;
  total = 1;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ProgressDialogData) {
    this.total = data?.total || 1;
  }
}
