import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { OfficeService } from '../shared/service/office.service';
import { User } from 'src/app/entities/user.model';
import { Router, RouterLink } from '@angular/router';
import { Invoice, InvoiceExtended } from 'src/app/entities/invoice.model';
import { DatePipe, formatDate, NgFor, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingAnimationComponent } from '../../shared/components/loading-animation/loading-animation.component';
import { OfficeInvoiceTableComponent } from '../office-invoice-table/office-invoice-table.component';
import { formatCurrencyForUser } from '../../shared/helpers/currency_helper';

@Component({
  selector: 'app-office-dashboard',
  templateUrl: './office-dashboard.component.html',
  styleUrls: ['./office-dashboard.component.scss'],
  imports: [
    NgIf,
    OfficeInvoiceTableComponent,
    RouterLink,
    NgFor,
    LoadingAnimationComponent,
    DatePipe,
    TranslateModule,
  ],
})
export class OfficeDashboardComponent implements OnInit {
  constructor(
    private sharedService: SharedService,
    private officeService: OfficeService,
    private router: Router
  ) {}

  public currentUser: User;
  public stripeConnectAccount: any;
  public openInvoices: Invoice[];
  public events: Array<any>;

  ngOnInit() {
    this.sharedService.currentUser.subscribe((user) => {
      if (user) {
        this.currentUser = user;
        if (!this.currentUser.profile.stripe_connect_id) {
          this.router.navigate(['/office/settings']);
        }
      }
    });

    this.officeService.allOpenInvoices.subscribe((invoices) => {
      if (invoices) {
        this.openInvoices = invoices.filter(
          (invoice) => invoice.status == 'open'
        );
      }
    });
    this.officeService.getAllOpenInvoices();

    this.officeService.getEvents().subscribe((events) => {
      if (!events || !events.data) {
        this.events = [];
        return;
      }

      this.events = events.data.map((e) => {
        let response = {
          type: e.type,
          created: e.created * 1000,
          data: {},
        };
        switch (e.type) {
          case 'invoice.finalized':
          case 'invoice.paid':
            response['data'] = {
              customer: e.data.object.customer_name,
              amount: formatCurrencyForUser(
                e.data.object.amount_due / 100,
                this.currentUser,
                e.data.object.currency.toUpperCase()
              ),
            };
            break;
          case 'payout.created':
            response['data'] = {
              amount: formatCurrencyForUser(
                e.data.object.amount / 100,
                this.currentUser,
                e.data.object.currency.toUpperCase()
              ),
              date: formatDate(
                e.data.object.arrival_date * 1000,
                'mediumDate',
                this.currentUser.locale
              ),
            };
            break;
          case 'payout.paid':
            response['data'] = {
              name: e.data.object.statement_descriptor,
              amount: formatCurrencyForUser(
                e.data.object.amount / 100,
                this.currentUser,
                e.data.object.currency.toUpperCase()
              ),
            };
            break;
          case 'balance.available':
            response['data'] = {
              amount: formatCurrencyForUser(
                e.data.object.available[0].amount / 100,
                this.currentUser,
                e.data.object.available[0].currency.toUpperCase()
              ),
            };
            break;
        }
        return response;
      });
    });
  }
}
