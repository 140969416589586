<div class="modal" [ngClass]="{ 'is-active': showModal }">
  <div class="modal-background"></div>
  <div class="modal-content">
    <a class="delete" (click)="close()"></a>
    <h3 class="modal-title">Support</h3>

    <p>We're here to help.</p>

    <ng-container *ngIf="state == 'menu'">
      <div class="columns">
        <div class="column">
          <a
            href="https://complicated.life/support"
            target="_blank"
            class="button is-large is-rounded is-fullwidth is-primary is-outlined"
            >FAQ</a
          >
        </div>
        <div class="column">
          <button
            class="button is-large is-rounded is-fullwidth is-primary is-outlined"
            (click)="state = 'ticket'"
          >
            Contact Support
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="state == 'ticket'">
      <ng-container *ngIf="!isSent">
        <div class="field">
          <label class="label">Please select an option:</label>
          <div class="select">
            <select [(ngModel)]="supportRequest.category">
              <option value="GENERAL_INQUIRY">
                I am a registered client and need support.
              </option>
              <option value="GENERAL_INQUIRY">
                I am a registered therapist and need support.
              </option>
              <option value="GENERAL_INQUIRY">
                I'm a therapist interested in joining or a current applicant.
              </option>
              <option value="EAP">I have a business-related inquiry.</option>
              <option value="EAP">
                I'm interested in It's Complicated for my organization.
              </option>
              <option value="GENERAL_INQUIRY">
                I have a different question.
              </option>
            </select>
          </div>
        </div>

        <div class="field">
          <label class="label">Your request:</label>
          <div class="control">
            <textarea
              class="textarea"
              [(ngModel)]="supportRequest.note"
            ></textarea>
          </div>
        </div>

        <p class="notification is-danger" *ngIf="showError">
          Please fill out all fields.
        </p>

        <div class="modal-actions">
          <button class="button is-rounded" (click)="close()">Cancel</button>
          <button
            class="button is-rounded is-primary"
            (click)="requestSupport()"
            [ngClass]="{ 'is-loading': isSending }"
          >
            Send
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="isSent">
        <p>Thank you, we will get back to you shortly.</p>
        <div class="modal-actions">
          <button class="button is-rounded is-primary" (click)="close()">
            Close
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
