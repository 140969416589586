import { Component, OnInit } from '@angular/core';
import { ReferralService } from '../service/referral.service';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { User } from 'src/app/entities/user.model';
import { AutosizeModule } from 'ngx-autosize';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';

@Component({
    selector: 'referral-modal',
    templateUrl: './referral-modal.component.html',
    styleUrls: ['./referral-modal.component.css'],
    imports: [NgClass, FormsModule, AutosizeModule]
})
export class ReferralModalComponent implements OnInit {
  constructor(
    private referralService: ReferralService,
    private alertService: AlertService,
    private sharedService: SharedService
  ) {}

  currentUser: User;
  showModal: boolean = false;
  email: string;
  message: string;
  isBusy: boolean = false;

  ngOnInit() {
    this.referralService.showEmailModal.subscribe((state) => {
      this.showModal = state;
    });
    this.sharedService.currentUser.subscribe((user) => {
      if (user) {
        this.currentUser = user;
        this.reset();
      }
    });
  }

  hideModal() {
    this.referralService.toogleEmailModal(false);
  }

  sendInvite() {
    if (this.isBusy) {
      return;
    }
    this.isBusy = true;
    this.referralService
      .sendInvite(this.email.trim(), this.message)
      .subscribe((response) => {
        this.alertService.success(this.email + ' has been invited');
        this.referralService.getReferrals();
        this.hideModal();
        this.reset();
      });
  }

  reset() {
    this.email = '';
    this.isBusy = false;
    if (this.currentUser.coupon) {
      this.message =
        "I'm using It's Complicated to manage and grow my practice. Use my couponcode " +
        this.currentUser.coupon.name +
        ' to get one month free';
    }
  }
}
