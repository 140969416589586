import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OfficeService } from '../../shared/service/office.service';
import { DatePipe, NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'invoice-unbilled-item',
    templateUrl: './invoice-unbilled-item.component.html',
    styleUrls: ['./invoice-unbilled-item.component.scss'],
    imports: [NgIf, NgClass, DatePipe]
})
export class InvoiceUnbilledItemComponent implements OnInit {
  constructor(private officeService: OfficeService) {}

  @Input()
  set item(item: any) {
    this._item = item;
    this.isAppointment = this._item.hasOwnProperty('start');
  }

  public _item: any;
  public isAppointment: boolean = false;
  public isIgnoring: boolean = false;
  public isHidden: boolean = false;

  @Output()
  addOnlineSession = new EventEmitter<any>();

  @Output()
  removeOnlineSession = new EventEmitter<any>();

  @Output()
  addAppointment = new EventEmitter<any>();

  @Output()
  removeAppointment = new EventEmitter<any>();

  ngOnInit(): void {}

  toggle() {
    if (this._item.selected) {
      if (this.isAppointment) {
        this.removeAppointment.emit(this._item);
      } else {
        this.removeOnlineSession.emit(this._item);
      }
    } else {
      if (this.isAppointment) {
        this.addAppointment.emit(this._item);
      } else {
        this.addOnlineSession.emit(this._item);
      }
    }
  }

  ignore() {
    let cid = this.isAppointment ? this._item.client.id : this._item.client_id;
    this.officeService
      .ignoreUnbilledItem(cid, this._item.id, this.isAppointment)
      .subscribe((response) => {
        if (response.status) {
          this.isHidden = true;
        }
        this.isIgnoring = false;
      });
  }
}
