<section class="dashboard-teaser">
  <header class="dashboard-teaser-header">
    <div class="columns is-mobile align-center">
      <div class="column is-one-third">
        <h3 class="dashboard-teaser-title">
          {{ "home.calendar-title" | translate }}
        </h3>
      </div>
      <div class="column has-text-right">
        <button
          *ngIf="user && user.type == 'Therapist'"
          routerLink="/home/settings/calendar"
          class="button is-rounded"
        >
          <span class="icon icon-settings-primary"></span>
          <span class="is-hidden-touch">{{
            "home.calendar-settings" | translate
          }}</span>
          <span class="is-hidden-desktop">{{
            "home.calendar-settings-short" | translate
          }}</span>
        </button>
        <button
          *ngIf="user && user.type == 'Therapist'"
          routerLink="/home/settings/widgets"
          class="button is-rounded"
        >
          <span class="icon">
            <svg
              width="17px"
              height="18px"
              viewBox="0 0 17 18"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="-"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="My-dashboard---Initial-view-(Therapists)-NEW"
                  transform="translate(-1053.000000, -1595.000000)"
                >
                  <rect
                    fill="#FFFFFF"
                    x="0"
                    y="0"
                    width="1500"
                    height="3358"
                  ></rect>
                  <g id="Page" fill="#74D0BF">
                    <g
                      id="Calendar"
                      transform="translate(521.000000, 1589.000000)"
                    >
                      <g id="Heading" transform="translate(1.000000, 0.000000)">
                        <g
                          id="Embed-calendar"
                          transform="translate(513.000000, 0.000000)"
                        >
                          <g
                            id="Embed-calendar-icon"
                            transform="translate(18.000000, 6.000000)"
                          >
                            <g transform="translate(0.000000, 0.000000)">
                              <polygon
                                id="Fill-1"
                                points="9.69324545 11.7907364 9.69324545 8.71437273 6.94333636 8.71437273 6.94333636 11.7907364 5.48206364 11.7907364 8.31788182 15.2369182 11.1537 11.7907364"
                              ></polygon>
                              <path
                                d="M1.56845455,16.2616091 L15.0684545,16.2616091 L15.0684545,7.26160909 L1.56845455,7.26160909 L1.56845455,16.2616091 Z M15.4767273,2.48833636 L13.7896364,2.48833636 L13.7896364,1.15960909 C13.7896364,0.521427273 13.2676364,0.000245454545 12.6302727,0.000245454545 L12.5967273,0.000245454545 C11.9593636,0.000245454545 11.4373636,0.521427273 11.4373636,1.15960909 L11.4373636,2.48833636 L5.19872727,2.48833636 L5.19872727,1.15960909 C5.19872727,0.521427273 4.67672727,0.000245454545 4.03936364,0.000245454545 L4.00581818,0.000245454545 C3.36845455,0.000245454545 2.84645455,0.521427273 2.84645455,1.15960909 L2.84645455,2.48833636 L1.15936364,2.48833636 C0.522,2.48833636 0,3.01033636 0,3.64851818 L0,16.6019727 C0,17.2393364 0.522,17.7613364 1.15936364,17.7613364 L15.4767273,17.7613364 C16.1149091,17.7613364 16.6360909,17.2393364 16.6360909,16.6019727 L16.6360909,3.64851818 C16.6360909,3.01033636 16.1149091,2.48833636 15.4767273,2.48833636 L15.4767273,2.48833636 Z"
                                id="Fill-2"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <span class="is-hidden-touch">{{
            "home.embed-your-calendar" | translate
          }}</span>
          <span class="is-hidden-desktop">{{
            "home.embed-your-calendar-short" | translate
          }}</span>
        </button>
        <button
          *ngIf="user && user.type == 'Client'"
          (click)="showSubscribe = true"
          class="button is-rounded"
        >
          <span class="icon">
            <svg
              width="17px"
              height="18px"
              viewBox="0 0 17 18"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="-"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="My-dashboard---Initial-view-(Therapists)-NEW"
                  transform="translate(-1053.000000, -1595.000000)"
                >
                  <rect
                    fill="#FFFFFF"
                    x="0"
                    y="0"
                    width="1500"
                    height="3358"
                  ></rect>
                  <g id="Page" fill="#74D0BF">
                    <g
                      id="Calendar"
                      transform="translate(521.000000, 1589.000000)"
                    >
                      <g id="Heading" transform="translate(1.000000, 0.000000)">
                        <g
                          id="Embed-calendar"
                          transform="translate(513.000000, 0.000000)"
                        >
                          <g
                            id="Embed-calendar-icon"
                            transform="translate(18.000000, 6.000000)"
                          >
                            <g transform="translate(0.000000, 0.000000)">
                              <polygon
                                id="Fill-1"
                                points="9.69324545 11.7907364 9.69324545 8.71437273 6.94333636 8.71437273 6.94333636 11.7907364 5.48206364 11.7907364 8.31788182 15.2369182 11.1537 11.7907364"
                              ></polygon>
                              <path
                                d="M1.56845455,16.2616091 L15.0684545,16.2616091 L15.0684545,7.26160909 L1.56845455,7.26160909 L1.56845455,16.2616091 Z M15.4767273,2.48833636 L13.7896364,2.48833636 L13.7896364,1.15960909 C13.7896364,0.521427273 13.2676364,0.000245454545 12.6302727,0.000245454545 L12.5967273,0.000245454545 C11.9593636,0.000245454545 11.4373636,0.521427273 11.4373636,1.15960909 L11.4373636,2.48833636 L5.19872727,2.48833636 L5.19872727,1.15960909 C5.19872727,0.521427273 4.67672727,0.000245454545 4.03936364,0.000245454545 L4.00581818,0.000245454545 C3.36845455,0.000245454545 2.84645455,0.521427273 2.84645455,1.15960909 L2.84645455,2.48833636 L1.15936364,2.48833636 C0.522,2.48833636 0,3.01033636 0,3.64851818 L0,16.6019727 C0,17.2393364 0.522,17.7613364 1.15936364,17.7613364 L15.4767273,17.7613364 C16.1149091,17.7613364 16.6360909,17.2393364 16.6360909,16.6019727 L16.6360909,3.64851818 C16.6360909,3.01033636 16.1149091,2.48833636 15.4767273,2.48833636 L15.4767273,2.48833636 Z"
                                id="Fill-2"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <span>{{ "home.subscribe-calendar" | translate }}</span>
        </button>
      </div>
    </div>
  </header>

  <div class="card">
    <ng-container>
      <div class="columns is-mobile is-vcentered calendar-header">
        <div class="column is-narrow">
          <button
            class="button is-primary is-navigation"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
          >
            <svg
              width="9px"
              height="13px"
              viewBox="0 0 9 13"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="-"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="My-dashboard---Initial-view-(Therapists)-NEW"
                  transform="translate(-547.000000, -1657.000000)"
                  stroke="#FFFFFF"
                  stroke-width="2"
                >
                  <g id="Page">
                    <g
                      id="Calendar"
                      transform="translate(521.000000, 1589.000000)"
                    >
                      <g id="Month" transform="translate(17.000000, 58.000000)">
                        <g
                          id="Previous"
                          transform="translate(0.000000, 2.000000)"
                        >
                          <polyline
                            id="Stroke-1"
                            points="17.0733766 9 11 14.499434 17.0733766 20"
                          ></polyline>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </button>
        </div>
        <div class="column has-text-centered">
          <h3 class="calendar-month">
            {{ viewDate | calendarDate : view + "ViewTitle" : "en" }}
          </h3>
        </div>
        <div class="column is-narrow">
          <button
            class="button is-primary is-navigation"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
          >
            <svg
              width="9px"
              height="13px"
              viewBox="0 0 9 13"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="-"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="My-dashboard---Initial-view-(Therapists)-NEW"
                  transform="translate(-1182.000000, -1657.000000)"
                  stroke="#FFFFFF"
                  stroke-width="2"
                >
                  <g id="Page">
                    <g
                      id="Calendar"
                      transform="translate(521.000000, 1589.000000)"
                    >
                      <g id="Month" transform="translate(17.000000, 58.000000)">
                        <g
                          id="Next"
                          transform="translate(647.500000, 16.500000) rotate(-180.000000) translate(-647.500000, -16.500000) translate(633.000000, 2.000000)"
                        >
                          <polyline
                            id="Stroke-1"
                            points="17.0733766 9 11 14.499434 17.0733766 20"
                          ></polyline>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </button>
        </div>
      </div>
      <div class="calendar-container">
        <mwl-calendar-month-view
          class="calendar-monthview"
          [weekStartsOn]="1"
          [viewDate]="viewDate"
          [cellTemplate]="customMonth"
          [events]="events || []"
          (dayClicked)="dayClicked($event.day)">
        </mwl-calendar-month-view>

        <div *ngIf="!events" class="loading-overlay">
          <shared-loading-animation></shared-loading-animation>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #customMonth let-day="day" let-locale="locale">
    <div class="cal-cell-top pointer">
      <span class="cal-day-number">{{
        day.date | calendarDate : "monthViewDayNumber" : locale
      }}</span>
      <div
        *ngIf="day.events[0]"
        class="cal-cell-bottom"
        style="margin-top: 10px"
      >
        <p
          style="
            font-size: 9.5px;
            padding: 5px;
            display: flex;
            align-items: center;
          "
          *ngFor="let e of day.events"
        >
          <span [ngClass]="e.meta.status" class="calendar-event-list-badge"></span>
          {{ e.title }}
        </p>
      </div>
    </div>
  </ng-template>
</section>

<div
  class="modal modal-fx-slideBottom"
  [ngClass]="{ 'is-active': showSubscribe }"
>
  <div class="modal-background"></div>
  <div class="modal-content">
    <span class="delete" (click)="showSubscribe = false"></span>
    <div class="columns">
      <div class="column is-2">
        <label class="switch">
          <input
            name="enableCalendar"
            (change)="onEnableICSChange($event.target.checked)"
            [(ngModel)]="user.profile.calendar_feed_enabled"
            type="checkbox"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="column">
        <p>{{ "settings.export-as-ics" | translate }}</p>
      </div>
    </div>

    <p *ngIf="user.profile.calendar_feed_enabled">
      <input
        class="input"
        readonly
        [value]="env.railsBaseUrl + '/calendar/' + user.profile.calendar_feed"
      />
      <span class="help is-danger">{{
        "settings.keep-link-safe" | translate
      }}</span>
    </p>
  </div>
</div>
