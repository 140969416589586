<div
  [@simpleFadeAnimation]="'in'"
  class="container-fluid container-fluid-limited"
  xmlns="http://www.w3.org/1999/html"
>
  <div class="columns is-marginless">
    <div class="column is-3 is-hidden-touch">
      <app-sidenav></app-sidenav>
    </div>
    <div class="column">
      <div class="is-flex-tablet is-flex-direction-row flex-gap-5">
        <div class="column">
          <h3 class="subtitle is-4">{{ "calendar.calendar" | translate }}</h3>
        </div>
        <div class="buttons is-flex is-flex-direction-row flex-gap-5 is-justify-content-flex-end has-padding-10 has-margin-bottom-0">
          <div class="column is-hidden-touch" *ngIf="currentUser">
            <p>
              {{ "calendar.timezone" | translate }}:
              <b>{{ currentUser.profile.timezone }}</b>
            </p>
          </div>
          <div class="buttons has-addons calendar-view-buttons is-flex-direction-row is-flex-wrap-nowrap  has-margin-bottom-0">
            <span
              class="button is-primary is-rounded is-small has-margin-bottom-0"
              (click)="switchToViewAndRemember(CalendarView.Month)"
              [ngClass]="{ selected: view === CalendarView.Month }"
              >{{ "calendar.month" | translate }}</span
            >
            <span
              class="button is-primary is-small has-margin-bottom-0"
              (click)="switchToViewAndRemember(CalendarView.Week)"
              [ngClass]="{ selected: view === CalendarView.Week }"
              >{{ "calendar.week" | translate }}</span
            >
            <span
              class="button is-primary is-rounded is-small has-margin-bottom-0"
              (click)="switchToViewAndRemember(CalendarView.Day)"
              [ngClass]="{ selected: view === CalendarView.Day }"
              >{{ "calendar.day" | translate }}</span
            >
          </div>
          <button
            *ngIf="currentUser && currentUser.type == 'Therapist'"
            routerLink="/home/settings/calendar"
            class="button is-primary is-rounded is-small has-margin-bottom-0"
          >
            <mat-icon class="button-icon">settings</mat-icon>
            <span class="is-hidden-touch has-margin-left-5">{{
              "office.mainmenu.settings" | translate
            }}</span>
          </button>
        </div>
      </div>

      <div class="card has-border">
        <div class="card-content">
          <div class="columns is-mobile is-fullwidth is-vcentered">
            <div class="column">
              <span
                class="button is-primary"
                mwlCalendarPreviousView
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="activeDayIsOpen = false"
                >&laquo;</span
              >
            </div>
            <div class="column is-8 has-text-centered">
              <h1 class="title is-hidden-touch is-4 is-marginless">
                {{ viewDate | calendarDate : view + "ViewTitle" : "en" : 1 }}
              </h1>
              <h1 class="title is-6 is-hidden-desktop is-hidden-tablet">
                {{ viewDate | calendarDate : view + "ViewTitle" : "en" : 1 }}
              </h1>
            </div>
            <div class="column has-text-right">
              <span
                class="button is-primary"
                mwlCalendarNextView
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="activeDayIsOpen = false"
                >&raquo;</span
              >
            </div>
          </div>

          <div [ngSwitch]="view">
            <mwl-calendar-month-view
              class="calendar-monthview"
              *ngSwitchCase="CalendarView.Month"
              [viewDate]="viewDate"
              [cellTemplate]="customMonth"
              [events]="events"
              [refresh]="refresh"
              [activeDayIsOpen]="activeDayIsOpen"
              (dayClicked)="dayClicked($event.day)"
              (eventClicked)="handleEvent($event.event)"
              [weekStartsOn]="1"
              [locale]="locale"
            >
            </mwl-calendar-month-view>

            <mwl-calendar-week-view
              *ngSwitchCase="CalendarView.Week"
              [headerTemplate]="customWeekHeader"
              [viewDate]="viewDate"
              (hourSegmentClicked)="hourClicked($event)"
              [events]="events"
              [refresh]="refresh"
              (eventClicked)="handleEvent($event.event)"
              (dragActive)="(true)"
              (dayHeaderClicked)="dayClicked($event.day)"
              [dayStartHour]="8"
              [weekStartsOn]="1"
              [locale]="locale"
            >
            </mwl-calendar-week-view>

            <mwl-calendar-day-view
              *ngSwitchCase="CalendarView.Day"
              [viewDate]="viewDate"
              [events]="events"
              [refresh]="refresh"
              (hourSegmentClicked)="hourClicked($event)"
              (eventClicked)="handleEvent($event.event)"
              [dayStartHour]="8"
              [locale]="locale"
            >
            </mwl-calendar-day-view>

            <!-- <mwl-calendar-month-view class="calendar-listview" *ngSwitchCase="CalendarView.List" [cellTemplate]="customList" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="switchDay($event.day)" (eventClicked)="handleEvent($event.event)"
                            (eventTimesChanged)="eventTimesChanged($event)">
                        </mwl-calendar-month-view> -->
          </div>

          <div class="columns is-mobile is-fullwidth">
            <div class="column">
              <div class="example-event pending">
                {{ "calendar.pending-session" | translate }}
              </div>
              <div class="example-event accepted">
                {{ "calendar.accepted-session" | translate }}
              </div>
              <div class="example-event external">
                {{ "calendar.external-event" | translate }}
              </div>
            </div>
            <div class="column has-text-right is-hidden-desktop" *ngIf="currentUser">
              <p>
                {{ "calendar.timezone" | translate }}:
                <b>{{ currentUser.profile.timezone }}</b>
              </p>
            </div>
          </div>
        </div>
      </div>

      <ng-template #customMonth let-day="day" let-locale="locale">
        <div class="cal-cell-top pointer">
          <!-- <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{
                  day.badgeTotal
                  }}</span> -->
          <span class="cal-day-number">{{
            day.date | calendarDate : "monthViewDayNumber" : locale
          }}</span>
          <div
            *ngIf="day.events[0]"
            class="cal-cell-bottom"
            style="margin-top: 10px"
          >
            <p
              style="
                font-size: 9.5px;
                padding: 5px;
                display: flex;
                align-items: center;
              "
              *ngFor="let e of day.events"
            >
              <span
                [ngClass]="e.status"
                class="calendar-event-list-badge"
              ></span>
              {{ e.title }}
            </p>
          </div>
        </div>
      </ng-template>

      <ng-template
        #customWeekHeader
        let-days="days"
        let-locale="locale"
        let-dayHeaderClicked="dayHeaderClicked"
        let-eventDropped="eventDropped"
      >
        <div class="cal-day-headers">
          <div
            class="cal-header"
            *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
            [class.cal-past]="day.isPast"
            [class.cal-today]="day.isToday"
            [class.cal-future]="day.isFuture"
            [class.cal-weekend]="day.isWeekend"
            [ngClass]="day.cssClass"
            (mwlClick)="dayHeaderClicked.emit({ day: day })"
            mwlDroppable
            dragOverClass="cal-drag-over"
            (drop)="
              eventDropped.emit({
                event: $event.dropData.event,
                newStart: day.date
              })
            "
          >
            <div class="weekday">
              {{ dayjs(day.date).format("dddd")}}
            </div>
            <div>
              <span class="month">{{ day.date | date : "LLL" }}&nbsp;</span>
              <span>{{ day.date | date : "d" }}</span>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #customList let-day="day" let-locale="locale">
        <div class="cal-cell-top">
          <span class="cal-day-number">{{
            day.date | calendarDate : "monthViewDayNumber" : locale
          }}</span>
          <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{
            day.badgeTotal
          }}</span>
          <div *ngIf="day.events[0]" class="cal-cell-bottom">
            <p
              style="font-size: 10px; padding: 5px"
              *ngFor="let e of day.events"
            >
              {{ e.start | calendarDate : "dayViewHour" : locale }} -
              {{ e.end | calendarDate : "dayViewHour" : locale }}:
              {{ e.title }} {{ e.status }}
            </p>
          </div>
        </div>
      </ng-template>
    </div>

    <!-- Create Event
        Events: Blocker, Appointment
        only for therapists
        -->
    <app-booking-form-container
      *ngIf="currentUser"
      [currentUser]="currentUser"
      (appointmentConfigured)="onAppointmentConfigured($event)"
      #bookingModal
    ></app-booking-form-container>

  </div>
  <app-payment-modal></app-payment-modal>
</div>
