import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Invoice } from '../../entities/invoice.model';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class InvoiceExportService {
  constructor(private translateService: TranslateService) {}

  generateCsvContent(invoices: Invoice[], payoutId?: string): string {
    // Base headers
    const baseHeaders = [
      'invoice_number',
      'date_of_issue',
      'client',
      'status',
      'amount',
      'currency',
      'fee',
      'net_amount',
      'date_of_payment',
    ];

    // Add payout_id if provided
    const headers = payoutId ? [...baseHeaders, 'payout_id'] : baseHeaders;

    // Translate headers
    const headerRow =
      headers
        .map((header) => {
          if (header === 'payout_id') {
            return this.translateService.instant(
              'office.payout.detail.payout_id'
            );
          }
          return this.translateService.instant(
            `office.invoice.export.columns.${header}`
          );
        })
        .join(',') + '\n';

    const rows = invoices.map((invoice) => {
      const applicationFee = invoice.fee_rate
        ? Number((invoice.total * invoice.fee_rate).toFixed(2))
        : Number((invoice.application_fee / 100).toFixed(2));
      const netAmount = invoice.total - applicationFee;

      const baseRow = [
        invoice.stripe_invoice_number,
        dayjs(invoice.created_at).format('L'),
        invoice.client ? invoice.client.name : invoice.client_email,
        invoice.status,
        invoice.total,
        invoice.currency,
        applicationFee,
        netAmount,
        invoice.paid_at ? dayjs(invoice.paid_at).format('L') : '',
      ];

      // Add payout_id if provided
      return payoutId ? [...baseRow, payoutId].join(',') : baseRow.join(',');
    });

    return headerRow + rows.join('\n');
  }

  exportToCsv(invoices: Invoice[], filename: string, payoutId?: string): void {
    const csvContent = this.generateCsvContent(invoices, payoutId);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `${filename}_${dayjs().format('YYYY-MM-DD')}.csv`);
  }
}
