import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { Invoice, InvoiceExtended } from '../../entities/invoice.model';
import {
  OfficeService,
  AllInvoicesParams,
} from '../shared/service/office.service';
import dayjs from 'dayjs';
import { DateHelpers } from '../../shared/helpers/date_helpers';
import { InvoiceExportService } from '../shared/invoice_export_service';

@Component({
  selector: 'app-invoice-export-dialog',
  template: `
    <h2 mat-dialog-title>{{ 'office.invoice.export.title' | translate }}</h2>

    <mat-dialog-content>
      <ng-container *ngIf="!isLoading">
        <div class="filter-summary">
          <!-- Status summary -->
          <div class="filter-item">
            <span class="filter-label">{{ 'common.status' | translate }}:</span>
            <span class="filter-value">
              <ng-container *ngIf="status; else allStatuses">
                {{ 'office.invoice.statuses.' + status | translate }}
              </ng-container>
              <ng-template #allStatuses>
                <span class="no-filter">{{
                  'office.invoice.list.all_statuses' | translate
                }}</span>
              </ng-template>
            </span>
          </div>

          <!-- Finalized date summary -->
          <div class="filter-item">
            <span class="filter-label"
              >{{ 'office.invoice.list.finalized_on' | translate }}:</span
            >
            <span class="filter-value">
              <ng-container
                *ngIf="hasFinalizedDateFilter; else allFinalizedDates"
              >
                {{
                  formatDateRange(
                    dateFilters.finalized.start,
                    dateFilters.finalized.end
                  )
                }}
              </ng-container>
              <ng-template #allFinalizedDates>
                <span class="no-filter">{{
                  'office.invoice.export.filters.any_date' | translate
                }}</span>
              </ng-template>
            </span>
          </div>

          <!-- Paid date summary -->
          <div class="filter-item">
            <span class="filter-label"
              >{{ 'office.invoice.list.paid_on' | translate }}:</span
            >
            <span class="filter-value">
              <ng-container *ngIf="hasPaidDateFilter; else allPaidDates">
                {{
                  formatDateRange(dateFilters.paid.start, dateFilters.paid.end)
                }}
              </ng-container>
              <ng-template #allPaidDates>
                <span class="no-filter">{{
                  'office.invoice.export.filters.any_date' | translate
                }}</span>
              </ng-template>
            </span>
          </div>

          <!-- Search query summary -->
          <div class="filter-item">
            <span class="filter-label">{{ 'common.search' | translate }}:</span>
            <span class="filter-value">
              <ng-container *ngIf="searchQuery; else noSearch">
                "{{ searchQuery }}"
              </ng-container>
              <ng-template #noSearch>
                <span class="no-filter">{{
                  'office.invoice.export.filters.none' | translate
                }}</span>
              </ng-template>
            </span>
          </div>
        </div>

        <div class="results-info" *ngIf="invoices.length > 0">
          <strong>
            {{
              'office.invoice.export.results_ready'
                | translate : { count: invoices.length }
            }}
          </strong>
        </div>
        <div class="results-info" *ngIf="invoices.length === 0">
          {{ 'office.invoice.export.no_results' | translate }}
        </div>
      </ng-container>

      <div class="loading-content" *ngIf="isLoading">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>
        {{ 'common.cancel' | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="isLoading || invoices.length === 0"
        (click)="exportToCsv()"
      >
        {{ 'office.invoice.export.download_csv' | translate }}
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      .filter-summary {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin: 16px 0;
      }

      .filter-item {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .filter-label {
        color: rgba(0, 0, 0, 0.6);
        font-size: 1em;
      }

      .filter-value {
        font-weight: 500;
      }

      .no-filter {
        color: rgba(0, 0, 0, 0.42);
        font-style: italic;
        font-weight: normal;
      }

      .results-info {
        margin-top: 24px;
        color: rgba(0, 0, 0, 0.7);
        font-size: 1.1em;
        text-align: center;
        margin-bottom: 12px;
      }

      .loading-content {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 200px;
      }

      @media (min-width: 768px) {
        .filter-item {
          flex-direction: row;
          gap: 8px;
          align-items: center;
        }

        .filter-label {
          min-width: 120px;
        }
      }
    `,
  ],
  imports: [
    MatDialogTitle,
    MatDialogContent,
    TranslateModule,
    NgIf,
    MatDialogActions,
    MatDialogClose,
    MatButton,
    MatProgressSpinner,
  ],
  standalone: true,
})
export class InvoiceExportDialogComponent implements OnInit {
  isLoading = false;
  invoices: Invoice[] = [];

  constructor(
    private dialogRef: MatDialogRef<InvoiceExportDialogComponent>,
    private officeService: OfficeService,
    private invoiceExportService: InvoiceExportService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      status: string;
      query: string;
      dateFilters: {
        finalized: { start: Date | null; end: Date | null };
        paid: { start: Date | null; end: Date | null };
      };
    }
  ) {}

  get status(): string {
    return this.data.status;
  }

  get dateFilters() {
    return this.data.dateFilters;
  }

  get searchQuery(): string {
    return this.data.query;
  }

  get hasFinalizedDateFilter(): boolean {
    return !!(
      this.dateFilters.finalized.start || this.dateFilters.finalized.end
    );
  }

  get hasPaidDateFilter(): boolean {
    return !!(this.dateFilters.paid.start || this.dateFilters.paid.end);
  }

  formatDateRange(start: Date | null, end: Date | null): string {
    if (!start && !end) {
      return '';
    }

    const formatDate = (date: Date | null) =>
      date ? dayjs(date).format('L') : '';
    return `${formatDate(start)} - ${formatDate(end)}`;
  }

  ngOnInit() {
    this.loadInvoices();
  }

  loadInvoices() {
    this.isLoading = true;

    const params: AllInvoicesParams = {
      status: this.status,
      query: this.searchQuery,
      finalized_start: DateHelpers.formatStartOfDayUTC(
        this.dateFilters.finalized.start
      ),
      finalized_end: DateHelpers.formatEndOfDayUTC(
        this.dateFilters.finalized.end
      ),
      paid_start: DateHelpers.formatStartOfDayUTC(this.dateFilters.paid.start),
      paid_end: DateHelpers.formatEndOfDayUTC(this.dateFilters.paid.end),
    };

    this.officeService.getAllInvoices(params).subscribe({
      next: (invoices) => {
        this.invoices = invoices;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  exportToCsv() {
    this.invoiceExportService.exportToCsv(
      this.invoices,
      `invoices_export_${dayjs().format('YYYY-MM-DD')}.csv`
    );
    this.dialogRef.close();
  }
}
