import { Injectable } from '@angular/core';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';
import { Observable } from 'rxjs';

export enum OnboardingStep {
  fill_out_profile,
  waiting_for_diploma,
  waiting_for_approval,
  approved_but_not_subscribed,
  done,
}

@Injectable({
  providedIn: 'root',
})
export class OnboardingBoxService {
  constructor(private http: GenericHttpService) {}

  public integrationExists(): Observable<any> {
    return this.http.get('/calendars/integrated');
  }
}
