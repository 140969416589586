import { CurrencyPipe, NgForOf, NgIf, UpperCasePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MatDatepickerModule,
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate,
} from '@angular/material/datepicker';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatPaginator } from '@angular/material/paginator';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { TranslateModule } from '@ngx-translate/core';
import { LocalisedDatePipe } from 'src/app/components/booking/booking-form-container/localised-date.pipe';
import {
  OfficeService,
  PayoutTableParams,
} from '../shared/service/office.service';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import dayjs from 'dayjs';
import { DateHelpers } from 'src/app/shared/helpers/date_helpers';
import { MatIconButton } from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
  selector: 'office-payout-list',
  templateUrl: 'payout-list.component.html',
  styleUrls: ['payout-list.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    TranslateModule,
    NgForOf,
    MatFormField,
    MatSelect,
    MatOption,
    MatIcon,
    MatPaginator,
    MatProgressSpinner,
    MatDateRangeInput,
    MatStartDate,
    MatEndDate,
    MatDatepickerToggle,
    MatDateRangePicker,
    CurrencyPipe,
    MatSort,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    LocalisedDatePipe,
    MatTableModule,
    UpperCasePipe,
    MatIconButton,
  ],
})
export class PayoutListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<any>;
  isLoading = false;
  totalItems = 0;
  pageSize = 10;
  currentPage = 1;
  selectedStatus = '';
  datePlaceholder = '';

  dateFilters = {
    arrival: { start: null, end: null },
    created: { start: null, end: null },
  };

  displayedColumns = [
    'created_at',
    'arrival_date',
    'status',
    'destination',
    'amount',
    'view',
  ];

  statusOptions = [
    { value: '', label: 'office.payout.list.all_statuses' },
    { value: 'paid', label: 'office.payout.statuses.paid' },
    { value: 'pending', label: 'office.payout.statuses.pending' },
    { value: 'in_transit', label: 'office.payout.statuses.in_transit' },
    { value: 'canceled', label: 'office.payout.statuses.canceled' },
    { value: 'failed', label: 'office.payout.statuses.failed' },
  ];

  validDateFilter = (d: Date | null): boolean => {
    // Return false if it's not a valid date object
    return d instanceof Date && !isNaN(d.getTime());
  };

  constructor(
    private officeService: OfficeService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loadPayouts();
    this.datePlaceholder = dayjs()
      .localeData()
      .longDateFormat('L')
      .toLowerCase();
  }

  ngAfterViewInit() {
    if (this.sort) {
      this.sort.sortChange.subscribe(() => {
        if (this.paginator) {
          this.paginator.firstPage();
        }
        this.loadPayouts();
      });
    }

    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.loadPayouts();
      });
    }

    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
  }

  onStatusChange() {
    if (this.paginator) {
      this.paginator.firstPage();
    }
    this.loadPayouts();
  }

  onDateFilterChange() {
    if (this.paginator) {
      this.paginator.firstPage();
    }
    this.loadPayouts();
  }

  loadPayouts() {
    console.log('Loading payouts...');
    this.isLoading = true;

    const params: PayoutTableParams = {
      page: this.paginator?.pageIndex + 1 || 1,
      per_page: this.paginator?.pageSize || 10,
      sort: this.sort?.active || 'arrival_date',
      direction: this.sort?.direction || 'desc',
      status: this.selectedStatus,
      ...(this.validDateFilter(this.dateFilters.arrival.start) && {
        arrival_date_start: DateHelpers.formatStartOfDayUTC(
          this.dateFilters.arrival.start
        ),
      }),
      ...(this.validDateFilter(this.dateFilters.arrival.end) && {
        arrival_date_end: DateHelpers.formatEndOfDayUTC(
          this.dateFilters.arrival.end
        ),
      }),
      ...(this.validDateFilter(this.dateFilters.created.start) && {
        created_date_start: DateHelpers.formatStartOfDayUTC(
          this.dateFilters.created.start
        ),
      }),
      ...(this.validDateFilter(this.dateFilters.created.end) && {
        created_date_end: DateHelpers.formatEndOfDayUTC(
          this.dateFilters.created.end
        ),
      }),
    };

    this.officeService.getPaginatedPayouts(params).subscribe({
      next: (response) => {
        this.dataSource = new MatTableDataSource(response.payouts);
        this.totalItems = response.total_count;
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error loading payouts:', error);
        this.isLoading = false;
        this.alertService.error('Error loading payouts');
      },
    });
  }

  isDateFilterActive(type: 'arrival' | 'created'): boolean {
    return !!(this.dateFilters[type].start || this.dateFilters[type].end);
  }

  clearDateFilter(type: 'arrival' | 'created'): void {
    this.dateFilters[type].start = null;
    this.dateFilters[type].end = null;
    this.onDateFilterChange();
  }

  openPayoutDetails(payout: any): void {
    this.officeService.setCurrentPayout(payout); // Set initial data from list
    this.router.navigate(['/office/payouts', payout.id]);
  }
}
