import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { MonitoringService } from '../../shared/services/monitoring/monitoring.service';
import { AuthLog } from '../service/i-c-auth.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
    selector: 'app-link-account',
    templateUrl: './link-accounts.component.html',
    styleUrls: ['./link-accounts.component.css'],
    imports: [NgSwitch, NgSwitchCase, FormsModule, TranslateModule]
})
export class LinkAccountsComponent {
  linkToken: string | undefined;
  redirectUri: string | undefined;
  email: string | undefined;
  linkAccountErrorType: typeof LinkAccountErrorType = LinkAccountErrorType;
  errorType: LinkAccountErrorType | undefined;
  linkOption: string = 'Link';

  constructor(private route: ActivatedRoute, private authService: AuthService) {
    this.linkToken = this.route.snapshot.queryParamMap.get('child_token');
    this.redirectUri = this.route.snapshot.queryParamMap.get('redirect_uri');
    this.email = this.route.snapshot.queryParamMap.get('email');

    switch (this.route.snapshot.queryParamMap.get('error_type')) {
      case 'accountMismatch':
        this.errorType = LinkAccountErrorType.account_mismatch;
        break;
      default:
        this.errorType = undefined;
    }
  }

  onSubmit() {
    if (this.linkOption === 'Link') {
      this.authService
        .logout({
          logoutParams: {
            domain: environment.auth0.domain,
            returnTo:
              environment.appUrl +
              '/login?link_token=' +
              this.linkToken +
              '&email=' +
              this.email +
              '&connection=' +
              environment.auth0.default_connection,
          },
        })
        .subscribe();
    } else {
      this.authService
        .logout({
          logoutParams: {
            returnTo: environment.baseUrl,
            domain: environment.auth0.domain,
          },
        })
        .subscribe(
          () => {
            AuthLog('Logged out');
          },
          (error) => {
            AuthLog('Logged out error: ', error);
            MonitoringService.captureException(error);
          }
        );
    }
  }
}

enum LinkAccountErrorType {
  account_mismatch = 'account_mismatch',
}
