import { InvoiceExtended } from './invoice.model';
import { Appointment } from './appointment.model';

export class InvoiceItem {
  id?: number;
  invoice: InvoiceExtended;
  appointment: Appointment;
  appointment_id: number;
  name: string;
  price: number;
  taxrate: number;
  taxrate_id: string;
  amount: number = 1;
  online_session_id: number;
}
