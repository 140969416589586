<div
  class="ic-modal modal"
  *ngIf="invoice && action"
  [ngClass]="{ 'is-active': action }"
>
  <div class="modal-background"></div>
  <div class="modal-content content">
    <h3 class="modal-title">
      {{ "office.invoice.modal." + action + ".title" | translate }}
    </h3>
    <p>{{ "office.invoice.modal." + action + ".content" | translate }}</p>
    <div class="box content invoice-box">
      <i class="icon icon-invoice"></i>
      <div>
        <strong *ngIf="invoice.client">{{ invoice.client.name }}</strong>
        <strong *ngIf="invoice.client_email">{{ invoice.client_email }}</strong
        ><br />
        {{ invoice.created_at | date : "shortDate" }},
        {{ invoice.total | currency : invoice.currency }}
        <shared-loading-animation
          *ngIf="action == 'link' && !hostedInvoiceLink"
        ></shared-loading-animation>
        <div class="code" *ngIf="action == 'link' && hostedInvoiceLink">
          <textarea
            #linkField
            class="textarea"
            readonly
            autosize
            [(ngModel)]="hostedInvoiceLink"
          ></textarea>
          <span class="clipboard" (click)="copyInvoiceLink()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="clipboard"
              width="2em"
              height="2em"
              viewBox="0 0 24 24"
            >
              <path
                fill=""
                d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
    <div class="buttons">
      <ng-container *ngIf="action != 'link'">
        <button class="button is-rounded" (click)="cancel()">
          {{ "office.invoice.modal." + action + ".cancel" | translate }}
        </button>
        <button
          class="button is-danger is-rounded"
          [ngClass]="{ 'is-loading': isExecuting }"
          [disabled]="isExecuting"
          (click)="confirm()"
        >
          {{ "office.invoice.modal." + action + ".confirm" | translate }}
        </button>
      </ng-container>
      <ng-container *ngIf="action == 'link'">
        <button class="button is-rounded is-primary" (click)="cancel()">
          {{ "office.invoice.modal." + action + ".cancel" | translate }}
        </button>
      </ng-container>
    </div>
  </div>
</div>
