import { Component, OnInit } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ICAuthService } from '../service/i-c-auth.service';
import { ValidationService } from '../service/validation.service';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';

export interface ResetPassword {
  password: string;
  password_confirmation: string;
}

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    imports: [NgIf, FormsModule, NgClass, RouterLink]
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: ICAuthService,
    private router: Router,
    public validationService: ValidationService
  ) {}

  public resetPassword: ResetPassword = {} as ResetPassword;
  public error: any;
  public headers: HttpHeaders;
  public loading: boolean;
  public success: boolean;

  public onKeyDown = (event) =>
    event.key == 'Enter' ? this.updatePassword() : null;

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.headers = new HttpHeaders()
        .set('uid', params.uid)
        .set('access-token', params['access-token'])
        .set('client', params.client);
    });
  }

  public updatePassword() {
    this.loading = true;
    this.authService
      .updatePassword(this.resetPassword, this.headers)
      .subscribe((res) => {
        this.loading = false;
        res.success ? (this.success = true) : (this.error = true);
      });
  }
}
