import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/entities/user.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TranslateModule } from '@ngx-translate/core';
import { AppointmentActionsComponent } from '../../booking/appointment-actions/appointment-actions.component';
import { AppointmentStatusMessageComponent } from '../../booking/appointment-status-message/appointment-status-message.component';
import { CurrencyPipe, DatePipe, NgIf } from '@angular/common';
import { Appointment } from '../../../entities/appointment.model';
import { LocalisedDatePipe } from '../../booking/booking-form-container/localised-date.pipe';

@Component({
  selector: 'app-booking-attachment',
  templateUrl: './booking-attachment.component.html',
  styleUrls: ['./booking-attachment.component.scss'],
  imports: [
    NgIf,
    AppointmentStatusMessageComponent,
    AppointmentActionsComponent,
    DatePipe,
    TranslateModule,
    LocalisedDatePipe,
    CurrencyPipe,
  ],
})
export class BookingAttachmentComponent implements OnInit {
  constructor() {}

  @Input() currentUser: User;
  @Input() appointment: Appointment;
  @Input() clientId: number;
  @Input() profileId: number;
  @Input() canUseVideo: boolean;
  @Input() editAppointment: (appointment: Appointment) => void;

  currentTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  showTimezone: boolean = false;
  ownsAppointment: boolean = false;

  ngOnInit() {
    if (this.appointment) {
      this.showTimezone =
        this.currentTimezone !== this.currentUser.profile.timezone;
      this.ownsAppointment =
        this.currentUser.profile.id.toString() === this.profileId.toString();
    }
  }
}
