<ng-container
  *ngIf="
    currentUser.profile &&
    !currentUser.profile.stripe_connect_id &&
    !stripeConnectAccount &&
    invoicingAvailableForProfileCountry
  "
>
  <section class="card">
    <div class="card-content content">
      <h4 class="title is-4">{{ "invoicing.welcome" | translate }}</h4>
      <p *ngFor="let intro of 'invoicing.introduction' | translate">
        {{ intro }}
      </p>
      <div class="field">
        <label class="switch">
          <input
            name="acceptTOC"
            [(ngModel)]="hasAcceptedStripeTOS"
            type="checkbox"
          />
          <span class="slider round"></span>
        </label>

        <label for="stripeTOS">
          {{ "invoicing.agree" | translate }}
          <a
            target="_blank"
            href="https://stripe.com/de/connect-account/legal"
            >{{ "invoicing.stripe-agreement" | translate }}</a
          ></label
        >
      </div>
      <div class="columns">
        <div class="column has-text-right">
          <button
            class="button is-primary is-rounded"
            [ngClass]="{ 'is-loading': isCreatingAccount }"
            (click)="createAccount()"
            [disabled]="!hasAcceptedStripeTOS"
          >
            {{ "invoicing.enable-invoicing" | translate }}
          </button>
        </div>
      </div>
    </div>
  </section>
</ng-container>

<ng-container
  *ngIf="
    !invoicingAvailableForProfileCountry &&
    !countrySet &&
    currentUser.profile &&
    !currentUser.profile.stripe_connect_id
  "
>
  <section class="card">
    <div class="card-content content">
      <h4 class="title is-4">
        {{ "office.not_available_no_country_set_title" | translate }}
      </h4>
      <p>{{ "office.not_available_no_country_set" | translate }}</p>
      <button class="button is-primary is-rounded" (click)="goToEditProfile()">
        Edit profile
      </button>
    </div>
  </section>
</ng-container>

<ng-container
  *ngIf="
    !invoicingAvailableForProfileCountry &&
    countrySet &&
    currentUser.profile &&
    !currentUser.profile.stripe_connect_id
  "
>
  <section class="card">
    <div class="card-content content">
      <h4 class="title is-4">
        {{ "office.not_available_in_country_title" | translate }}
      </h4>
      <p>{{ "office.not_available_in_country" | translate }}</p>
      <p>
        <a href="https://stripe.com/global" target="_blank">{{
          "office.not_available_in_country_stripe_link_text" | translate
        }}</a>
      </p>
    </div>
  </section>
</ng-container>

<ng-container *ngIf="stripeConnectAccount && stripeCurrentlyDueAccountLinks">
  <ng-container
    *ngIf="!currentUser.profile.stripe_connect_active; else connected"
  >
    <ng-container *ngIf="currentlyDueRequirementsExist; else waiting">
      <ng-container *ngIf="requirementsErrorsExist; else setup">
        <app-office-setup-stripe-launcher
          [title]="
            'office.steps.approval_steps_required_error.title' | translate
          "
          [message]="
            'office.steps.approval_steps_required_error.text' | translate
          "
          [action]="
            'office.steps.approval_steps_required_error.action' | translate
          "
          [stripeUrl]="stripeCurrentlyDueAccountLinks.url"
          [isWarning]="true"
        ></app-office-setup-stripe-launcher>
      </ng-container>
      <ng-template #setup>
        <app-office-setup-stripe-launcher
          [title]="'office.steps.approval_steps_required.title' | translate"
          [message]="'office.steps.approval_steps_required.text' | translate"
          [action]="'office.steps.approval_steps_required.action' | translate"
          [stripeUrl]="stripeCurrentlyDueAccountLinks.url"
        ></app-office-setup-stripe-launcher>
      </ng-template>
    </ng-container>
    <ng-template #waiting>
      <app-office-setup-stripe-launcher
        [title]="'office.steps.approval_waiting.title' | translate"
        [message]="'office.steps.approval_waiting.text' | translate"
        [action]="'office.steps.approval_waiting.action' | translate"
        [stripeUrl]="stripeCurrentlyDueAccountLinks.url"
      ></app-office-setup-stripe-launcher>
    </ng-template>
  </ng-container>
  <ng-template #connected>
    <ng-container *ngIf="currentlyDueRequirementsExist; else nothingDue">
      <ng-container *ngIf="requirementsErrorsExist; else somethingDue">
        <app-office-setup-stripe-launcher
          [title]="
            'office.steps.approval_steps_required_error.title' | translate
          "
          [message]="
            'office.steps.approval_steps_required_error.text' | translate
          "
          [action]="
            'office.steps.approval_steps_required_error.action' | translate
          "
          [stripeUrl]="stripeCurrentlyDueAccountLinks.url"
          [isWarning]="true"
        ></app-office-setup-stripe-launcher>
      </ng-container>
      <ng-template #somethingDue>
        <app-office-setup-stripe-launcher
          [title]="'office.steps.verification_due.title' | translate"
          [message]="
            'office.steps.verification_due.text'
              | translate
                : {
                    date: stripeConnectRestrictionDeadline | localisedDate : 'L'
                  }
          "
          [action]="'office.steps.verification_due.action' | translate"
          [stripeUrl]="stripeCurrentlyDueAccountLinks.url"
          [isWarning]="true"
        ></app-office-setup-stripe-launcher>
      </ng-template>
    </ng-container>
    <ng-template #nothingDue>
      <app-office-setup-stripe-launcher
        *ngIf="
          stripeEventuallyDueAccountLinks && eventuallyDueRequirementsExist
        "
        [title]="'office.steps.verify_extra.title' | translate"
        [message]="'office.steps.verify_extra.text' | translate"
        [action]="'office.steps.verify_extra.action' | translate"
        [stripeUrl]="stripeEventuallyDueAccountLinks.url"
      ></app-office-setup-stripe-launcher>
    </ng-template>
  </ng-template>

  <section class="dashboard-teaser">
    <header>
      <h3 class="dashboard-teaser-title">
        {{ "office.steps.personal_details.title" | translate }}
      </h3>
    </header>

    <div class="card">
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <div class="columns">
              <div class="column is-4">
                <div class="field">
                  <span class="label">{{
                    "office.steps.personal_details.name" | translate
                  }}</span>
                </div>
              </div>
              <div class="column">
                <span>
                  {{ stripeConnectAccount.individual.first_name }}
                  {{ stripeConnectAccount.individual.last_name }}</span
                >
              </div>
            </div>
            <div class="columns">
              <div class="column is-4">
                <div class="field">
                  <span class="label">{{
                    "office.steps.personal_details.address" | translate
                  }}</span>
                </div>
              </div>
              <div class="column">
                <span>
                  {{ stripeConnectAccount.individual.address.line1 }}<br
                /></span>
                <span *ngIf="stripeConnectAccount.individual.address.line2">
                  {{ stripeConnectAccount.individual.address.line2 }}<br
                /></span>
                <span>
                  {{
                    stripeConnectAccount.individual.address.postal_code
                  }}</span
                >
                <span> {{ stripeConnectAccount.individual.address.city }}</span>
                <span>
                  {{ stripeConnectAccount.individual.address.country }}</span
                >
              </div>
            </div>
          </div>
          <div class="column">
            <div class="columns">
              <div class="column is-4">
                <div class="field">
                  <span class="label">{{
                    "office.steps.personal_details.phone" | translate
                  }}</span>
                </div>
              </div>
              <div class="column">
                <span> {{ stripeConnectAccount.individual.phone }}</span>
              </div>
            </div>
            <div class="columns">
              <div class="column is-4">
                <div class="field">
                  <span class="label">{{
                    "office.steps.personal_details.dob" | translate
                  }}</span>
                </div>
              </div>
              <div class="column">
                <span *ngIf="stripeConnectAccount.individual.dob.day">
                  {{ stripeConnectAccount.individual.dob.day }}.{{
                    stripeConnectAccount.individual.dob.month
                  }}.{{ stripeConnectAccount.individual.dob.year }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!!bankAccount" class="columns">
          <div class="column is-2">
            <div class="field">
              <span class="label">{{
                "office.steps.personal_details.payout_details" | translate
              }}</span>
            </div>
          </div>
          <div class="column has-text-left">
            <p *ngIf="bankAccount.bank_name">
              {{ bankAccount.bank_name }}
            </p>
            <p>
              <span *ngIf="bankAccount.currency">
                {{ bankAccount.currency.toUpperCase() }}</span
              >
              <span *ngIf="bankAccount.last4">
                [••••
                {{ bankAccount.last4 }}]</span
              >
            </p>
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-centered">
            <a
              class="is-primary is-rounded button"
              href="{{ stripeCurrentlyDueAccountLinks.url }}"
              >{{ "office.steps.personal_details.action" | translate }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="dashboard-teaser">
    <header>
      <h3 class="dashboard-teaser-title">
        {{ "office.steps.business_information.title" | translate }}
      </h3>
    </header>

    <div class="card">
      <div class="card-content">
        <p>{{ "office.steps.business_information.copy" | translate }}</p>
        <div class="columns is-multiline has-margin-top-15">
          <div class="column">
            <div class="field">
              <label class="label">{{
                "office.steps.business_information.company_name" | translate
              }}</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  [(ngModel)]="stripeConnectAccount.business_profile.name"
                  name="address1"
                  [ngClass]="{
                    'is-danger': !stripeConnectAccount.business_profile.name
                  }"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">{{
                "office.steps.business_information.address1" | translate
              }}</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  [ngModel]="
                    stripeConnectAccount.business_profile.support_address?.line1
                  "
                  (ngModelChange)="updateSupportAddress({ line1: $event })"
                  name="address1"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">{{
                "office.steps.business_information.address2" | translate
              }}</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  [ngModel]="
                    stripeConnectAccount.business_profile.support_address?.line2
                  "
                  (ngModelChange)="updateSupportAddress({ line2: $event })"
                  name="address2"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <div class="columns no-margin-bottom">
              <div class="column">
                <div class="field">
                  <label class="label">{{
                    "office.steps.business_information.zip" | translate
                  }}</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      [ngModel]="
                        stripeConnectAccount.business_profile.support_address
                          ?.postal_code
                      "
                      (ngModelChange)="
                        updateSupportAddress({ postal_code: $event })
                      "
                      name="postal_code"
                    />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">{{
                    "office.steps.business_information.city" | translate
                  }}</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      placeholder="City"
                      [ngModel]="
                        stripeConnectAccount.business_profile.support_address
                          ?.city
                      "
                      (ngModelChange)="updateSupportAddress({ city: $event })"
                      name="city"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <label class="label">{{
                "office.steps.business_information.country" | translate
              }}</label>
              <div class="control select is-fullwidth">
                <select
                  [ngModel]="
                    stripeConnectAccount.business_profile.support_address
                      ?.country
                  "
                  (ngModelChange)="updateSupportAddress({ country: $event })"
                >
                  <option *ngFor="let c of countries" [value]="c.code">
                    {{ c.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-centered">
            <button
              class="button is-primary is-rounded"
              (click)="updateStripeConnectAccount()"
              [ngClass]="{ 'is-loading': isUpdatingBusinessInformation }"
            >
              {{ "office.steps.business_information.action" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="dashboard-teaser"
    *ngIf="showPayoutSchedule && currentUser.profile.stripe_connect_active"
  >
    <header>
      <h3 class="dashboard-teaser-title">
        {{ "office.settings.payout_schedule.title" | translate }}
      </h3>
    </header>
    <div class="card">
      <div class="card-content">
        <div class="field is-flex is-align-items-center">
          <div class="select is-rounded align-center">
            <select
              [(ngModel)]="selectedPayoutScheduleInterval"
              (change)="onPayoutIntervalChanged($event)"
            >
              <option value="manual">
                {{
                  "office.settings.payout_schedule.interval_manual" | translate
                }}
              </option>
              <option value="daily">
                {{
                  "office.settings.payout_schedule.interval_daily" | translate
                }}
              </option>
              <option value="weekly">
                {{
                  "office.settings.payout_schedule.interval_weekly" | translate
                }}
              </option>
              <option value="monthly">
                {{
                  "office.settings.payout_schedule.interval_monthly" | translate
                }}
              </option>
            </select>
          </div>
          <ng-container *ngIf="selectedPayoutScheduleInterval == 'weekly'">
            <span class="has-margin-left-15 has-margin-right-15">{{
              "office.settings.payout_schedule.weekly_anchor_label" | translate
            }}</span>
            <div class="select is-rounded">
              <select
                [(ngModel)]="selectedPayoutScheduleWeeklyAnchor"
                (change)="onPayoutWeeklyAnchorChanged($event)"
              >
                <option value="monday">
                  {{
                    "office.settings.payout_schedule.weekly_anchor_monday"
                      | translate
                  }}
                </option>
                <option value="tuesday">
                  {{
                    "office.settings.payout_schedule.weekly_anchor_tuesday"
                      | translate
                  }}
                </option>
                <option value="wednesday">
                  {{
                    "office.settings.payout_schedule.weekly_anchor_wednesday"
                      | translate
                  }}
                </option>
                <option value="thursday">
                  {{
                    "office.settings.payout_schedule.weekly_anchor_thursday"
                      | translate
                  }}
                </option>
                <option value="friday">
                  {{
                    "office.settings.payout_schedule.weekly_anchor_friday"
                      | translate
                  }}
                </option>
                <option value="saturday">
                  {{
                    "office.settings.payout_schedule.weekly_anchor_saturday"
                      | translate
                  }}
                </option>
                <option value="sunday">
                  {{
                    "office.settings.payout_schedule.weekly_anchor_sunday"
                      | translate
                  }}
                </option>
              </select>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedPayoutScheduleInterval == 'monthly'">
            <span class="has-margin-left-15 has-margin-right-15">{{
              "office.settings.payout_schedule.monthly_anchor_label" | translate
            }}</span>
            <div class="select is-rounded">
              <select
                [(ngModel)]="selectedPayoutScheduleMonthlyAnchor"
                (change)="onPayoutMonthlyAnchorChanged($event)"
              >
                <option
                  *ngFor="let anchor of monthlyPayoutAnchors"
                  [value]="anchor"
                >
                  {{ anchor }}
                </option>
              </select>
            </div>
          </ng-container>
        </div>
        <div *ngIf="selectedPayoutScheduleInterval == 'manual'">
          <p>
            {{
              "office.settings.payout_schedule.interval_manual_desc" | translate
            }}
          </p>
        </div>
        <div *ngIf="selectedPayoutScheduleInterval == 'daily'">
          <p>
            {{
              "office.settings.payout_schedule.interval_daily_desc" | translate
            }}
          </p>
        </div>
        <div *ngIf="selectedPayoutScheduleInterval == 'weekly'">
          <p>
            {{
              "office.settings.payout_schedule.interval_weekly_desc" | translate
            }}
          </p>
        </div>
        <div *ngIf="selectedPayoutScheduleInterval == 'monthly'">
          <p>
            {{
              "office.settings.payout_schedule.interval_monthly_desc"
                | translate
            }}
          </p>
        </div>
        <div class="columns has-margin-top-25">
          <div class="column has-text-centered">
            <button
              class="button is-rounded is-primary has-margin-left-auto"
              [disabled]="!stripeConnectAccount || !hasPayoutScheduleChanges()"
              [ngClass]="{ 'is-loading': isUpdatingPayoutSchedule }"
              (click)="updatePayoutSchedule()"
            >
              {{ "office.settings.payout_schedule.update_confirm" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <office-invoice-settings
    *ngIf="currentUser.profile.stripe_connect_active"
  ></office-invoice-settings>

  <section class="dashboard-teaser">
    <header>
      <h3 class="dashboard-teaser-title">
        {{ "office.settings.delete_account.title" | translate }}
      </h3>
    </header>
    <div class="card">
      <div class="card-content">
        <button
          class="button is-rounded is-danger is-outlined"
          (click)="isDeletingAccount = true"
        >
          {{ "office.settings.delete_account.cta" | translate }}
        </button>
      </div>
    </div>
  </section>

  <div class="ic-modal modal" [ngClass]="{ 'is-active': isDeletingAccount }">
    <div class="modal-background"></div>
    <div class="modal-content content">
      <h3 class="modal-title">
        {{ "office.settings.delete_account.modal.title" | translate }}
      </h3>
      <p>{{ "office.settings.delete_account.modal.copy" | translate }}</p>
      <div class="buttons">
        <button class="button is-rounded" (click)="isDeletingAccount = false">
          {{ "office.settings.delete_account.modal.cancel" | translate }}
        </button>
        <button
          class="button is-danger is-rounded"
          [ngClass]="{ 'is-loading': isDeletingAccountConfirmed }"
          [disabled]="isDeletingAccountConfirmed"
          (click)="deleteOfficeAccount()"
        >
          {{ "office.settings.delete_account.modal.confirm" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
