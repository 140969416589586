<ng-container *ngIf="invoice && invoiceClient">
  <div class="content">
    <h3>
      {{
        "office.invoice.detail.title"
          | translate : { client: invoice.client?.name || invoice.client_email }
      }}
    </h3>

    <div class="card">
      <div class="card-content">
        <ng-container *ngIf="invoice.status == 'draft'">
          <div class="steps">
            <div class="step-item is-completed">
              <div class="step-marker">
                <span class="icon"
                  ><i class="material-icons lightgray">done</i></span
                >
              </div>
              <div class="step-details">
                {{ "invoicing.modal.steps.client" | translate }}
              </div>
            </div>
            <div class="step-item is-completed">
              <div class="step-marker">
                <span class="icon"
                  ><i class="material-icons lightgray">done</i></span
                >
              </div>
              <div class="step-details">
                {{ "invoicing.modal.steps.appointments" | translate }}
              </div>
            </div>
            <div class="step-item is-completed">
              <div class="step-marker">
                <span class="icon"
                  ><i class="material-icons lightgray">done</i></span
                >
              </div>
              <div class="step-details">
                {{ "invoicing.modal.steps.extras" | translate }}
              </div>
            </div>
            <div class="step-item is-active">
              <div class="step-marker">
                <span> 4 </span>
              </div>
              <div class="step-details">
                {{ "invoicing.modal.steps.review" | translate }}
              </div>
            </div>
          </div>
        </ng-container>

        <div class="columns">
          <div class="column">
            <p
              class="address has-margin-bottom-15"
              *ngIf="invoiceClient && invoice.status != 'draft'"
            >
              <span>{{ invoiceClient.name }}</span>
              <span *ngFor="let addressLine of clientAddressLines()">{{
                addressLine
              }}</span>
            </p>
          </div>
          <div class="column has-text-right">
            <p>
              <strong>{{ "office.invoice.status" | translate }}</strong>
              <span class="tag is-rounded has-margin-left-5">
                {{ "office.invoice.statuses." + invoice.status | translate }}
              </span>
            </p>
            <p>
              <strong>{{ "office.invoice.created_at" | translate }}</strong>
              {{ invoice.created_at | date : "mediumDate" }}
            </p>
            <p *ngIf="invoice.stripe?.status_transitions.finalized_at">
              <strong>{{ "office.invoice.sent_to_client" | translate }}</strong>
              {{
                invoice.stripe.status_transitions.finalized_at * 1000
                  | date : "mediumDate"
              }}
            </p>
            <p *ngIf="invoice.stripe?.status_transitions.paid_at">
              <strong>{{ "office.invoice.paid_at" | translate }}</strong>
              {{
                invoice.stripe.status_transitions.paid_at * 1000
                  | date : "mediumDate"
              }}
            </p>
          </div>
        </div>
        <div
          class="address columns is-multiline"
          *ngIf="invoiceClient && invoice.status == 'draft'"
        >
          <div class="column is-12">
            <div class="field">
              <label for="" class="label">Name</label>
              <div class="control">
                <input
                  type="text"
                  class="input"
                  name="name"
                  autocomplete="off"
                  [(ngModel)]="invoiceClient.name"
                />
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="columns is-multiline">
              <div class="column is-12">
                <div class="field">
                  <label class="label">Address Line 1</label>
                  <div class="control">
                    <input
                      type="text"
                      class="input"
                      name="street"
                      autocomplete="off"
                      [(ngModel)]="invoiceClient.address.line1"
                    />
                  </div>
                </div>
              </div>
              <div class="column is-12">
                <div class="field">
                  <label class="label">Address Line 2</label>
                  <div class="control">
                    <input
                      type="text"
                      class="input"
                      autocomplete="off"
                      [(ngModel)]="invoiceClient.address.line2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="columns is-multiline">
              <div class="column is-4">
                <div class="field">
                  <label class="label">Postal Code</label>
                  <div class="control">
                    <input
                      type="text"
                      class="input"
                      autocomplete="off"
                      name="postal_code"
                      [(ngModel)]="invoiceClient.address.postal_code"
                    />
                  </div>
                </div>
              </div>
              <div class="column is-8">
                <div class="field">
                  <label class="label">City</label>
                  <div class="control">
                    <input
                      type="text"
                      class="input"
                      autocomplete="off"
                      name="city"
                      [(ngModel)]="invoiceClient.address.city"
                    />
                  </div>
                </div>
              </div>
              <div class="column is-12">
                <div class="field">
                  <label class="label">Country</label>
                  <div class="control select is-fullwidth">
                    <select [(ngModel)]="invoiceClient.address.country">
                      <option *ngFor="let c of countries" [value]="c.code">
                        {{ c.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngFor="let item of invoice.invoice_items; let i = index"
          class="invoice-item"
        >
          <div class="columns">
            <div class="column is-5">
              <div class="field">
                <label>{{ "office.invoice.labels.name" | translate }}</label>
                <ng-container *ngIf="invoice.status == 'draft'">
                  <div class="control">
                    <textarea
                      type="text"
                      class="input"
                      [(ngModel)]="item.name"
                      autosize
                      [minRows]="1"
                    ></textarea>
                  </div>
                </ng-container>
                <ng-container *ngIf="invoice.status != 'draft'">
                  <p>{{ item.name }}</p>
                </ng-container>
              </div>
            </div>
            <div class="column is-1">
              <div class="field">
                <label>{{ "office.invoice.labels.amount" | translate }}</label>
                <ng-container *ngIf="invoice.status == 'draft'">
                  <div class="control">
                    <input
                      type="number"
                      min="1"
                      class="input"
                      [(ngModel)]="item.amount"
                    />
                  </div>
                </ng-container>
                <ng-container *ngIf="invoice.status != 'draft'">
                  <p>{{ item.amount }}</p>
                </ng-container>
              </div>
            </div>
            <div class="column is-2">
              <div class="field">
                <label>{{ "office.invoice.labels.price" | translate }}</label>
                <ng-container *ngIf="invoice.status == 'draft'">
                  <div class="control has-icons-right">
                    <input type="text" class="input" [(ngModel)]="item.price" />
                  </div>
                </ng-container>
                <ng-container *ngIf="invoice.status != 'draft'">
                  <p>
                    {{
                      item.price
                        | currency : (invoice.currency | uppercase)
                    }}
                  </p>
                </ng-container>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label for="">{{
                  "office.invoice.labels.taxrate" | translate
                }}</label>
                <div class="control">
                  <ng-container *ngIf="invoice.status == 'draft'">
                    <div class="select is-fullwidth tax-select">
                      <select
                        [(ngModel)]="item.taxrate_id"
                        (ngModelChange)="onTaxrateChange(item)"
                      >
                        <ng-container *ngFor="let taxrate of taxrates">
                          <option *ngIf="taxrate.active" [value]="taxrate.id">
                            {{ taxrate.percentage }}% {{ taxrate.display_name }}
                          </option>
                        </ng-container>
                      </select>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="invoice.status != 'draft'">
                    <p>{{ item.taxrate }} %</p>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="column is-1 has-text-right">
              <div class="field">
                <label for="">{{
                  "office.invoice.labels.total" | translate
                }}</label>
                <div class="control has-text-right">
                  <p>
                    <strong>
                      {{
                        item.amount * item.price
                          | currency
                            : (invoice.currency | uppercase)
                      }}<br />
                    </strong>
                    <small class="tax" *ngIf="item.taxrate > 0">
                      {{
                        calculateTotalVAT(item.price, item.taxrate)
                          | currency
                            : (invoice.currency | uppercase)
                      }}
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            *ngIf="invoice.status == 'draft'"
            class="delete is-small"
            (click)="removeInvoiceItem(item, i)"
          ></button>
        </div>
        <div class="columns" *ngIf="invoice.status == 'draft'">
          <div class="column">
            <div class="invoice-item add-item" (click)="addInvoiceItem()">
              <p>{{ "office.invoice.actions.add_item" | translate }}</p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-offset-7 is-2 has-text-right">
            <p class="subtitle">
              {{ "office.invoice.labels.tax_total" | translate }}
            </p>
          </div>
          <div class="column has-text-right">
            <p class="subtitle">
              {{
                totalTax()
                  | currency : (invoice.currency | uppercase)
              }}
            </p>
          </div>
        </div>
        <div class="columns">
          <div class="column is-offset-7 is-2 has-text-right">
            <p class="title is-4">
              {{ "office.invoice.labels.total" | translate }}
            </p>
          </div>
          <div class="column has-text-right">
            <p class="title is-4">
              {{
                invoice.invoice_items
                  | invoiceTotal
                  | currency : (invoice.currency | uppercase)
              }}
            </p>
          </div>
        </div>
        <ng-container *ngIf="invoice.status == 'paid'">
          <ng-container *ngIf="invoice.fee_rate > 0; else oldInvoices">
            <div class="columns">
              <div class="column is-9 has-text-right">
                <p class="subtitle is-6">
                  {{ "office.invoice.labels.application_fee" | translate }} ({{
                    invoice.fee_rate * 100 | number : "1.0-2"
                  }}%)
                </p>
              </div>
              <div class="column has-text-right">
                <p class="subtitle is-6">
                  -
                  {{
                    (invoice.invoice_items | invoiceTotal) * invoice.fee_rate
                      | currency : (invoice.currency | uppercase)
                  }}
                </p>
              </div>
            </div>
            <div class="columns">
              <div class="column is-9 has-text-right">
                <p class="subtitle is-6">
                  {{ "office.invoice.labels.net" | translate }}
                </p>
              </div>
              <div class="column has-text-right">
                <p class="subtitle is-6">
                  {{
                    (invoice.invoice_items | invoiceTotal) -
                      (invoice.invoice_items | invoiceTotal) * invoice.fee_rate
                      | currency : (invoice.currency | uppercase)
                  }}
                </p>
              </div>
            </div>
          </ng-container>

          <ng-template #oldInvoices>
            <div class="columns">
              <div class="column is-9 has-text-right">
                <p class="subtitle is-6">It's Complicated fee</p>
              </div>
              <div class="column has-text-right">
                <p class="subtitle is-6">
                  -
                  {{
                    invoice.application_fee / 100
                      | currency : invoice.currency
                  }}
                </p>
              </div>
            </div>
            <div class="columns">
              <div class="column is-9 has-text-right">
                <p class="subtitle is-6">Stripe fee</p>
              </div>
              <div class="column has-text-right">
                <p class="subtitle is-6">
                  -
                  {{
                    invoice.stripe_fee / 100
                      | currency : invoice.currency
                  }}
                </p>
              </div>
            </div>
            <div class="columns">
              <div class="column is-9 has-text-right">
                <p class="subtitle is-6">
                  {{ "office.invoice.labels.net" | translate }}
                </p>
              </div>
              <div class="column has-text-right">
                <p class="subtitle is-6">
                  {{
                    (invoice.total * 100 -
                      invoice.application_fee -
                      invoice.stripe_fee) /
                      100 | currency : invoice.currency
                  }}
                </p>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <p
          *ngIf="(invoice.invoice_items | invoiceTotal) < 5"
          class="help has-text-right"
        >
          {{ "office.invoice.errors.not_enough" | translate }}
          {{ invoice.currency | uppercase }}
        </p>
      </div>
      <hr />

      <div class="buttons is-hidden-print">
        <ng-container *ngIf="invoice.status == 'draft'">
          <button
            class="button is-rounded is-danger is-outlined"
            (click)="deleteInvoice()"
            [ngClass]="{ 'is-loading': isDeletingInvoice }"
          >
            {{ "office.invoice.actions.delete" | translate }}
          </button>
          <button
            class="button is-rounded"
            (click)="saveChanges()"
            [ngClass]="{ 'is-loading': isSavingInvoice }"
          >
            {{ "office.invoice.actions.save" | translate }}
          </button>
          <button
            class="button is-rounded is-primary"
            (click)="sendInvoice()"
            [ngClass]="{ 'is-loading': isSavingAndSendingInvoice }"
            [disabled]="(invoice.invoice_items | invoiceTotal) < 5"
          >
            {{ "office.invoice.actions.save_and_send" | translate }}
          </button>
        </ng-container>

        <ng-container *ngIf="invoice.status == 'open'">
          <button
            class="button is-rounded"
            (click)="markInvoiceAsPaid()"
            [ngClass]="{ 'is-loading': isMarkingInvoiceAsPaid }"
          >
            {{ "office.invoice.actions.mark_as_paid" | translate }}
          </button>
          <button
            class="button is-rounded"
            (click)="sendEmailReminder()"
            [ngClass]="{ 'is-loading': isSendingEmailReminder }"
          >
            {{ "office.invoice.actions.send_reminder" | translate }}
          </button>
          <button
            class="button is-rounded is-danger is-outlined"
            (click)="cancelInvoice()"
          >
            {{ "office.invoice.actions.cancel" | translate }}
          </button>
        </ng-container>
        <ng-container *ngIf="invoice.status == 'paid'">
          <button
            class="button is-rounded is-danger is-outlined"
            (click)="refundInvoice()"
          >
            {{ "office.invoice.actions.refund" | translate }}
          </button>
        </ng-container>
        <ng-container *ngIf="invoice.status != 'draft'">
          <button
            class="button is-rounded is-outlined"
            (click)="downloadInvoice()"
          >
            {{ "office.invoice.actions.download" | translate }}
          </button>
          <button
            class="button is-rounded is-outlined"
            (click)="duplicateInvoice()"
          >
            {{ "office.invoice.actions.copy" | translate }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
