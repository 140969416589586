<!-- launcher.component.html -->
<section class="dashboard-teaser wrapper">
  <header class="dashboard-teaser-header">
    <h3 class="dashboard-teaser-title">{{ 'launcher.header.title' | translate }}</h3>
    <p class="subtitle">{{ 'launcher.header.subtitle' | translate }}</p>
  </header>

  <div class="card">
    <div class="card-content content">
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let step of steps; let i = index"
          [expanded]="isStepExpanded(i)"
          [disabled]="isStepDisabled(i)"
          (opened)="onPanelOpened(i)"
          (closed)="onPanelClosed(i)"
          class="launcher-panel"
          [class.disabled-panel]="isStepDisabled(i)"
          [class.completed-panel]="isStepComplete(i)"
          [class.current-panel]="i === currentStepIndex">

          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="completion-indicator" [class.complete]="isStepComplete(i)">
                <mat-icon *ngIf="isStepComplete(i)" class="has-text-success" [matTooltip]="'launcher.actions.done' | translate">
                  check_circle
                </mat-icon>
                <mat-icon *ngIf="!isStepComplete(i)" class="incomplete-circle">
                  radio_button_unchecked
                </mat-icon>
              </div>
              {{ 'launcher.steps.' + step.id + '.title' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="panel-content">
            <p class="step-description">{{ 'launcher.steps.' + step.id + '.subtitle' | translate }}</p>

            <div class="step-actions">
              <div class="buttons">
                <button class="button is-rounded is-primary" (click)="step.action()">
                  {{ 'launcher.steps.' + step.id + '.cta' | translate }}
                </button>

                <div class="action-buttons" *ngIf="!isStepComplete(i)">
                  <ng-container *ngIf="step.hasCalendarCheck">
                    <button
                      class="button is-small is-rounded action-button"
                      (click)="setIgnoreCalendar()"
                      [matTooltip]="'launcher.actions.ignore' | translate">
                      <mat-icon>close</mat-icon>
                    </button>
                  </ng-container>

                  <ng-container *ngIf="!step.hasCalendarCheck">
                    <button
                      class="button is-small is-rounded action-button"
                      (click)="markStepDone(i)"
                      [matTooltip]="'launcher.actions.done' | translate">
                      <mat-icon>check</mat-icon>
                    </button>
                    <button
                      class="button is-small is-rounded action-button"
                      (click)="markStepDone(i)"
                      [matTooltip]="'launcher.actions.skip' | translate">
                      <mat-icon>close</mat-icon>
                    </button>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</section>
