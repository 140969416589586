import { Component, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Note, NoteTemplate } from 'src/app/entities/note.model';
import { NoteService } from './note.service';
import { FormsModule } from '@angular/forms';
import { NgClass, NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'shared-note-modal',
    templateUrl: './new-note-modal.component.html',
    styleUrls: ['./new-note-modal.component.css'],
    imports: [NgIf, NgClass, FormsModule, NgFor, TranslateModule]
})
export class NewNoteModalComponent implements OnInit {
  constructor(
    private noteService: NoteService,
    private translateService: TranslateService
  ) {}

  isModalActive: boolean = false;
  isSaving: boolean = false;
  note: Note;
  noteTemplate = NoteTemplate;
  noteTemplateKeys = Object.keys(this.noteTemplate);

  // public froalaEditorOptions: Object = {
  //   fileUpload: false,
  //   toolbarButtons: [
  //     'paragraphFormat',
  //     'undo',
  //     'redo',
  //     'bold',
  //     'italic',
  //     'underline',
  //     'formatUL',
  //     'formatOL',
  //   ],
  //   paragraphFormat: {
  //     N: 'Normal',
  //     H4: 'Heading 4',
  //     H5: 'Heading 5',
  //   },
  // };

  ngOnInit(): void {
    this.noteService.note.subscribe((note) => (this.note = note));
    this.noteService.showModal.subscribe(
      (state) => (this.isModalActive = state)
    );
  }

  closeModal() {
    this.noteService.closeModal();
  }

  onTemplateChange() {
    const headlines = this.translateService
      .instant(`clientcenter.notes.template_prefill.${this.note.template}`)
      .split(' ');
    if (this.note.body == '' || this.note.body == null) {
      this.note.body = '<h3>' + headlines.join('</h3><p></p><h3>') + '</h3>';
    } else {
      if (this.note.body.indexOf('<h3>') == -1) {
        this.note.body =
          '<h3>' +
          headlines.join('</h3><p></p><h3>') +
          '</h3>' +
          this.note.body;
      } else {
        let index = -1;
        this.note.body = this.note.body.replace(/<h3>[a-zA-Z]*<\/h3>/g, () => {
          index++;
          return headlines[index] ? '<h3>' + headlines[index] + '</h3>' : '';
        });
      }
    }
  }

  update() {
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;

    this.noteService.saveNote(this.note).then(() => {
      this.isSaving = false;
    });
  }
}
