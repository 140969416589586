<div [@simpleFadeAnimation]="'in'">
  <h3 class="subtitle is-4 is-hidden-touch">
    {{ "office.settings.subscription.choose_plan" | translate }}
  </h3>
  <div class="card" *ngIf="!!isYearlyBilling$">
    <div class="tabs is-fullwidth">
      <ul>
        <li>
          <a
            class="tab"
            (click)="setBillingPeriodToYearly(false)"
            [ngClass]="{ 'is-selected': !isYearlyBilling$.value }"
            >{{
              "office.settings.subscription.plan_picker_monthly" | translate
            }}</a
          >
        </li>
        <li>
          <a
            class="tab highlighted"
            (click)="setBillingPeriodToYearly(true)"
            [ngClass]="{ 'is-selected': isYearlyBilling$.value }"
            >{{ "office.settings.subscription.plan_picker_yearly" | translate
            }}</a>
        </li>
      </ul>
    </div>

    <!-- Crazy structure, that was the simples way I could get the animations the way I wanted -->
    <ng-container
      *ngIf="isYearlyBilling$ | async as isYearlyBilling; else noYearlyBilling"
    >
      <div
        class="plan-picker-table columns is-multiline has-margin-top-0"
        @planAnimation
      >
        <div
          class="column is-one-third plan-container"
          *ngFor="let plan of plans"
        >
          <app-plan-v2
            [plan]="plan"
            [isYearlyBilling]="isYearlyBilling"
            [isHighlighted]="plan.is_highlighted"
            (onPlanSelected)="onPlanSelected($event.paymentPlan, $event.plan)"
          ></app-plan-v2>
        </div>
      </div>
    </ng-container>
    <ng-template #noYearlyBilling>
      <div class="plan-picker-table columns is-multiline" @planAnimation>
        <div
          class="column is-one-third plan-container"
          *ngFor="let plan of plans"
        >
          <app-plan-v2
            [plan]="plan"
            [isYearlyBilling]="false"
            [isHighlighted]="plan.is_highlighted"
            (onPlanSelected)="onPlanSelected($event.paymentPlan, $event.plan)"
          ></app-plan-v2>
        </div>
      </div>
    </ng-template>
  </div>
</div>
