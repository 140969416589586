import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: '**', redirectTo: '/home', pathMatch: 'full' },
    ]),
  ],
  exports: [RouterModule],
})
export class CatchAllRoutingModule {}
