import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { InvoiceItem } from 'src/app/entities/invoice-item.model';
import { InvoiceExtended } from 'src/app/entities/invoice.model';
import { Client } from 'src/app/entities/client.model';
import { OfficeService } from 'src/app/office/shared/service/office.service';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';
import { User } from 'src/app/entities/user.model';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { LeanConversationUser } from '../../../entities/lean-conversation-user.model';
import { MonitoringService } from 'src/app/shared/services/monitoring/monitoring.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { of } from 'rxjs';

@Component({
  selector: 'app-log-attachment',
  templateUrl: './log-attachment.component.html',
  styleUrls: ['./log-attachment.component.scss'],
  imports: [NgIf, NgClass, RouterLink, DatePipe, TranslateModule],
})
export class LogAttachmentComponent implements OnInit {
  constructor(
    private officeService: OfficeService,
    private router: Router,
    private http: GenericHttpService
  ) {}

  @Input() currentUser: User;
  @Input() log: {};

  @Input() otherUser: LeanConversationUser;

  areDetailsLoaded: boolean = false;
  isSavingInvoice: boolean = false;

  logType: string;
  invoice: InvoiceExtended;
  canUseInvoicing: boolean = false;

  ngOnInit() {
    this.canUseInvoicing =
      this.currentUser &&
      this.currentUser.type === 'Therapist' &&
      this.currentUser.profile.stripe_connect_active;

    if (this.log['duration']) {
      this.logType = 'online-session';
      const uid = this.log['uid'] || this.log['id'];
      this.http
        .get('/onlinesessions/' + uid)
        .pipe(
          catchError((error) => {
            if (error.status >= 400 && error.status < 500) {
              console.error('Error fetching online session', error);
            }
            return of(null); // Return a fallback value or handle the error as needed
          })
        )
        .subscribe((response) => {
          if (response) {
            this.log = response;
            this.invoice = response['invoice'];
            this.areDetailsLoaded = true;
          }
        });
    } else {
      this.logType = this.log['type'];
    }
  }

  invoiceSession() {
    if (this.logType != 'online-session') return;

    this.isSavingInvoice = true;

    var invoice = new InvoiceExtended();
    invoice.client = new Client();
    invoice.client.id = this.log['client_id'];
    var invoiceItem = new InvoiceItem();
    invoiceItem.online_session_id = this.log['id'];
    invoiceItem.name =
      'Online Therapie Session on ' +
      new Date(this.log['created_at']).toDateString();
    invoiceItem.price = this.currentUser.profile.price_per_session;
    invoice.invoice_items = [invoiceItem];
    this.officeService.createInvoice(invoice).subscribe((response) => {
      this.router.navigate(['office', 'invoices', response.invoice.id]);
    });
  }
}
