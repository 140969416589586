<table mat-table [dataSource]="dataSource" matSort class="invoices-table">
  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "common.date" | translate }}
    </th>
    <td mat-cell *matCellDef="let invoice">
      {{ invoice.created_at | date : "shortDate" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="invoice_no">
    <th
      mat-header-cell
      class="is-hidden-mobile"
      *matHeaderCellDef
      mat-sort-header
    >
      <div>{{ "office.invoice.list.invoice_number" | translate }}</div>
    </th>
    <td mat-cell class="is-hidden-mobile" *matCellDef="let invoice">
      {{ invoice.stripe_invoice_number }}
    </td>
  </ng-container>

  <ng-container matColumnDef="client">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div class="is-clipped px-5">{{ "common.client" | translate }}</div>
    </th>
    <td mat-cell *matCellDef="let invoice">
      <div class="is-clipped px-5">
        <span *ngIf="invoice.client; else no_client">{{
          invoice.client.name
        }}</span>
        <ng-template #no_client>
          <span *ngIf="invoice.client_email">{{ invoice.client_email }}</span>
        </ng-template>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <div class="is-clipped px-5">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "common.status" | translate }}
      </th>
      <td mat-cell *matCellDef="let invoice">
        {{ "office.invoice.statuses." + invoice.status | translate }}
      </td>
    </div>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th
      mat-header-cell
      class="is-hidden-mobile"
      *matHeaderCellDef
      mat-sort-header
    >
      {{ "common.amount" | translate }}
    </th>
    <td mat-cell class="is-hidden-mobile" *matCellDef="let invoice">
      {{
        invoice.total | currency : (invoice.currency | uppercase)
      }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let invoice">
      <!-- You can add your menu items here -->
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"

        class="menu-button"
        (click)="$event.stopPropagation()"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu"  class="elevation_2">
        <button mat-menu-item [routerLink]="['/office/invoices', invoice.id]">
          {{ "office.invoice.actions.show_detail" | translate }}
        </button>
        <ng-container *ngIf="invoice.status != 'draft'">
          <button mat-menu-item (click)="downloadInvoice($event, invoice)">
            {{ "office.invoice.actions.download" | translate }}
          </button>
          <button mat-menu-item (click)="getInvoiceLink($event, invoice)">
            {{ "office.invoice.actions.show_link" | translate }}
          </button>
        </ng-container>

        <button mat-menu-item (click)="duplicateInvoice($event, invoice)">
          {{ "office.invoice.actions.copy" | translate }}
        </button>
        <button
          mat-menu-item
          *ngIf="invoice.status == 'draft'"
          (click)="deleteInvoice($event, invoice)"
        >
          {{ "office.invoice.actions.delete" | translate }}
        </button>
        <button
          mat-menu-item
          *ngIf="invoice.status == 'open'"
          (click)="cancelInvoice($event, invoice)"
        >
          {{ "office.invoice.actions.cancel" | translate }}
        </button>
        <button
          mat-menu-item
          *ngIf="invoice.status == 'paid'"
          (click)="refundInvoice($event, invoice)"
        >
          {{ "office.invoice.actions.refund" | translate }}
        </button>
        <button
          mat-menu-item
          *ngIf="invoice.status == 'open'"
          (click)="markInvoiceAsPaid($event, invoice)"
        >
          {{ "office.invoice.actions.mark_as_paid" | translate }}
        </button>
        <button
          mat-menu-item
          *ngIf="invoice.status == 'open'"
          (click)="sendEmailReminder($event, invoice)"
        >
          {{ "office.invoice.actions.send_reminder" | translate }}
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="onRowClick(row)"
    class="invoice-table-item"
  ></tr>
</table>
