import { User } from './user.model';
import { Conversation } from './conversation.model';
import { InvoiceExtended } from './invoice.model';

export class Message {
  body: string;
  user_id: number;
  read: boolean;
  created_at: Date;
  id: number;
  user: User;
  bookingNotification: boolean;
  conversation?: Conversation;
  decrypted: boolean;
  encrypted: boolean;
  deleted: boolean;
  attachment: any;
  appointment: any;
  file_attachment: any;
  invoice: InvoiceExtended;
  log: string;
}
