<form [formGroup]="requestBookingForm">
  <section class="modal-card-body content">
    <div class="box date-review-box" *ngIf="showClient">
      <strong class="review-date">
        <i class="icon icon-my-clients"></i>
        {{ selectedClient?.name }}
      </strong>
      <button
        class="button is-small is-rounded"
        [disabled]="isBusy"
        (click)="onChangeClient()"
      >
        {{ "messaging.booking-modal.change" | translate }}
      </button>
    </div>

    <div class="box date-review-box">
      <strong class="review-date">
        <i class="icon icon-calendar"></i>
        {{ appointmentStart | localisedDate : 'ddd, MMMM D [at] HH:mm'}} <span *ngIf="timezoneText">&nbsp;({{ timezoneText }})</span>
      </strong>
      <button
        class="button is-small is-rounded"
        [disabled]="isBusy"
        (click)="onChangeDate()"
      >
        {{ "messaging.booking-modal.change" | translate }}
      </button>
    </div>

    <div class="field" *ngIf="showDuration">
      <label class="label has-text-left">{{
        "messaging.booking-modal.duration" | translate
      }}</label>
      <div class="duration">
        <p class="control has-icons-right">
          <input
            class="input"
            formControlName="duration"
            [disabled]="isBusy"
            [mask]="'009'"
          />
          <span class="icon is-small is-right">{{
            "common.min" | translate
          }}</span>
        </p>
        <p
          class="help is-error"
          *ngIf="requestBookingForm.get('duration').errors?.['durationInvalid']  && (requestBookingForm.touched || requestBookingForm.dirty)"
        >
          {{ "messaging.booking-modal.invalid-duration" | translate }}
        </p>
      </div>

    </div>

    <label class="label">{{
      "messaging.booking-modal.online-or-not" | translate
    }}</label>
    <div class="buttons">
      <button
        class="button is-primary is-rounded"
        [disabled]="isBusy"
        [ngClass]="{
          'is-outlined': requestBookingForm.controls.isOnline.value !== true
        }"
        (click)="setIsOnline(true)"
      >
        {{ "messaging.online" | translate }}
      </button>
      <button
        class="button is-primary is-rounded"
        [disabled]="isBusy"
        [ngClass]="{
          'is-outlined': requestBookingForm.controls.isOnline.value !== false
        }"
        (click)="setIsOnline(false)"
      >
        {{ "messaging.booking-modal.in-person" | translate }}
      </button>
    </div>
    <p class="help">
      {{ "messaging.booking-modal.help-text" | translate }}
    </p>

    <label class="label">
      {{ "messaging.booking-modal.invoice-in-advance" | translate }}</label
    >
    <div class="buttons">
      <button
        class="button is-primary is-rounded"
        [ngClass]="{
          'is-outlined':
            requestBookingForm.controls.paymentType.value != 'prepay'
        }"
        [disabled]="!canUseInvoicing || isBusy"
        (click)="setPaymentType(PaymentType.prepay)"
      >
        {{ "messaging.booking-modal.prepay" | translate }}
      </button>
      <button
        class="button is-primary is-rounded"
        [disabled]="isBusy"
        [ngClass]="{
          'is-outlined':
            requestBookingForm.controls.paymentType.value != 'invoice_later'
        }"
        (click)="setPaymentType(PaymentType.invoice_later)"
      >
        {{ "messaging.booking-modal.invoice-later" | translate }}
      </button>
        <button
                class="button is-primary is-rounded"
                [disabled]="isBusy"
                *ngIf="showEAPBookingOption && !cannotBookEAPSessionReason"
                [ngClass]="{
          'is-outlined': requestBookingForm.controls.paymentType.value != 'eap'
        }"
                (click)="setPaymentType(PaymentType.eap)"
        >
          {{ "messaging.booking-modal.eap" | translate }}
        </button>
        <span
                *ngIf="showEAPBookingOption && cannotBookEAPSessionReason"
                matTooltipClass="mat-tooltip"
                class="mat-tooltip-trigger-icon-wrapper"
                matTooltip="{{
                    'messaging.booking-modal.eap-unavailable-no-client-code-tooltip' | translate : { reason: cannotBookEAPSessionReason }
                  }}"
        >
        <button
                class="button is-primary is-rounded is-outlined"
                [disabled]="true"
        >
          {{ "messaging.booking-modal.eap" | translate }}
        </button>
          </span>
    </div>
    <p class="help" *ngIf="showEAPBookingOption && !cannotBookEAPSessionReason">
      {{ "messaging.booking-modal.eap-message" | translate }}
    </p>
    <p class="help" *ngIf="!showEAPBookingOption || !!cannotBookEAPSessionReason">
      {{ "messaging.booking-modal.choosing" | translate }}
      <i> {{ "messaging.booking-modal.prepay" | translate }}</i>
      {{ "messaging.booking-modal.choosing-text" | translate }}
    </p>
    <p class="help" *ngIf="!canUseInvoicing">
      {{ "messaging.booking-modal.activate-prepay" | translate }}
    </p>



    <div
      class="columns"
      *ngIf="
        requestBookingForm.controls.paymentType.value == PaymentType.prepay &&
        canUseInvoicing
      "
    >
      <div class="column">
        <label class="label">{{
          "messaging.booking-modal.how-to-charge" | translate
        }}</label>
        <div class="field">
          <label class="label">{{ "messaging.price" | translate }}</label>
          <p class="control has-icons-right">
            <input
              class="input is-medium"
              type="text"
              placeholder=""
              formControlName="prepayPrice"
              [disabled]="isBusy"
              mask="separator.2"
            />
            <span class="icon is-small is-right">{{ currency }}</span>
          </p>
          <p
            class="help is-error"
            *ngIf="requestBookingForm.errors?.['priceTooLow'] && (requestBookingForm.touched || requestBookingForm.dirty)"
          >
            {{ "messaging.booking-modal.minimum-invoice-amount" | translate }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="columns"
      *ngIf="
        requestBookingForm.controls.paymentType.value == PaymentType.eap && canBookCouplesSessions
      "
    >
      <div class="column">
          <label class="label">{{
              "messaging.booking-modal.eap-couples-therapy" | translate
            }}</label>
          <label class="switch">
            <input
              name="enableCouples"
              type="checkbox"
              [disabled]="isBusy"
              formControlName="isCouplesSession"
            />
            <span class="slider round"></span>
          </label>
          <p class="help" *ngIf="requestBookingForm.controls.isCouplesSession.value">
            {{ "messaging.booking-modal.eap-couples-on" | translate }}
          </p>
        <p class="help" *ngIf="!requestBookingForm.controls.isCouplesSession.value">
          {{ "messaging.booking-modal.eap-couples-off" | translate }}
        </p>
        </div>
    </div>
  </section>

  <footer class="modal-card-foot">
    <button class="button is-rounded" (click)="onCancel()">
      {{ "messaging.cancel" | translate }}
    </button>
    <button
      class="button is-primary is-rounded"
      (click)="onSubmit()"
      [ngClass]="{ 'is-loading': isBusy }"
      [disabled]="requestBookingForm.invalid || isBusy"
    >
      <ng-container [ngSwitch]="actionType">
        <span *ngSwitchCase="SubmitActionType.schedule">{{
          "messaging.booking-modal.schedule" | translate
        }}</span>
        <span *ngSwitchCase="SubmitActionType.confirm">{{
          "messaging.booking-modal.confirm" | translate
        }}</span>
        <span *ngSwitchCase="SubmitActionType.update">{{
          "messaging.booking-modal.update" | translate
        }}</span>
      </ng-container>
    </button>
  </footer>
</form>
