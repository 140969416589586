import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICAuthService } from 'src/app/auth/service/i-c-auth.service';
import { User } from 'src/app/entities/user.model';
import { Plan } from 'src/app/entities/plan.model';
import { PaymentPlan } from 'src/app/entities/payment-plan.model';
import { FeaturesService } from '../../../shared/services/features/features.service';
import { PaymentPlanAvailability } from '../../../entities/payment-plan-availability.model';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import {
  CurrencyPipe,
  NgClass,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  PercentPipe,
} from '@angular/common';
import { MatButton } from '@angular/material/button';
import { LocalisedDatePipe } from '../../booking/booking-form-container/localised-date.pipe';

@Component({
  selector: 'app-plan-v2',
  templateUrl: './planv2.component.html',
  styleUrls: ['./planv2.component.scss'],
  imports: [
    NgClass,
    NgSwitch,
    NgSwitchCase,
    MatTooltip,
    NgFor,
    MatIcon,
    NgIf,
    PercentPipe,
    CurrencyPipe,
    TranslateModule,
    MatButton,
    LocalisedDatePipe,
  ],
})
export class Planv2Component implements OnInit {
  protected readonly PaymentPlanAvailability = PaymentPlanAvailability;
  card: any;

  constructor(
    private authService: ICAuthService,
    private featuresService: FeaturesService
  ) {}

  @Input()
  public plan: Plan;

  @Input()
  isYearlyBilling: boolean;

  @Input()
  public isUpgrade: boolean;

  @Input()
  public isHighlighted: boolean;

  @Output() onPlanSelected: EventEmitter<{
    paymentPlan: PaymentPlan;
    plan: Plan;
  }> = new EventEmitter();
  public paymentPlan: PaymentPlan;
  public currentUser: User;

  ngOnInit() {
    this.paymentPlan = this.isYearlyBilling
      ? this.plan.payment_plans.find((plan) => plan.month === 12)
      : this.plan.payment_plans.find((plan) => plan.month === 1);

    this.authService.getCurrentUser().subscribe((user) => {
      if (user) {
        this.currentUser = user;
      }
    });
  }

  monthlyPrice(plan) {
    return plan.price / 100 / plan.month;
  }

  calculateSavings(plan) {
    const originalPrice = plan.monthly_price_discounted_from / 100;
    const currentPrice = this.monthlyPrice(plan);
    const savings = ((originalPrice - currentPrice) / originalPrice) * 100;
    return Math.round(savings);
  }

  onSelectPlan() {
    this.onPlanSelected.emit({
      paymentPlan: this.paymentPlan,
      plan: this.plan,
    });
  }
}
