import { Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'app-eap-member',
    templateUrl: './eap-member.component.html',
    styleUrls: ['./eap-member.component.scss'],
    imports: [MatTooltip, TranslateModule]
})
export class EapMemberComponent implements OnInit {
  @Input() companyName: string;
  constructor(private injector: Injector, private router: Router) {}

  ngOnInit() {}
}
