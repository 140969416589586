<div class="share-dialog">
  <div class="dialog-header">
    <h2>{{ 'launcher.share.dialog_title' | translate }}</h2>
    <button class="close-button" (click)="closeDialog()" [attr.aria-label]="'launcher.share.close' | translate">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <div class="message-preview">
      {{ 'launcher.share.message' | translate }}

      {{data.profileLink}}
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
      <button
        class="button is-primary is-rounded"
        (click)="copyToClipboard()">
        <mat-icon>content_copy</mat-icon>
        {{ 'launcher.share.copy_button' | translate }}
      </button>

      <button
        class="button is-rounded share-button"
        (click)="shareOnLinkedIn()">
        {{ 'launcher.share.linkedin_button' | translate }}
        <mat-icon>launch</mat-icon>
      </button>
    </mat-dialog-actions>
</div>
