import { ProfileService } from 'src/app/components/profile/service/profile.service';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/entities/user.model';
import { ICAuthService } from '../../../auth/service/i-c-auth.service';
import { SettingsService } from '../../settings/service/settings.service';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { environment } from 'src/environments/environment';
import { OnboardingStep } from './onboarding-box.service';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { OnboardingProgressBarComponent } from '../../../shared/components/onboarding-progress-bar/onboarding-progress-bar.component';
import { NgClass, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { createEditProfileLink } from '../../../shared/helpers/navigation_helper';
import { AmplitudeAnalyticsService } from '../../../shared/services/analytics/amplitude-analytics.service';

@Component({
    selector: 'app-onboarding-box',
    templateUrl: './onboarding-box.component.html',
    styleUrls: ['./onboarding-box.component.scss'],
    imports: [
        NgIf,
        OnboardingProgressBarComponent,
        AngularSvgIconModule,
        NgSwitch,
        NgSwitchCase,
        RouterLink,
        NgClass,
        TranslateModule,
    ]
})
export class OnboardingBoxComponent implements OnInit {
  constructor(
    private settingsService: SettingsService,
    private alertService: AlertService,
    private auth: ICAuthService,
    private profileService: ProfileService,
    private analytics: AmplitudeAnalyticsService
  ) {}

  env = environment;
  _currentUser: User;

  onboardingStep: OnboardingStep;
  ONBOARDINGSTEP = OnboardingStep;

  requestedMail: boolean;
  subscriptions: Array<any> = [];
  subscriptionLoaded: boolean = false;

  ngOnInit() {
    this.auth.getCurrentUser().subscribe((user: any) => {
      this._currentUser = user;

      if (user && user.type === 'Therapist') {
        this.settingsService.getActiveSubscriptions().subscribe((sub: any) => {
          this.subscriptions = sub;
          this.subscriptionLoaded = true;
          this.assignOnboardingStep();
        });
      }
    });
  }

  assignOnboardingStep() {
    if (
      !this._currentUser.profile.profile_approved &&
      !this._currentUser.profile.profile_completed
    ) {
      this.onboardingStep = OnboardingStep.fill_out_profile;
      return;
    }
    if (
      !this._currentUser.profile.profile_approved &&
      this._currentUser.profile.diplomas.length === 0
    ) {
      this.onboardingStep = OnboardingStep.waiting_for_diploma;
      return;
    }
    if (
      !this._currentUser.profile.profile_approved &&
      this._currentUser.profile.diplomas.length > 0
    ) {
      this.onboardingStep = OnboardingStep.waiting_for_approval;
      return;
    }
    if (this.subscriptions.length === 0) {
      this.onboardingStep = OnboardingStep.approved_but_not_subscribed;
      return;
    }
    this.onboardingStep = OnboardingStep.done;
  }

  canClose() {
    return (
      this._currentUser.email_confirmed &&
      this.onboardingStep === OnboardingStep.done
    );
  }

  openProfileEditorV2() {
    window.location.href = createEditProfileLink(
      this._currentUser.locale,
      this._currentUser.profile
    );
  }

  shouldDisplay() {
    if (!this.subscriptionLoaded) {
      return false;
    }
    if (this._currentUser.type === 'Client') {
      return false;
    }
    // Fallback: Email not verified yet?
    if (!this._currentUser.email_confirmed) {
      return true;
    }
    // keep this here for legacy reasons, so it doesn't pop up for people who ignored
    if (this._currentUser.profile.ignoreProfile) {
      return false;
    }
    return this.onboardingStep !== OnboardingStep.done;
  }

  resendWelcomeMail() {
    var self = this;
    this.auth.resendWelcomeMail().subscribe(() => {
      self.alertService.success('onboarding.email_sent');
      self.requestedMail = true;
    });
  }

  setIgnoreProfile() {
    this._currentUser.profile.ignoreProfile = true;
    this.profileService.updateItem(
      this._currentUser.profile,
      this._currentUser.profile.id
    );
  }

  setIgnoreCalendar() {
    this._currentUser.profile.ignoreCalendar = true;
    this.profileService.updateItem(
      this._currentUser.profile,
      this._currentUser.profile.id
    );
  }

  openCommunity() {
    this.analytics.trackOpenedCommunity({
      source_page: 'dashboard',
      source_action: 'onboarding_box',
    });
    window.open(
      'https://community.complicated.life/c/grow-your-practice-with-it-s-complicated/',
      '_blank'
    );
  }

  openDiplomas(): void {
    window.location.href = createEditProfileLink(
      this._currentUser.locale,
      this._currentUser.profile,
      'diplomas'
    );
  }
}
