import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CalendarService } from '../service/calendar.service';
import { Appointment } from '../../../entities/appointment.model';
import { User } from '../../../entities/user.model';
import { PaymentType } from '../../../entities/PaymentType';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { AppointmentStatusMessageComponent } from '../../booking/appointment-status-message/appointment-status-message.component';
import { AppointmentActionsComponent } from '../../booking/appointment-actions/appointment-actions.component';
import { LocalisedDatePipe } from '../../booking/booking-form-container/localised-date.pipe';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { AlertService } from '../../../shared/components/alert/service/alert.service';

@Component({
  template: `
    <div class="dialog-header">
      <h2 mat-dialog-title>
        {{ appointment?.title }}
      </h2>
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <mat-dialog-content cdkFocusInitial>
      <div *ngIf="loading" class="loading-state">
        <mat-progress-spinner
          mode="indeterminate"
          diameter="40"
        ></mat-progress-spinner>
      </div>

      <div *ngIf="!loading && appointment">
        <h3 class="subtitle" *ngIf="appointment.status != 'blocker'">
          <span class="icon-wrapper">
            <ng-container *ngIf="appointment.is_online">
              <mat-icon color="primary">video_chat</mat-icon>
              <span>Online</span> &nbsp;|&nbsp;
            </ng-container>
            <ng-container *ngIf="!appointment.is_online">
              <mat-icon color="primary">chair</mat-icon>
              <span>In Person</span> &nbsp;|&nbsp;
            </ng-container>
            {{ appointment.start | localisedDate : 'LT' }} -
            {{ appointment.end | localisedDate : 'LT' }}
            <span>&nbsp;({{ currentUser.profile.timezone }})</span>
          </span>

          <span class="icon-wrapper" [ngSwitch]="appointment.payment_type">
            <ng-container *ngSwitchCase="PaymentType.prepay">
              <mat-icon color="primary">payments</mat-icon>
              <span>Pre-pay</span>
            </ng-container>
            <ng-container *ngSwitchCase="PaymentType.invoice_later">
              <mat-icon color="primary">schedule</mat-icon>
              <span>Invoice later</span>
            </ng-container>
            <ng-container *ngSwitchCase="PaymentType.eap">
              <mat-icon color="primary">schedule</mat-icon>
              <span>EAP</span>
            </ng-container>
          </span>
        </h3>

        <h3 class="subtitle" *ngIf="appointment.status == 'blocker'">
          {{ appointment.start | localisedDate : 'LT' }} -
          {{ appointment.end | localisedDate : 'LT' }}
        </h3>

        <app-appointment-status-message
          [appointment]="appointment"
          [currentUser]="currentUser"
          [profileId]="appointment.profile.id"
        >
        </app-appointment-status-message>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end" *ngIf="!loading && appointment">
      <button
        *ngIf="appointment?.status == 'blocker'"
        mat-stroked-button
        color="warn"
        (click)="deleteBlocker()"
      >
        {{ 'calendar.remove-blocker' | translate }}
      </button>

      <app-appointment-actions
        *ngIf="appointment?.status != 'blocker'"
        [appointment]="appointment"
        [currentUser]="currentUser"
        [clientId]="appointment.client?.id"
        [profileId]="appointment.profile.id"
        [canUseVideo]="appointment.can_use_video"
        [useSmallButtons]="false"
        [editAppointment]="this.editAppointment.bind(this)"
        (changed)="appointmentChanged($event)"
      >
      </app-appointment-actions>
    </mat-dialog-actions>
  `,
  styles: [
    `
      .dialog-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 12px;
      }

      .loading-state {
        display: flex;
        justify-content: center;
        padding: 32px;
      }

      .subtitle {
        margin-bottom: 16px;
      }

      .icon-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    `,
  ],
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatProgressSpinner,
    NgIf,
    MatIcon,
    LocalisedDatePipe,
    NgSwitch,
    NgSwitchCase,
    AppointmentStatusMessageComponent,
    MatDialogActions,
    AppointmentActionsComponent,
    MatButton,
    TranslateModule,
    MatIconButton,
  ],
})
export class AppointmentDetailDialogComponent implements OnInit {
  protected readonly PaymentType = PaymentType;
  loading = true;
  appointment: Appointment;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      appointmentId: number | string;
      currentUser: User;
      onEdit: (appointment: Appointment) => void;
      onDelete: (id: string) => Promise<void>;
    },
    private dialogRef: MatDialogRef<AppointmentDetailDialogComponent>,
    private calendarService: CalendarService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.loadAppointment();
  }

  private loadAppointment() {
    this.calendarService
      .getAppointment(
        this.data.appointmentId,
        this.data.currentUser.type === 'Therapist'
      )
      .subscribe({
        next: (appointment) => {
          this.appointment = appointment;
          this.loading = false;
        },
        error: () => {
          this.dialogRef.close();
        },
      });
  }

  get currentUser(): User {
    return this.data.currentUser;
  }

  editAppointment = () => {
    this.data.onEdit(this.appointment);
    this.dialogRef.close();
  };

  deleteBlocker = async () => {
    this.loading = true;
    this.calendarService
      .deleteBlocker(this.appointment.id.toString())
      .subscribe({
        next: (res) => {
          this.alertService.success('calendar.remove-blocker-success');
          this.dialogRef.close(this.appointment.id);
        },
        error: (err) => {
          this.alertService.error('calendar.remove-blocker-error');
          this.loading = false;
        },
      });
  };

  appointmentChanged(appointmentId: number) {
    this.dialogRef.close(appointmentId);
  }

  close() {
    this.dialogRef.close();
  }
}
