import { User } from './../../entities/user.model';
import { SharedService } from './../../shared/services/shared.service';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    imports: [TranslateModule, DatePipe]
})
export class FooterComponent implements OnInit {
  constructor(private sharedService: SharedService) {}

  public now: Date;
  public user: User;

  ngOnInit() {
    this.sharedService.currentUser.subscribe((user) => {
      this.user = user;
    });
    this.now = new Date();
  }
}
