import { Component, OnInit } from '@angular/core';
import { FavoritesService } from './service/favorites-service';
import { User } from '../../entities/user.model';
import { SharedService } from '../../shared/services/shared.service';
import { AlertService } from '../../shared/components/alert/service/alert.service';
import {
  createDirectoryLink,
  createProfileLink,
} from '../../shared/helpers/navigation_helper';
import { Animation } from '../../shared/animations/fade-animation';
import { AmplitudeAnalyticsService } from '../../shared/services/analytics/amplitude-analytics.service';
import { TranslateModule } from '@ngx-translate/core';
import { FavoriteTherapistCardComponent } from './favorite-therapist-card/favorite-therapist-card.component';
import { NgFor, NgIf } from '@angular/common';
import { SidenavComponent } from '../../frame/sidenav/sidenav.component';

@Component({
    selector: 'app-favorites',
    templateUrl: './favorites.component.html',
    styleUrls: ['./favorites.component.scss'],
    animations: [Animation.fadeAnimation],
    imports: [
        SidenavComponent,
        NgIf,
        NgFor,
        FavoriteTherapistCardComponent,
        TranslateModule,
    ]
})
export class FavoritesComponent implements OnInit {
  private currentUser: User;
  loaded: boolean = false;
  isError: boolean = false;
  therapists = [];
  favorites = new Set<number>();

  constructor(
    private favoritesService: FavoritesService,
    private sharedService: SharedService,
    private alertService: AlertService,
    private analytics: AmplitudeAnalyticsService
  ) {}

  ngOnInit(): void {
    this.analytics.trackFavoritesViewed();
    this.sharedService.currentUser.subscribe((user) => {
      if (user) {
        this.currentUser = user;
        this.fetchFavorites();
      }
    });
  }

  fetchFavorites(): void {
    this.isError = false;
    this.favoritesService.getFavorites().subscribe(
      (favorites) => {
        this.therapists = favorites;
        this.therapists.forEach((therapist) =>
          this.favorites.add(therapist.id)
        );
        this.loaded = true;
      },
      (error) => {
        this.isError = true;
        this.loaded = true;
      }
    );
  }

  addFavorite(therapist: User) {
    // Optimistically add to favorites.
    this.favorites.add(therapist.id);

    this.favoritesService.addFavorite(therapist.id).subscribe(
      () => {},
      (error) => {
        this.favorites.delete(therapist.id);
        this.alertService.error('my-favorite-therapists.error-adding-favorite');
      }
    );
  }

  removeFavorite(therapist: User) {
    // Optimistically remove from favorites.
    this.favorites.delete(therapist.id);

    this.favoritesService
      .removeFavorite(therapist.id)
      .subscribe(null, (error) => {
        // If the service call fails, revert the change.
        this.favorites.add(therapist.id);
        this.alertService.error(
          'my-favorite-therapists.error-removing-favorite'
        );
      });
  }

  navigateToProfile(therapist: User) {
    this.analytics.trackFavoriteClicked({
      therapist_user_id: therapist.id.toString(),
    });
    window.location.href = createProfileLink(
      therapist,
      this.currentUser.locale
    );
  }

  navigateToDirectory() {
    window.location.href = createDirectoryLink(this.currentUser.locale);
  }
}
