import { Component, OnInit } from '@angular/core';
import { ICAuthService } from '../../../auth/service/i-c-auth.service';
import { User } from 'src/app/entities/user.model';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SettingsService } from '../../settings/service/settings.service';
import { environment } from 'src/environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-client-onboarding-box',
    templateUrl: './client-onboarding-box.component.html',
    styleUrls: ['./client-onboarding-box.component.scss'],
    imports: [NgIf, AngularSvgIconModule, NgClass, TranslateModule]
})
export class ClientOnboardingBoxComponent implements OnInit {
  shouldShowBox: boolean = false;
  isRequestingEmail: boolean = false;
  currentUser: User;
  constructor(
    private sharedService: SharedService,
    private settingsService: SettingsService,
    private alertService: AlertService,
    private auth: ICAuthService
  ) {}

  public env = environment;

  ngOnInit(): void {
    this.sharedService.currentUser.subscribe((user) => {
      if (user) {
        this.currentUser = user;
        this.shouldShowBox =
          !!this.currentUser && !this.currentUser.email_confirmed;
      }
    });
  }
  resendVerificationEmail() {
    if (this.isRequestingEmail) {
      return;
    }
    this.isRequestingEmail = true;
    this.auth.resendWelcomeMail().subscribe(() => {
      this.alertService.success('onboarding.email_sent');
      this.isRequestingEmail = false;
    });
  }
}
