import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CalendarComponent } from '../calendar/calendar.component';
import { SettingsComponent } from '../settings/settings.component';
import { ReferralsComponent } from '../referrals/referrals.component';
import { AuthCanActivate } from '../../auth/service/i-c-auth.service';
import { FavoritesComponent } from '../favorites/favorites.component';
import { WaitingRoomComponent } from '../waiting-room/waiting-room.component';
import { TranslateModule } from '@ngx-translate/core';
import { MessageListStreamComponent } from '../messaging/message-list-stream/message-list-stream.component';
import { StreamConversationComponent } from '../messaging/stream-conversation/stream-conversation.component';

const routes = [
  {
    path: 'home/video-waiting-room',
    component: WaitingRoomComponent,
    canActivate: AuthCanActivate,
  },
  {
    path: 'home/messaging',
    component: MessageListStreamComponent,
    canActivate: AuthCanActivate,
  },
  {
    path: 'home/messagingV2',
    component: MessageListStreamComponent,
    canActivate: AuthCanActivate,
  },
  {
    path: 'home/messaging/:id',
    component: StreamConversationComponent,
    canActivate: AuthCanActivate,
  },
  {
    path: 'home/messagingV2/:id',
    component: StreamConversationComponent,
    canActivate: AuthCanActivate,
  },
  {
    path: 'home/calendar',
    component: CalendarComponent,
    canActivate: AuthCanActivate,
  },
  {
    path: 'home/invites',
    component: ReferralsComponent,
    canActivate: AuthCanActivate,
  },
  {
    path: 'home/favourites',
    component: FavoritesComponent,
    canActivate: AuthCanActivate,
  },
  {
    path: 'home/settings',
    component: SettingsComponent,
    canActivate: AuthCanActivate,
  },
  {
    path: 'home/settings/calendar',
    component: SettingsComponent,
    canActivate: AuthCanActivate,
  },
  {
    path: 'home/settings/programs',
    component: SettingsComponent,
    canActivate: AuthCanActivate,
  },
  {
    path: 'home/settings/notifications',
    component: SettingsComponent,
    canActivate: AuthCanActivate,
  },
  {
    path: 'home/settings/subscription',
    component: SettingsComponent,
    canActivate: AuthCanActivate,
  },
  {
    path: 'home/settings/subscription-callback',
    component: SettingsComponent,
    canActivate: AuthCanActivate,
  },
  {
    path: 'home/settings/plan-picker',
    component: SettingsComponent,
    canActivate: AuthCanActivate,
  },
  {
    path: 'home/settings/community-plan',
    component: SettingsComponent,
    canActivate: AuthCanActivate,
  },
  {
    path: 'home/settings/widgets',
    component: SettingsComponent,
    canActivate: AuthCanActivate,
  },
];

@NgModule({
  imports: [
    TranslateModule.forChild({
      extend: true, // This option will make the module inherit translations from parent modules
    }),
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class ComponentsRoutingModule {}
