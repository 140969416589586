<section class="dashboard-teaser">
  <header class="dashboard-teaser-header">
    <div class="columns is-mobile align-center">
      <div class="column">
        <h3 class="dashboard-teaser-title">
          {{ "common.invoices" | translate }}
        </h3>
      </div>
    </div>
  </header>

  <div class="card">
    <div class="table-filters">
      <!-- Status filter -->
      <mat-form-field appearance="outline" class="status-select">
        <mat-label>{{ "common.status" | translate }}</mat-label>
        <mat-select
          [(ngModel)]="selectedStatus"
          placeholder="{{ 'office.invoice.list.all_statuses' | translate }}"
          (ngModelChange)="onStatusChange()">
          <mat-option *ngFor="let option of statusOptions" [value]="option.value">
            {{ option.label | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Finalized Date filter -->
      <mat-form-field appearance="outline" class="date-filter">
        <mat-label>{{ "office.invoice.list.finalized_on" | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="finalizedPicker" [dateFilter]="validDateFilter">
          <input matStartDate
                 [placeholder]="datePlaceholder"
                 [(ngModel)]="dateFilters.finalized.start"
                 name="finalizedStart"
                 (dateChange)="onDateFilterChange()">
          <input matEndDate
                 [placeholder]="datePlaceholder"
                 [(ngModel)]="dateFilters.finalized.end"
                 name="finalizedEnd"
                 (dateChange)="onDateFilterChange()">
        </mat-date-range-input>
        <div matSuffix class="suffix-buttons">
          <button *ngIf="isFinalizedAtDateFilterActive"
                  mat-icon-button
                  class="clear-button"
                  (click)="clearFinalizedDateFilter()"
                  aria-label="Clear date filter">
            <mat-icon>close</mat-icon>
          </button>
          <mat-datepicker-toggle [for]="finalizedPicker"></mat-datepicker-toggle>
        </div>
        <mat-date-range-picker #finalizedPicker></mat-date-range-picker>
      </mat-form-field>

      <!-- Paid Date filter -->
      <mat-form-field appearance="outline" class="date-filter">
        <mat-label>{{ "office.invoice.list.paid_on" | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="paidPicker">
          <input matStartDate
                 [placeholder]="datePlaceholder"
                 [(ngModel)]="dateFilters.paid.start"
                 name="paidStart"
                 (dateChange)="onDateFilterChange()">
          <input matEndDate
                 [placeholder]="datePlaceholder"
                 [(ngModel)]="dateFilters.paid.end"
                 name="paidEnd"
                 (dateChange)="onDateFilterChange()">
        </mat-date-range-input>
        <div matSuffix class="suffix-buttons">
          <button *ngIf="isPaidDateFilterActive"
                  mat-icon-button
                  class="clear-button"
                  (click)="clearPaidDateFilter()"
                  aria-label="Clear date filter">
            <mat-icon>close</mat-icon>
          </button>
          <mat-datepicker-toggle [for]="paidPicker"></mat-datepicker-toggle>
        </div>
        <mat-date-range-picker #paidPicker></mat-date-range-picker>
      </mat-form-field>

      <!-- Search filter -->
      <mat-form-field appearance="outline" class="search-field">
        <mat-label>{{ "common.search" | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput
               [(ngModel)]="filterQuery"
               (ngModelChange)="onSearchChange($event)"
               [placeholder]="'office.invoice.list.search_placeholder' | translate">
      </mat-form-field>

      <button mat-raised-button
              color="primary"
              class="export-button"
              (click)="openExportDialog()">
        <mat-icon>file_download</mat-icon>
        {{ 'office.invoice.export.button' | translate }}
      </button>
    </div>

    <div class="table-container">
      <div class="loading-overlay" *ngIf="isLoading">
        <mat-spinner diameter="50"></mat-spinner>
      </div>

      <table mat-table [dataSource]="dataSource" matSort class="invoices-table">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="is-hidden-mobile">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row" class="is-hidden-mobile">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="toggleSelection(row)"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Other existing columns... -->
        <ng-container matColumnDef="invoice_no">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "office.invoice.list.invoice_number" | translate }}
          </th>
          <td mat-cell *matCellDef="let invoice">{{ invoice.stripe_invoice_number }}</td>
        </ng-container>

        <ng-container matColumnDef="client">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "common.client" | translate }}
          </th>
          <td mat-cell *matCellDef="let invoice">
            <span *ngIf="invoice.client">{{ invoice.client.name }}</span>
            <span *ngIf="!invoice.client && invoice.client_email">{{ invoice.client_email }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "common.status" | translate }}
          </th>
          <td mat-cell *matCellDef="let invoice">
            {{ "office.invoice.statuses." + invoice.status | translate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>
            {{ "common.amount" | translate }}
          </th>
          <td mat-cell *matCellDef="let invoice">
            {{ invoice.total | currency:(invoice.currency | uppercase) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="finalized_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="header-cell-content">
              <div class="header-title">{{ "office.invoice.list.finalized_on" | translate }}</div>
            </div>
          </th>
          <td mat-cell *matCellDef="let invoice">
            <span *ngIf="invoice.finalized_at">{{ invoice.finalized_at | localisedDate:'L' }}</span>
            <span *ngIf="!invoice.finalized_at">-</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="paid_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="header-cell-content">
              <div class="header-title">{{ "office.invoice.list.paid_on" | translate }}</div>
            </div>
          </th>
          <td mat-cell *matCellDef="let invoice">
            <span *ngIf="invoice.paid_at">{{ invoice.paid_at | localisedDate:'L' }}</span>
            <span *ngIf="!invoice.paid_at">-</span>
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let invoice">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu"  class="elevation_2">
              <button mat-menu-item [routerLink]="['/office/invoices', invoice.id]">
                {{ "office.invoice.actions.show_detail" | translate }}
              </button>
              <ng-container *ngIf="invoice.status != 'draft'">
                <button mat-menu-item (click)="downloadInvoice($event, invoice)">
                  {{ "office.invoice.actions.download" | translate }}
                </button>
                <button mat-menu-item (click)="getInvoiceLink($event, invoice)">
                  {{ "office.invoice.actions.show_link" | translate }}
                </button>
              </ng-container>

              <button mat-menu-item (click)="duplicateInvoice($event, invoice)">
                {{ "office.invoice.actions.copy" | translate }}
              </button>
              <button
                mat-menu-item
                *ngIf="invoice.status == 'draft'"
                (click)="deleteInvoice($event, invoice)"
              >
                {{ "office.invoice.actions.delete" | translate }}
              </button>
              <button
                mat-menu-item
                *ngIf="invoice.status == 'open'"
                (click)="cancelInvoice($event, invoice)"
              >
                {{ "office.invoice.actions.cancel" | translate }}
              </button>
              <button
                mat-menu-item
                *ngIf="invoice.status == 'paid'"
                (click)="refundInvoice($event, invoice)"
              >
                {{ "office.invoice.actions.refund" | translate }}
              </button>
              <button
                mat-menu-item
                *ngIf="invoice.status == 'open'"
                (click)="markInvoiceAsPaid($event, invoice)"
              >
                {{ "office.invoice.actions.mark_as_paid" | translate }}
              </button>
              <button
                mat-menu-item
                *ngIf="invoice.status == 'open'"
                (click)="sendEmailReminder($event, invoice)"
              >
                {{ "office.invoice.actions.send_reminder" | translate }}
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"
            (click)="onRowClick(row)"
            [class.selected]="selection.isSelected(row)"
            class="invoice-table-item">
        </tr>
      </table>

      <div class="paginator-wrapper">
        <div class="actions-area" *ngIf="selectedInvoices.size > 0">
          <button mat-button [matMenuTriggerFor]="bulkMenu" color="primary">
            {{ 'office.invoice.actions.bulk_actions' | translate }} ({{selectedInvoices.size}})
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #bulkMenu="matMenu">
            <button mat-menu-item (click)="downloadSelected()">
              <mat-icon>download</mat-icon>
              <span>{{ 'office.invoice.actions.download' | translate }}</span>
            </button>
            <button mat-menu-item (click)="sendBulkReminders()" [disabled]="!hasOpenInvoices()">
              <mat-icon>send</mat-icon>
              <span>{{ 'office.invoice.actions.send_reminder' | translate }} ({{ getOpenInvoicesCount() }})</span>
            </button>
          </mat-menu>
        </div>

        <mat-paginator
          [length]="totalItems"
          [pageSize]="pageSize"
          [pageSizeOptions]="[5, 10, 25, 100]"
          [pageIndex]="currentPage - 1">
        </mat-paginator>
      </div>
    </div>
  </div>
</section>
