import { AvailabilitySlot } from 'src/app/entities/availability-slot.model';
import dayjs from 'dayjs';

export class SlotHelper {
  static setInitialSlotTimes(
    existingSlots: AvailabilitySlot[]
  ): AvailabilitySlot {
    const newSlot = {
      start_hour: 9,
      start_minute: 0,
      end_hour: 17,
      end_minute: 0,
    };

    if (existingSlots.length > 0) {
      const lastSlot = existingSlots[existingSlots.length - 1];
      const lastSlotEndHour = lastSlot.end_hour;
      const lastSlotEndMinute = lastSlot.end_minute;
      newSlot.start_hour = lastSlotEndHour;
      newSlot.start_minute = lastSlotEndMinute;

      if (newSlot.start_minute + 30 < 60) {
        newSlot.start_minute += 30;
      } else {
        newSlot.start_hour += 1;
        newSlot.start_minute = (newSlot.start_minute + 30) % 60;
      }

      newSlot.end_hour = newSlot.start_hour + 4;
      newSlot.end_minute = newSlot.start_minute;

      if (
        newSlot.end_hour > 24 ||
        (newSlot.end_hour === 24 && newSlot.end_minute > 0)
      ) {
        newSlot.start_hour = 0;
        newSlot.start_minute = 0;
        newSlot.end_hour = 1;
        newSlot.end_minute = 0;
      }
    }

    return newSlot;
  }

  static isSlotOverlapping(
    newSlot: AvailabilitySlot,
    existingSlots: AvailabilitySlot[]
  ): boolean {
    const newStart = dayjs()
      .set('hour', newSlot.start_hour)
      .set('minute', newSlot.start_minute)
      .set('second', 0)
      .set('millisecond', 0);

    const newEnd = dayjs()
      .set('hour', newSlot.end_hour)
      .set('minute', newSlot.end_minute)
      .set('second', 0)
      .set('millisecond', 0);

    return existingSlots.some((slot) => {
      const start = dayjs()
        .set('hour', slot.start_hour)
        .set('minute', slot.start_minute)
        .set('second', 0)
        .set('millisecond', 0);

      const end = dayjs()
        .set('hour', slot.end_hour)
        .set('minute', slot.end_minute)
        .set('second', 0)
        .set('millisecond', 0);

      return (
        newStart.isBetween(start, end, null, '[)') ||
        newEnd.isBetween(start, end, null, '(]') ||
        start.isBetween(newStart, newEnd, null, '[)') ||
        end.isBetween(newStart, newEnd, null, '(]')
      );
    });
  }
}
