import { Component, Input, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-office-setup-stripe-launcher',
    templateUrl: './office-setup-stripe-launcher.component.html',
    styleUrls: ['./office-setup-stripe-launcher.component.scss'],
    imports: [NgClass]
})
export class OfficeSetupStripeLauncherComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() action: string;
  @Input() stripeUrl: string;
  @Input() isWarning: boolean;
  constructor() {}

  ngOnInit(): void {}
}
