import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/entities/user.model';
import { OfficeService } from 'src/app/office/shared/service/office.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'shared-invoice-new-button',
    templateUrl: './invoice-new-button.component.html',
    styleUrls: ['./invoice-new-button.component.scss'],
    imports: [TranslateModule]
})
export class InvoiceNewButtonComponent implements OnInit {
  constructor(
    private sharedService: SharedService,
    private officeService: OfficeService
  ) {}

  currentUser: User;

  ngOnInit() {
    this.sharedService.currentUser.subscribe(
      (user) => (this.currentUser = user)
    );
  }

  createNewInvoice() {
    this.officeService.toggleCreateInvoiceModal(true);
  }
}
