import { Component, Input } from '@angular/core';
import { Plan } from '../../../entities/plan.model';
import { SettingsService } from '../service/settings.service';
import {
  animate,
  group,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Animation } from '../../../shared/animations/fade-animation';
import { MatDialog } from '@angular/material/dialog';
import { PlanStartConfirmDialog } from '../plan-start-confirm-dialog/plan-start-confirm-dialog';
import { PaymentPlan } from '../../../entities/payment-plan.model';
import { PaymentPlanAvailability } from '../../../entities/payment-plan-availability.model';
import { PlanUpgradeConfirmDialog } from '../plan-upgrade-confirm-dialog/plan-upgrade-confirm-dialog';
import { ICAuthService } from '../../../auth/service/i-c-auth.service';
import { User } from '../../../entities/user.model';
import { Router } from '@angular/router';
import { PlanDowngradeConfirmDialog } from '../plan-downgrade-confirm-dialog/plan-downgrade-confirm-dialog';
import { PlanSwitchCycleConfirmDialog } from '../plan-switch-cycle-confirm-dialog/plan-switch-cycle-confirm-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { Planv2Component } from '../planv2/planv2.component';
import {
  AsyncPipe,
  CurrencyPipe,
  NgClass,
  NgFor,
  NgIf,
  PercentPipe,
} from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MonitoringService } from '../../../shared/services/monitoring/monitoring.service';
import { PlanWinbackConfirmDialog } from '../plan-winback-confirm-dialog/plan-winback-confirm-dialog';
import { SubscriptionStatus } from '../../../entities/subscription-status.model';
import { AlertService } from '../../../shared/components/alert/service/alert.service';

@Component({
  selector: 'app-single-plan-picker',
  templateUrl: './single-plan-picker.component.html',
  styleUrls: ['./single-plan-picker.component.scss'],
  animations: [
    Animation.fadeAnimation,
    trigger('planAnimation', [
      transition('* <=> *', [
        query(':enter', style({ opacity: 0, transform: 'translateY(20px)' }), {
          optional: true,
        }),
        query(
          ':enter',
          stagger('200ms', [
            group([
              animate('400ms ease-in-out', style({ opacity: 1 })),
              animate('500ms ease-out', style({ transform: 'translateY(0)' })),
            ]),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
  imports: [
    NgIf,
    NgClass,
    NgFor,
    Planv2Component,
    AsyncPipe,
    PercentPipe,
    TranslateModule,
    CurrencyPipe,
    MatIcon,
    MatTooltip,
  ],
})
export class SinglePlanPickerComponent {
  @Input() planKind: string;
  @Input() header: string;
  @Input() ctaText: string;
  @Input() yearlySavingsText: string;
  @Input() confirmationTitleText: string;
  @Input() confirmationMessageText: string;

  public plan: Plan;
  public paymentPlanYearly: PaymentPlan;
  public paymentPlanMonthly: PaymentPlan;
  public currentUser: User;
  public currentSubscription: SubscriptionStatus;

  constructor(
    private settingsService: SettingsService,
    private dialog: MatDialog,
    private authService: ICAuthService,
    private router: Router,
    public alertService: AlertService
  ) {}

  ngOnInit() {
    this.authService.getCurrentUser().subscribe((user) => {
      if (user) {
        this.currentUser = user;
        if (this.currentUser.profile.profile_approved) {
          this.settingsService
            .getPlanByKind(this.planKind)
            .subscribe((plan) => {
              this.plan = plan;
              this.paymentPlanYearly = plan.payment_plans.find(
                (paymentPlan) => paymentPlan.month === 12
              );
              this.paymentPlanMonthly = plan.payment_plans.find(
                (paymentPlan) => paymentPlan.month === 1
              );
            });
          this.settingsService
            .getSubscriptionStatus()
            .subscribe((subscription) => {
              this.currentSubscription = subscription;
              if (
                subscription.upcoming_plan_kind?.toLowerCase() ===
                this.planKind.toLowerCase()
              ) {
                this.router.navigate(['/home/settings/subscription']);
              }
            });
        } else {
          this.router.navigate(['/home/settings/subscription']);
        }
      }
    });
  }

  onPlanSelected(paymentPlan: PaymentPlan) {
    const isAvailable =
      paymentPlan.availability !==
        PaymentPlanAvailability.UNAVAILABLE_ALREADY_SUBSCRIBED &&
      paymentPlan.availability !==
        PaymentPlanAvailability.UNAVAILABLE_SHORTER_CYCLE &&
      paymentPlan.availability !== PaymentPlanAvailability.UNKNOWN;

    if (!isAvailable) {
      MonitoringService.captureMessage('Plan not available', {
        extra: {
          planId: paymentPlan.id,
          planName: this.plan.name,
          availability: paymentPlan.availability,
        },
      });
    }

    if (
      this.currentSubscription?.upcoming_plan_change_name?.toLowerCase() ===
      'free'
    ) {
      this.dialog
        .open(PlanWinbackConfirmDialog, {
          data: {
            paymentPlanId: paymentPlan.id,
            planName: this.plan.name,
            date: this.currentSubscription.subscription.current_period_end,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.router.navigate(['/home/settings/subscription']);
          }
        });
      return;
    }

    switch (paymentPlan.availability) {
      case PaymentPlanAvailability.AS_NEW_PLAN:
        this.dialog.open(PlanStartConfirmDialog, {
          data: {
            paymentPlanId: paymentPlan.id,
            planName: this.plan.name,
          },
        });
        break;
      case PaymentPlanAvailability.UPGRADE:
        this.dialog
          .open(PlanUpgradeConfirmDialog, {
            data: {
              paymentPlanId: paymentPlan.id,
              planName: this.plan.name,
              profileCurrency: this.currentUser.profile.currency,
              isYearlyBilling: paymentPlan.month === 12,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.router.navigate(['/home/settings/subscription']);
            }
          });
        break;
      case PaymentPlanAvailability.DOWNGRADE:
        this.dialog
          .open(PlanDowngradeConfirmDialog, {
            data: {
              paymentPlanId: paymentPlan.id,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.router.navigate(['/home/settings/subscription']);
            }
          });
        break;
      case PaymentPlanAvailability.BILLING_CYCLE_CHANGE:
        this.dialog
          .open(PlanSwitchCycleConfirmDialog, {
            data: {
              paymentPlanId: paymentPlan.id,
              isAnnualSwitch: paymentPlan.month === 12,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.router.navigate(['/home/settings/subscription']);
            }
          });
        break;
      default:
        MonitoringService.captureMessage(
          'Availability: ' + paymentPlan.availability + ' not implemented',
          {
            extra: {
              planId: paymentPlan.id,
              planName: this.plan.name,
              availability: paymentPlan.availability,
            },
          }
        );
        break;
    }
  }
}
