<nav class="header">
  <div
    class="back navitem"
    [routerLink]="['/home/messaging/']"
  ></div>
  <app-avatar-image
    class="avatar-image otherUserStatus"
    [loading]="!otherUser"
    [ngClass]="{
                    online: otherUserOnlineStatus,
                    offline: !otherUserOnlineStatus
                  }"
    [src]="getProfileImageUrl(
          this.otherUser?.profile_id,
          'mini'
        )"
    [alt]="'Profile Picture'"
    [showNotification]="false"
    [showMatchedBadge]="false"
  ></app-avatar-image>
  <div
    *ngIf="otherUser"
    class="navbar-item has-dropdown"
    [ngClass]="{ 'is-active': showUserContextMenu }"
  >
    <a
      class="navbar-link"
      (click)="showUserContextMenu = !showUserContextMenu"
    >
      <div>
        <h2 class="chatpartner-name">
          {{ otherUser.name }}
          <span *ngIf="otherUserDeactivated(); else activeUser" style="color: #7d8894">
            {{ "messaging.deactivated" | translate }}
          </span>
          <ng-template #activeUser>
            <span *ngIf="otherUserUnderReview()">
              {{ "messaging.under-review" | translate }}
            </span>
          </ng-template>
        </h2>
        <ng-container *ngIf="otherUser.jobTitle">
          <h3 class="chatpartner-title">
            {{ otherUser.jobTitle }}
          </h3>
        </ng-container>
      </div>
    </a>
    <div class="navbar-dropdown">
      <a class="navbar-item" (click)="showOtherUser()">
        {{ "common.show" | translate }}
        <span *ngIf="otherUser.type == 'Client'"
        >&nbsp;{{ "common.client" | translate }}
                      </span>
        <span *ngIf="otherUser.type == 'Therapist'"
        >&nbsp;{{ "common.profile" | translate }}
                      </span>
      </a>
      <a
        class="navbar-item"
        *ngIf="currentUser && currentUser.type == 'Therapist'"
        (click)="reportUser()"
      >
        {{ "support.report-user" | translate }}
      </a>
      <a
        class="navbar-item"
        *ngIf="currentUser && !conversationIsHidden"
        (click)="onHideConversation()"
      >
        <span
          matTooltipClass="mat-tooltip"
          matTooltip="{{'messaging.hide_conversation_tooltip' | translate}}"
          class="is-flex align-center"
          style="cursor: pointer;"
        >{{ 'messaging.hide_conversation' | translate }}&nbsp;<mat-icon
          class="mat-tooltip-trigger-icon">help_outline</mat-icon>
        </span>
      </a>
      <a
        class="navbar-item"
        *ngIf="currentUser && conversationIsHidden"
        (click)="onUnhideConversation()"
      >
        <span
          matTooltipClass="mat-tooltip"
          matTooltip="{{'messaging.un-hide_conversation_tooltip' | translate}}"
          class="is-flex align-center"
          style="cursor: pointer;"
        >{{ 'messaging.un-hide_conversation' | translate }}&nbsp;<mat-icon
          class="mat-tooltip-trigger-icon">help_outline</mat-icon>
        </span>
      </a>
    </div>
  </div>
  <ng-container *ngIf="showMatchedBadge">
    <app-matched-by-ic/>
  </ng-container>
  <ng-container *ngIf="!!otherUserEapProvider()">
    <app-eap-member [companyName]="otherUserEapProvider()" />
  </ng-container>
  <div class="spacer"></div>
  <div
    class="buttons"
    *ngIf="otherUser && !otherUser.flagged"
  >
    <ng-container
      *ngIf="currentUser && currentUser.type == 'Therapist' &&  currentUser?.abilities?.can_start_video_call && !otherUserDeactivated() && !otherUserUnderReview()">
      <div
        class="button is-rounded is-primary is-outlined video-call is-hidden-touch"
        data-tooltip="Start a secure video call"
        (click)="startVideo()"
      >
        <svg-icon
          class="icon is-small"
          src="assets/svg/video_person.svg"
          [svgStyle]="{ 'width.px':24 }"
        ></svg-icon>
        <span>{{
            "messaging.video-call" | translate
          }}</span>
      </div>
      <div
        class="button is-rounded is-primary is-outlined video-call is-hidden-desktop"
        data-tooltip="Start a secure video call"
        (click)="startVideo()"
      >
        <svg-icon
          class="icon is-small"
          src="assets/svg/video_person.svg"
          [svgStyle]="{ 'width.px':24 }"
        ></svg-icon>
      </div>
    </ng-container>
    <ng-container *ngIf="currentUser && currentUser.type == 'Client' ">
      <div
        class="button is-rounded is-primary is-outlined video-call is-hidden-touch"
        data-tooltip="Enter the waiting room for this therapist"
        (click)="openWaitingRoomLink()"
      >
        <svg-icon
          class="icon is-small"
          src="assets/svg/video_person.svg"
          [svgStyle]="{ 'width.px':24 }"
        ></svg-icon>
        <span class="is-hidden-touch">{{
            "messaging.enter-waiting-room" | translate
          }}</span>
      </div>
      <div
        class="button is-rounded is-primary is-outlined video-call is-hidden-desktop"
        data-tooltip="Enter the waiting room for this therapist"
        (click)="openWaitingRoomLink()"
      >
        <svg-icon
          class="icon is-small"
          src="assets/svg/video_person.svg"
          [svgStyle]="{ 'width.px':24 }"
        ></svg-icon>
      </div>
    </ng-container>
  </div>
</nav>
