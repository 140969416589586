import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SettingsService } from './service/settings.service';
import { User } from '../../entities/user.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { NotificationSettings } from 'src/app/entities/notification-settings.model';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from '../profile/service/profile.service';
import { Animation } from '../../shared/animations/fade-animation';
import { ICAuthService } from 'src/app/auth/service/i-c-auth.service';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { MonitoringService } from '../../shared/services/monitoring/monitoring.service';
import { TranslateModule } from '@ngx-translate/core';
import { SocialWidgetsComponent } from './social-widgets/social-widgets.component';
import { PlanPickerComponent } from './plan-picker/plan-picker.component';
import { SubscriptionCallbackComponent } from './subscription-callback/subscription-callback.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { CalendarIntegrationsComponent } from './calendar-integrations/calendar-integrations.component';
import { CalendarWorkingHoursComponent } from './calendar-working-hours/calendar-working-hours.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { SidenavComponent } from '../../frame/sidenav/sidenav.component';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { ProgramsComponent } from './programs/programs.component';
import { SinglePlanPickerComponent } from './single-plan-picker/single-plan-picker.component';
import { extractErrorMessage } from '../../shared/helpers/errors.helper';
import { EmailChangeDialogComponent } from './email-change-dialog-component/email-change-dialog-component';
import { MatDialog } from '@angular/material/dialog';
import { LocaleService } from 'src/app/office/shared/service/locale.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: [Animation.fadeAnimation],
  imports: [
    NgIf,
    SidenavComponent,
    FormsModule,
    NgSelectModule,
    NgFor,
    NgClass,
    CalendarWorkingHoursComponent,
    CalendarIntegrationsComponent,
    SubscriptionComponent,
    SubscriptionCallbackComponent,
    PlanPickerComponent,
    SocialWidgetsComponent,
    TranslateModule,
    ProgramsComponent,
    SinglePlanPickerComponent,
  ],
})
export class SettingsComponent implements OnInit {
  constructor(
    private sharedService: SharedService,
    private settingsService: SettingsService,
    private localeService: LocaleService,
    private activatedRoute: ActivatedRoute,
    private profileService: ProfileService,
    private alertService: AlertService,
    private auth: ICAuthService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  public env = environment;
  public timezones;
  public currentUser: User;
  public currentSection: String;
  public notificationSettings: NotificationSettings[];
  public messageSettings: NotificationSettings;
  public appointmentSettings: NotificationSettings;
  public updatingUser: boolean;
  public editFirstName: boolean;
  public editLastName: boolean;
  private bypassEmailConfirmation: boolean = false;
  public editingEmail: boolean;
  public isOnLegacyAuth: boolean = !!localStorage.getItem('accessToken');
  public editEAPAccessCode: boolean = false;
  public eapAccessCode: string;
  public isDeletingPhoto: boolean = false;
  public showDeleteModal: boolean = false;
  public deletingAccount: boolean = false;
  public shouldResetPassword: boolean = false;
  public resetingPassword: boolean = false;
  private originalSettings: any;
  firstName: string;
  lastName: string;
  public newEmail: string = ''; // Add this property

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.timezones =
      'supportedValuesOf' in Intl
        ? (Intl as any).supportedValuesOf('timeZone')
        : DEFAULT_TIMEZONES; // Your fallback array
    this.assignSlots();
    this.activatedRoute.url.subscribe((params) => {
      this.currentSection = params[params.length - 1].path;
    });
  }

  groupTZ(item) {
    return item.split('/')[0];
  }

  cloneSettings() {
    this.originalSettings = JSON.parse(JSON.stringify(this.currentUser));
  }

  startEditingEmail() {
    this.editingEmail = true;
    this.newEmail = this.currentUser.email;
  }

  cancelEditingEmail() {
    this.editingEmail = false;
    this.newEmail = this.currentUser.email;
  }

  startEditingFirstName() {
    this.editFirstName = true;
  }

  startEditingLastName() {
    this.editLastName = true;
  }

  cancelEditingFirstName() {
    this.editFirstName = false;
    this.firstName = this.currentUser.profile.firstname;
  }

  cancelEditingLastName() {
    this.editLastName = false;
    this.lastName = this.currentUser.profile.lastname;
  }

  startEditingEAPCode() {
    this.editEAPAccessCode = true;
    this.eapAccessCode = this.currentUser.eap_access_code;
  }

  cancelEditingEAPCode() {
    this.editEAPAccessCode = false;
    this.eapAccessCode = this.currentUser.eap_access_code;
  }

  /**
   * Get current user,
   * Get notifications settings,
   * assign slots to weekday,
   * update them and check if google calendar
   * integration exists.
   *
   */
  assignSlots() {
    this.sharedService.currentUser.subscribe((user) => {
      if (user) {
        this.currentUser = user;
        this.cloneSettings();
        this.getNotificationSettings();
        this.firstName = user.profile.firstname;
        this.lastName = user.profile.lastname;
      }
    });
  }

  updateUser = (event: any) => {
    if (event.key === 'Enter') {
      this.updateSettings();
    } else if (event.key === 'Escape') {
      if (event.target.name === 'editFirstName') {
        this.cancelEditingFirstName();
      } else if (event.target.name === 'editLastName') {
        this.cancelEditingLastName();
      } else if (event.target.name === 'newEmail') {
        this.cancelEditingEmail();
      }
    }
  };

  updateSettings() {
    if (this.updatingUser) {
      return;
    }

    // Only show confirmation if not already confirmed
    if (
      !this.bypassEmailConfirmation &&
      this.editingEmail &&
      this.newEmail !== this.currentUser.email
    ) {
      this.showEmailChangeConfirmation();
      return;
    }

    this.updatingUser = true;
    // Update dialog loading state if it's open
    const dialogRef = this.dialog.getDialogById('email-change-dialog');
    if (dialogRef) {
      dialogRef.componentInstance.data.loading = true;
    }

    this.localeService.setLocale(this.currentUser.locale);

    const updatedUser = {
      ...this.currentUser,
      ...(this.editingEmail && { email: this.newEmail }),
      ...(this.editEAPAccessCode && { eap_access_code: this.eapAccessCode }),
    };

    this.settingsService
      .updateCurrentUserV2(updatedUser, this.firstName, this.lastName)
      .pipe(
        catchError((error) => {
          if (error?.error?.reason?.includes('social')) {
            this.alertService.error('settings.email_change_google');
          } else {
            this.alertService.error(
              extractErrorMessage(error, 'common.generic_error.message')
            );
          }
          this.resetUser();
          this.newEmail = this.currentUser.email; // reset to avoid being logged out
          return of({
            user: this.currentUser,
          });
        })
      )
      .subscribe((updatedUserResponse) => {
        this.updatingUser = false;
        this.editingEmail = false;
        this.editEAPAccessCode = false;
        this.bypassEmailConfirmation = false;
        this.editFirstName = false;
        this.editLastName = false;

        const emailChanged =
          this.newEmail && this.newEmail !== this.currentUser.email;

        if (emailChanged) {
          this.auth.signOut();
        } else {
          this.sharedService.setCurrentUser({
            ...updatedUserResponse.user,
            email_confirmed: this.currentUser.email_confirmed,
          });
        }
      });
  }

  showEmailChangeConfirmation() {
    const dialogRef = this.dialog.open(EmailChangeDialogComponent, {
      width: '100%',
      maxWidth: '400px',
      data: { loading: false },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.bypassEmailConfirmation = true;
        this.updateSettings();
      }
    });
  }

  private resetUser() {
    this.updatingUser = false;
    this.currentUser = this.originalSettings;
    this.sharedService.updateUser(this.currentUser);
  }

  /**
   * Delete user account.
   */
  deleteUser = () => {
    this.deletingAccount = true;
    this.settingsService.deleteUser(this.currentUser).subscribe((res) => {
      if (res.user == 'destroyed') {
        this.auth.deleteAccount().subscribe((res) => {
          if (res && res.status !== 'success') {
            MonitoringService.captureMessage(
              'Failed to delete account',
              res.message
            );
          }
        });
      }
    });
  };

  startPasswordResetProcess() {
    if (!this.isOnLegacyAuth) {
      // Auth0 sends an email to reset, so we don't need to confirm anything
      this.triggerPasswordResetEmail();
    } else {
      // start the confirmation process via the dialog
      this.shouldResetPassword = true;
    }
  }

  triggerPasswordResetEmail() {
    this.resetingPassword = true;
    this.auth.triggerPasswordChangeEmail().subscribe((res) => {
      this.resetingPassword = false;
      if (res.success) {
        this.alertService.success('settings.password_reset_email_sent');
      } else {
        this.alertService.error('settings.password_reset_email_send_failed');
      }
    });
  }
  /**
   * Get users notification
   * settings ( Push, Email )
   *
   */
  getNotificationSettings = () =>
    this.settingsService
      .getNotificationSettings(this.currentUser.profile.id)
      .subscribe((ns) => {
        this.messageSettings = ns.find((ns) => ns.name == 'message');
        this.appointmentSettings = ns.find((ns) => ns.name == 'appointment');
      });

  /**
   * Update users
   * notification settings
   * ( Push, Email )
   *
   */
  updateNotificationSettings = () => {
    this.notificationSettings.forEach((ns: NotificationSettings, i: number) => {
      this.settingsService.updateNotificationSettings(ns).subscribe((res) => {
        // if (this.notificationSettings.length == i) this.getNotificationSettings();
      });
    });
  };

  /**
   * On change of settings,
   * assign to array for updating.
   *
   */
  onNotificationSettingsChange = () => {
    this.notificationSettings = [];
    this.notificationSettings.push(
      this.messageSettings,
      this.appointmentSettings
    );
    this.updateNotificationSettings();
  };

  /**
   * Enable caledar view
   * and save to DB.
   *
   * @param value Boolean
   *
   */
  onEnableCalendarChange(value: boolean) {
    this.currentUser.profile.calendar_enabled = value;
    this.profileService.updateItem(
      this.currentUser.profile,
      this.currentUser.id
    );
  }

  /**
   * Enable caledar export
   * and save to DB.
   *
   * @param value Boolean
   *
   */
  onEnableICSChange(value: boolean) {
    this.currentUser.profile.calendar_feed_enabled = value;
    this.profileService.updateItem(
      this.currentUser.profile,
      this.currentUser.id
    );
  }

  deletePhotos() {
    this.isDeletingPhoto = true;
    this.settingsService
      .deleteProfilePicture(this.currentUser)
      .subscribe(() => {
        this.currentUser.profile.images = [];
        this.profileService.updateItem(
          this.currentUser.profile,
          this.currentUser.id
        );
        this.isDeletingPhoto = false;
        this.sharedService.setCurrentUser(this.currentUser);
      });
  }

  updateDuration() {
    this.profileService
      .updateAppointmentDuration(this.currentUser.profile)
      .subscribe((profile) => {
        this.alertService.success('settings.app-duration-updated');
      });
  }

  updateMinAppointmentNotificationTime() {
    this.profileService
      .updateMinAppointmentNotificationTime(this.currentUser.profile)
      .subscribe((profile) => {
        this.alertService.success('settings.app-notification-time-updated');
      });
  }

  logoutToResetPassword() {
    this.shouldResetPassword = false;
    this.auth.signOut();
  }

  onEnableMarketingEmails(value: boolean) {
    this.currentUser.marketing_email_opt_in = value;
    this.updateSettings();
  }

  onEnableNewsletterEmails(value: boolean) {
    this.currentUser.newsletter_email_opt_in = value;
    this.updateSettings();
  }
}

export const DEFAULT_TIMEZONES = [
  // Americas
  'America/New_York', // Eastern (US & Canada)
  'America/Chicago', // Central (US & Canada)
  'America/Denver', // Mountain (US & Canada)
  'America/Los_Angeles', // Pacific (US & Canada)
  'America/Toronto', // Eastern Canada
  'America/Vancouver', // Pacific Canada
  'America/Mexico_City', // Mexico
  'America/Sao_Paulo', // Brazil

  // Europe & Africa
  'Europe/London', // UK, Ireland, Portugal
  'Europe/Paris', // France, Spain
  'Europe/Berlin', // Germany, Netherlands, Poland
  'Europe/Rome', // Italy
  'Europe/Moscow', // Russia
  'Africa/Cairo', // Egypt
  'Africa/Johannesburg', // South Africa

  // Asia & Pacific
  'Asia/Dubai', // UAE
  'Asia/Shanghai', // China
  'Asia/Tokyo', // Japan
  'Asia/Singapore', // Singapore
  'Asia/Hong_Kong', // Hong Kong
  'Asia/Seoul', // Korea
  'Asia/Kolkata', // India
  'Australia/Sydney', // Australia Eastern
  'Australia/Perth', // Australia Western
  'Pacific/Auckland', // New Zealand
];
