import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { InvoiceExtended } from 'src/app/entities/invoice.model';
import { OfficeService } from 'src/app/office/shared/service/office.service';
import { User } from 'src/app/entities/user.model';
import { AlertService } from '../alert/service/alert.service';
import {
  StripeCardComponent,
  StripeIbanComponent,
  StripeService,
} from 'ngx-stripe';
import { StripeElement } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import {
  CurrencyPipe,
  DatePipe,
  NgClass,
  NgFor,
  NgIf,
  UpperCasePipe,
} from '@angular/common';
import { LocalisedDatePipe } from '../../../components/booking/booking-form-container/localised-date.pipe';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.css'],
  imports: [
    NgClass,
    NgIf,
    NgFor,
    RouterLink,
    UpperCasePipe,
    CurrencyPipe,
    DatePipe,
    TranslateModule,
    LocalisedDatePipe,
  ],
})
export class PaymentModalComponent implements OnInit {
  @ViewChild(StripeCardComponent, { static: false })
  cardComponent: StripeCardComponent;
  @ViewChild(StripeIbanComponent, { static: false })
  ibanComponent: StripeIbanComponent;

  constructor(
    private sharedService: SharedService,
    private officeService: OfficeService,
    private alertService: AlertService,
    private stripeService: StripeService,
    private cdr: ChangeDetectorRef
  ) {}

  public currentUser: User;
  public invoiceID: number;
  public invoice: InvoiceExtended;

  card: StripeElement;

  ngOnInit() {
    this.sharedService.currentUser.subscribe((user) => {
      this.currentUser = user;
      this.cdr.detectChanges();
    });
    this.officeService.currentInvoice.subscribe((invoice) => {
      if (invoice) {
        this.invoice = invoice;
        this.stripeService.changeKey(environment.stripe);
        this.cdr.detectChanges();
      }
    });
    this.sharedService.payableInvoice.subscribe((id) => {
      this.invoiceID = id;
      this.invoice = undefined;
      this.cdr.detectChanges();
      if (this.invoiceID) {
        this.officeService.getCurrentInvoice(this.invoiceID);
      }
    });
  }

  public close() {
    this.sharedService.removePayableInvoice();
    this.officeService.removeCurrentInvoice();
  }
}
