import { Component, Input, OnInit } from '@angular/core';
import { CalendarService } from '../../../components/calendar/service/calendar.service';
import { User } from 'src/app/entities/user.model';
import { Appointment } from 'src/app/entities/appointment.model';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { CallButtonComponent } from '../video/call-button/call-button.component';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { LocalisedDatePipe } from '../../../components/booking/booking-form-container/localised-date.pipe';

@Component({
  selector: 'app-next-appointment',
  templateUrl: './next-appointment.component.html',
  styleUrls: ['./next-appointment.component.scss'],
  imports: [
    NgIf,
    NgFor,
    ProfilePictureComponent,
    CallButtonComponent,
    RouterLink,
    DatePipe,
    TranslateModule,
    LocalisedDatePipe,
  ],
})
export class NextAppointmentComponent implements OnInit {
  constructor(private calendarService: CalendarService) {}

  nextAppointments: Array<Appointment>;
  @Input()
  currentUser: User;

  showTimezoneHint: boolean = false;
  currentTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  ngOnInit() {
    this.showTimezoneHint =
      this.currentUser.profile.timezone !== this.currentTimezone;

    this.calendarService.getLatestAppointment().subscribe((data) => {
      this.nextAppointments = data;
    });
  }
}
