import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { User } from '../../../entities/user.model';
import { LeanConversationUser } from '../../../entities/lean-conversation-user.model';

@Component({
  selector: 'app-eap-reminder-banner',
  templateUrl: './eap-reminder-banner.component.html',
  styleUrls: ['./eap-reminder-banner.component.scss'],
  imports: [TranslateModule, NgIf],
})
export class EapReminderBannerComponent implements OnInit {
  @Input() otherUser: LeanConversationUser;
  isEapAllowed: boolean;
  constructor() {}

  ngOnInit(): void {
    this.isEapAllowed =
      this.otherUser && !this.otherUser.cannot_book_eap_session_reason;
  }
}
