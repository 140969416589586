<div class="input-container">
  <stream-message-input
    class="stream-input"
    inputMode="mobile"></stream-message-input>
</div>

<ng-template
  #customAttachmentUpload
  let-attachmentService="attachmentService"
>
  <!-- Shared file input at top level -->
  <input
    #fileInput
    type="file"
    class="str-chat__file-input"
    data-testid="file-input"
    [multiple]="true"
    id="{{ fileInputId }}"
    (change)="filesSelected(fileInput.files, attachmentService)"
    style="display: none;"
  />

  <!-- Desktop view -->
  <div class="is-flex is-align-items-center flex-gap-5 has-margin-right-5 is-hidden-touch">
    <button
      title="Suggest Appointment"
      id="calendarButton"
      class="button is-text is-icon  input-action"
      (click)="this.onCreateBooking.emit()"
      *ngIf="currentUser?.abilities?.can_create_appointments"
    >
      <i class="icon icon-calendar"></i>
    </button>
    <button
      title="Create Invoice"
      *ngIf="
        currentUser &&
        currentUser.type == 'Therapist' &&
        currentUser.profile.stripe_connect_active == true
      "
      class="button is-text is-icon input-action"
      (click)="this.onCreateInvoice.emit()"
    >
      <i class="icon icon-invoice"></i>
    </button>
    <div
      class="str-chat__file-input-container button is-text input-action"
      data-testid="file-upload-button"
    >
      <label class="str-chat__file-input-label" for="{{ fileInputId }}">
        <i class="icon icon-attachment"></i>
      </label>
    </div>
  </div>

  <!-- Mobile view -->
  <div class="is-hidden-desktop">
    <mat-menu #menu="matMenu" class="centered-menu">
      <button mat-menu-item *ngIf="currentUser?.abilities?.can_create_appointments"
              (click)="this.onCreateBooking.emit()">
        <div class="is-flex is-align-items-center flex-gap-5">
          <i class="icon icon-calendar"></i>
          {{ "messaging.suggest-appointment" | translate }}
        </div>
      </button>
      <button mat-menu-item *ngIf="
        currentUser &&
        currentUser.type == 'Therapist' &&
        currentUser.profile.stripe_connect_active == true
      " (click)="this.onCreateInvoice.emit()">
        <div class="is-flex is-align-items-center flex-gap-5">
          <i class="icon icon-invoice"></i>
          {{ "messaging.create-invoice" | translate }}
        </div>
      </button>
      <button mat-menu-item>
        <div class="is-flex is-align-items-center flex-gap-5">
          <i class="icon icon-attachment"></i>
          <label class="str-chat__file-input-label" for="{{ fileInputId }}">
            {{ "messaging.upload-file" | translate }}
          </label>
        </div>
      </button>
    </mat-menu>

    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      title="More actions"
      class="button is-text is-icon input-action"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
</ng-template>

<app-booking-form-container
  *ngIf="currentUser && currentUser.type == 'Therapist'"
  [currentUser]="currentUser"
  #bookingModal
></app-booking-form-container>
