<section class="dashboard-teaser">
    <header class="dashboard-teaser-header">
        <div class="columns is-mobile align-center">
            <div class="column">
                <h3 class="dashboard-teaser-title">
                    {{ "common.payouts" | translate }}
                </h3>
            </div>
        </div>
    </header>

    <div class="card">
        <div class="table-filters">
            <!-- Status filter -->
            <mat-form-field appearance="outline" class="status-select">
                <mat-label>{{ "common.status" | translate }}</mat-label>
                <mat-select
                        [(ngModel)]="selectedStatus"
                        placeholder="{{ 'office.payout.list.all_statuses' | translate }}"
                        (ngModelChange)="onStatusChange()">
                    <mat-option *ngFor="let option of statusOptions" [value]="option.value">
                        {{ option.label | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Created Date filter -->
            <mat-form-field appearance="outline" class="date-filter is-hidden-mobile">
                <mat-label>{{ "office.payout.list.created_date_filter" | translate }}</mat-label>
                <mat-date-range-input [rangePicker]="createdPicker" [dateFilter]="validDateFilter">
                    <input matStartDate
                           [placeholder]="datePlaceholder"
                           [(ngModel)]="dateFilters.created.start"
                           name="createdStart"
                           (dateChange)="onDateFilterChange()">
                    <input matEndDate
                           [placeholder]="datePlaceholder"
                           [(ngModel)]="dateFilters.created.end"
                           name="createdEnd"
                           (dateChange)="onDateFilterChange()">
                </mat-date-range-input>
                <div matSuffix class="suffix-buttons">
                    <button *ngIf="isDateFilterActive('created')"
                            mat-icon-button
                            class="clear-button"
                            (click)="clearDateFilter('created')"
                            aria-label="Clear created date filter">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-datepicker-toggle [for]="createdPicker"></mat-datepicker-toggle>
                </div>
                <mat-date-range-picker #createdPicker></mat-date-range-picker>
            </mat-form-field>

            <!-- Arrival Date filter -->
            <mat-form-field appearance="outline" class="date-filter is-hidden-mobile">
                <mat-label>{{ "office.payout.list.arrival_date_filter" | translate }}</mat-label>
                <mat-date-range-input [rangePicker]="arrivalPicker"  [dateFilter]="validDateFilter">
                    <input matStartDate
                           [placeholder]="datePlaceholder"
                           [(ngModel)]="dateFilters.arrival.start"
                           name="arrivalStart"
                           (dateChange)="onDateFilterChange()">
                    <input matEndDate
                           [placeholder]="datePlaceholder"
                           [(ngModel)]="dateFilters.arrival.end"
                           name="arrivalEnd"
                           (dateChange)="onDateFilterChange()">
                </mat-date-range-input>
                <div matSuffix class="suffix-buttons">
                    <button *ngIf="isDateFilterActive('arrival')"
                            mat-icon-button
                            class="clear-button"
                            (click)="clearDateFilter('arrival')"
                            aria-label="Clear arrival date filter">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-datepicker-toggle [for]="arrivalPicker"></mat-datepicker-toggle>
                </div>
                <mat-date-range-picker #arrivalPicker></mat-date-range-picker>
            </mat-form-field>
        </div>
        <div class="table-container">
            <div class="loading-overlay" *ngIf="isLoading">
                <mat-spinner diameter="50"></mat-spinner>
            </div>

            <table mat-table [dataSource]="dataSource" matSort class="payouts-table">
                <!-- Created At Column -->
                <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ "office.payout.list.created_date" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let payout">{{ payout.created_at | localisedDate:'L' }}</td>
                </ng-container>

                <!-- Arrival Date Column -->
                <ng-container matColumnDef="arrival_date" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="is-hidden-mobile">
                        {{ "office.payout.list.arrival_date" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let payout" class="is-hidden-mobile">{{ payout.arrival_date | localisedDate:'L' }}</td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ "common.status" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let payout">
                        {{ "office.payout.statuses." + payout.status | translate }}
                    </td>
                </ng-container>

                <!-- Destination Column -->
                <ng-container matColumnDef="destination">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="is-hidden-mobile">
                        {{ "office.payout.list.destination" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let payout" class="is-hidden-mobile">
        <span *ngIf="payout.destination">
          {{ payout.destination.bank_name }} •••• {{ payout.destination.last4 }}
        </span>
                    </td>
                </ng-container>

                <!-- Amount Column -->
                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ "common.amount" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let payout">
                        {{ payout.amount / 100 | currency:(payout.currency | uppercase) }}
                    </td>
                </ng-container>

              <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let payout">
                  <button mat-icon-button (click)="openPayoutDetails(payout); $event.stopPropagation()">
                    <mat-icon>visibility</mat-icon>
                  </button>
                </td>
              </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"
                    class="payout-table-item">
                </tr>
            </table>

            <div class="empty-state" *ngIf="!isLoading && (!dataSource?.data || dataSource.data.length === 0)">
                <p>{{ "office.payout.list.no_payouts" | translate }}</p>
            </div>

            <div class="paginator-wrapper" *ngIf="dataSource?.data?.length > 0">
                <mat-paginator
                        [length]="totalItems"
                        [pageSize]="pageSize"
                        [pageSizeOptions]="[5, 10, 25, 100]"
                        [pageIndex]="currentPage - 1">
                </mat-paginator>
            </div>
        </div>

    </div>
</section>

